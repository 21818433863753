(function () {
    angular.module('pgtocobrancas')
        .controller('PgtoCobrancasComponentController', ['$log', '$scope', '$ionicScrollDelegate', '$state', 'TypeEnum', '$timeout', '$rootScope','$compile','$sce','CobrancaService', 'CartaoService', '$ionicModal','Configs','$ionicLoading','SessionData','DownloadArquivo','Notification',
            function ($log, $scope, $ionicScrollDelegate, $state, TypeEnum, $timeout, $rootScope,$compile,$sce,CobrancaService,CartaoService,$ionicModal,Configs,$ionicLoading,SessionData,DownloadArquivo,Notification) {
                let vm = this;
                let ctrl = this;
                
                vm.stateIs = function(state){
                	return $state.is(state);
                }
                
                vm.checkedItems = function(){  
                	var selectedItems = [];
	                angular.forEach(vm.itens, function(value, index){
	        			if(value.checked){
	        				selectedItems.push(value);
	        			}
	        		});
	                return selectedItems;
                }
                
                vm.checkItem = function ($event,item) {
                			$event.stopPropagation();
                			
                	  $timeout(function(){
	          			if($event.target.tagName!=="INPUT"){
	          					item.checked = !item.checked;
	          			}
            		  	$rootScope.$broadcast('CardCreditSelection');  
                    })
                }


                
                vm.isArray = function (item) {
                	return angular.isArray(item);
                }
                
                vm.getWindowWidth = function () {
                    return window.innerWidth;
                }
                


                vm.itemStyle = function () {
                    if (vm.cardItemStyle)
                        return 'item ' + vm.cardItemStyle;
                    return 'item';
                }

                vm.getValueOfType = function (item, column) {
                    switch (column.type) {
                        case TypeEnum.DATE:
                            return item[column.id] != null && item[column.id] != '' ? moment(item[column.id]).utc().format('DD/MM/YYYY') : '';
                        case TypeEnum.STRING:
                            return item[column.id];
                        case TypeEnum.CURRENCY:
                            return typeof item[column.id] == 'number' ? convertToCurrency(item[column.id]) : item[column.id];
                        case TypeEnum.CARD:
                            return 'xxxx.xxxx.xxxx.' + item[column.id];
                        default:
                            return item[column.id];
                    }
                };
                
                
                
                vm.getValue= function (item, columnId,type) { 
                	
                	let t=null;
                	
                	if(type =="TypeEnum.DATE"){
                		t= TypeEnum.DATE;
                	}else if(type =="TypeEnum.STRING"){
                		t= TypeEnum.STRING
                	}else if(type =="TypeEnum.CURRENCY"){
                		t= TypeEnum.CURRENCY
                	}else if(type =="TypeEnum.CARD"){
                		t= TypeEnum.CARD
                	}
                	
                	let column={id:columnId, type:t};
                	 return vm.getValueOfType(item, column);
                };
                
          

                function convertToCurrency(input = 0) {
                    return parseFloat(input).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                }
                
                
                $ionicModal.fromTemplateUrl('./js/custom-components/cobranca-list/modal-cobranca-detalhe.html', {
        	        scope: $scope,
        	        animation: 'slide-in-up'
        	    }).then(function(modal) {
        	        $scope.modal = modal;
        	    });
        	    
        	    vm.detalharCobranca = function(cobranca,$event){
        	    	$scope.codCobranca = cobranca;
        	    	$event.stopPropagation();
        	    	CobrancaService.listarItensCobranca(cobranca).then(function handleSuccess(result) {
						$scope.pedidoPagamento = {};
        	          
						$scope.lancamentos = result.itensLancamento; 
						$scope.pagamentos = result.itensPagamento;  
						$scope.pedidoPagamento.pedido = result.pedidoPgto;
						$scope.pedidoPagamento.cartoes = result.pedidoPgtoCartoes;
						$scope.isPagCartao = false;
						if (result.itensPagamento.tipoPagamento){
							if (result.itensPagamento.tipoPagamento.toUpperCase() == "CARTAO" || 
								result.itensPagamento.tipoPagamento.toUpperCase() == "CARTÃO"){
									$scope.isPagCartao = true;
							}
						}
						$scope.modal.show()
        	          
        	        }).catch(function(err) {
                      $ionicLoading.hide();
                  });
				}
				
				vm.downloadConsultaPagamento = function() {
					let numPedido = $scope.pedidoPagamento.pedido[0].idPedidoPgto;
					CartaoService.downloadConsultaPagamento(numPedido);
				}                
                
        	    ctrl.downloadBoleto = function(){
              		$ionicLoading.show({
        				content : 'Loading',
        				animation : 'fade-in',
        				showBackdrop : true,
        				maxWidth : 200,
        				showDelay : 1
					});
					
					CobrancaService.downloadBoletoCobranca(ctrl.boleto.itensBoleto.codigoBoleto);
        	    }                				        	    
        	    
        		$ionicModal.fromTemplateUrl('js/custom-components/boleto-detail/boleto-itens-modal.html', {
        			scope: $scope,
        			animation: 'slide-in-up',
        		}).then(function(modalBoleto) {
        			vm.modalBoleto = modalBoleto;
        		    $scope.ctrl=ctrl; 
        		});
        		
        		// Fecha os detalhes do boleto
        		ctrl.closeBoletoDetail=function (){
        			$log.debug('[BoletoDetailComponentController.closeBoletoDetail()]:boleto=', ctrl.boleto);
        			vm.modalBoleto.hide();
        		};
        		
        		
        			ctrl.copiarNumeroBoleto=function (){
        				if(!Clipboard.isSupported()){
        					Notification.warning('Função não suportada no navegador em uso.');
        					return;
        				}
        			
        			ctrl.clipboard = new Clipboard('#copy-button');
        			Notification.success("Número do Boleto foi copiado!");
        			
        		}
        		
        		ctrl.prorrogarVencimentoBoleto = function(){
					$ionicLoading.show({
        				content : 'Loading',
        				animation : 'fade-in',
        				showBackdrop : true,
        				maxWidth : 200,
        				showDelay : 1
					});
					
					CobrancaService.prorrogarVencimentoBoleto(ctrl.boleto.codigoBoleto).then(function(result){						
						if (result.valor){
							ctrl.continuarAberturaCobranca(result.valor, novoBoleto = true);
						} else {
							ctrl.continuarAberturaCobranca(ctrl.boleto.codigoBoleto, novoBoleto = false);
						}

					}).catch(function(err) {
						$ionicLoading.hide();
					});

				}
        		
        		vm.openBoletoDetail=function(boleto,$event){
        		 	$event.stopPropagation();
        			//$log.debug('[BoletoDetailComponentController.openBoletoDetail()]:boleto=', boleto);
					ctrl.boleto={codigoBoleto:boleto};
					
					CobrancaService.getExibeProrrogaBoleto(boleto)
                     .then(function(result) {
                    	 if (result.valor){
							ctrl.prorrogarVencimentoBoleto();
						 } else {
							ctrl.continuarAberturaCobranca(ctrl.boleto.codigoBoleto, novoBoleto = false);
						 }
                     })
                     .catch(function(err) {
                         Notification.error(err);
                     });        			
				}
				
				ctrl.continuarAberturaCobranca = function(boleto, isNew){
					//Carregando valores do popup
					let promises = [];
        			promises.push(CobrancaService.listarItensDetalheBoleto(boleto)
					.then(function(result) {
						ctrl.boleto.itensBoleto = result;
						ctrl.boleto.itensBoleto.codigoBoleto = boleto;						 
					})
					.catch(function(err) {
						Notification.error(err);
					}));

					promises.push(CobrancaService.getDadosImpressaoBoleto(boleto)
					.then(function(result) {
						ctrl.boleto.codigoBarras = result.ipte;
					})
					.catch(function(err) {
						Notification.error(err);
					}));

					Promise.all(promises).then(function(){
						$ionicLoading.hide(); 
						if (isNew) $scope.$emit('broadAtualizarCobrancas'); 
					}).catch(erro => { 
						$log.debug("erro.message")
						$ionicLoading.hide(); 
						if (isNew) $rootScope.$broadcast('emitAtualizaBoletos');
					});					
        			vm.modalBoleto.show();
				}        		
        		
                
                
     }]);
})();
