(function () {
  /*
   * Modulo do componenet agenda-list-item (avisosList)
   */
  angular
    .module('agenda')
    .controller('AgendaListItemComponentController', [
      '$log',
      '$scope',
	  '$state',
	  'SessionData',
      AgendaListItemComponentController]);

  	function AgendaListItemComponentController($log, $scope, $state, SessionData) {
		var ctrl = this;

		//$log.debug('[AgendaListItemComponentController]\t$scope=', $scope, 'ctrl=', ctrl);
		ctrl.event.className = typeof(ctrl.event.className) == 'object' ? ctrl.event.className.join(' ') : ctrl.event.className;

		var endData = moment.utc(ctrl.event.end);
		var startData = moment.utc(ctrl.event.start);

		let dayMask = 'DD/MM/YYYY';
		let hourMask = 'HH:mm';
		let formatMask = dayMask + ' - ' + hourMask;
		
		
		ctrl.dataHora = moment(startData).format(ctrl.event.allDay ? dayMask : formatMask);
		
		if (endData.isValid() || !ctrl.event.allDay) {
			var horaEvento = moment(endData).format(endData.isSame(startData, 'day') ? hourMask : formatMask);
			ctrl.dataHora += (horaEvento == "00:00" )|| (moment(startData).local().format('HH:mm') == moment(endData).format('HH:mm')) ? "" : ' - ' + horaEvento;
		}     

		ctrl.dataHora += ctrl.event.allDay ? ' ' : '';
		ctrl.redirecinarMateriaisDisciplina = function redirecinarMateriaisDisciplina(){
			
			$state.go('home.publicacoes', 
				{
					'disciplina': ctrl.event.disciplinaObject
				}
			)
		
		}
      	$log.debug('[AgendaListItemComponentController]\tctrl=', ctrl);    
   	}
})();
