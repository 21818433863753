(function () {
  /*
   * Modulo do componenet ocorrencias-list (ocorrenciasList)
   */
  angular
    .module('ocorrencias')
    .controller('OcorrenciasListController', [
      '$log',
      '$scope',
      'Aluno.Ocorrencias',
      'Aluno.Ocorrencias.Ciencia',
      'SessionData',
      'Notification',
      OcorrenciasListController]);

  function OcorrenciasListController($log, $scope, Aluno_Ocorrencias, Aluno_Ocorrencias_Ciencia, SessionData, Notification) {
      var vm = this;
      vm.id = 'OcorrenciasListController';
      $scope.id = vm.id;

      $log.debug('[OcorrenciasListController]\t$scope=', $scope);
      $log.debug('[OcorrenciasListController]\tSessionData=', SessionData);

      var aluno = SessionData.aluno;
      var alunoSelecionado = aluno.list[aluno.selecionado];

      vm.ocorrencias = [];

      Aluno_Ocorrencias.query({codPessoa : alunoSelecionado.id, codAluno: encodeURIComponent(alunoSelecionado.codAluno)}, Aluno_Ocorrencia_handleSuccess, Aluno_Ocorrencia_handleFailure);

      function Aluno_Ocorrencia_handleFailure(reason) {
        $log.error('[Aluno_Ocorrencia_handleFailure]\terror' + arguments);
        if(reason !== undefined) $log.error('[Aluno_Ocorrencia_handleFailure]\treason:' + reason);
      }
      
      function Aluno_Ocorrencia_handleSuccess(result) {
        $log.debug('[Aluno_Ocorrencia_handleSuccess]\t result=' + result);
        vm.ocorrencias = result.content;

        for (let i = 0; i < vm.ocorrencias.length; i++){
          vm.ocorrencias[i].data = vm.ocorrencias[i].data.substring(0,10);
          vm.ocorrencias[i].data.replace("-", "/");
        }
                
      }
      
      vm.listOpenOcorrenciaDetail = function(item) {
        vm.ocorrenciaItem = item;
        $log.debug('[OcorrenciasListController.listOpenOcorrenciaDetail]\tvm=', vm);
        vm.onOpenOcorrenciaDetail({ocorrencia: item});
        
        Aluno_Ocorrencias_Ciencia.query({codPessoa : SessionData.user.id, codAluno: encodeURIComponent(alunoSelecionado.codAluno)}, item,
        		Aluno_Ocorrencias_Ciencia_handleSuccess, Aluno_Ocorrencias_Ciencia_handleFailure);
      }
      
      function Aluno_Ocorrencias_Ciencia_handleFailure(reason) {
          $log.error('[Aluno_Ocorrencias_Ciencia_handleFailure]\terror' + arguments);
      }
        
      function Aluno_Ocorrencias_Ciencia_handleSuccess(result) {
    	  $log.debug('[Aluno_Ocorrencias_Ciencia_handleSuccess]\t result=' + result);
    	  var status = (result && result.status) ? result.status : null;
    	  
    	  if(status && (status.code == 0)){	// Status code 0 : Ok
    		  vm.ocorrenciaItem.ciente = true; // Define como ciente
    	  } else {
    		  //Mensagem não será exibida - Notification.warning(status.statusMsg);
    	    $log.debug('[Aluno_Ocorrencias_Ciencia_handleSuccess]\t status=' + status.statusMsg);
    	  }
    	  
      }      

      $log.debug('[OcorrenciasListController]\tvm=', vm);
   }
})();