angular
    .module('MyApp')
    .service('DocumentoService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {
      var _aluno = SessionData.aluno;
      var _msgErro = { msgErro: 'Error student undefined' };
           
      /*this.baixarAnexo = function(dadosAnexo) {
          let defer = $q.defer();
          
          let params = {
                "codAviso": dadosAnexo.codAviso,
                "codAnexo": dadosAnexo.codAnexo
            };
          
          if (dadosAnexo) {
              $resource(Configs.getApiServer() + '/pessoas/cod_aviso/:codAviso/cod_anexo/:codAnexo/baixar_anexo_aviso', {}, {
                  cache: false,
                  query: {
                      method: 'GET'
                  } 
                })
                .get(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;
          
      }*/
      
      
    }]);