angular.module('MyApp')
    .service('AvaliaService', ['$resource', '$ionicLoading', '$q', 'SessionData', 'Configs', '$http', 
    function($resource, $ionicLoading, $q, SessionData, Configs, $http) {
        
        var _aluno = SessionData.aluno;

        this.getListaQuestionarios = function getQuestionarios() {
            let defer = $q.defer();
            
            var _objAluno = {
                codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno
            };
            
            if (_objAluno) {
                $resource(Configs.getApiServer() + '/avaliacaoInstitucional/questionarios/codAluno/:codAluno/lista-questionarios', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                    
                  })
                  .query(_objAluno, function(result) {
                      defer.resolve(result);
                  }, function(err) {
                  defer.reject(err);
              });
            } else {
              defer.reject({ _msgErro });
          }
          return defer.promise;          
        }

        this.iniciarQuestionario = function iniciarQuestionario(params){
            let defer = $q.defer();
            
            let objQuestionario = {
                codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
                questionario: encodeURI(params.questionario),
                tipoQuestionario: encodeURI(params.tipo_questionario),
                aplicacao: encodeURI(params.aplicacao),
                formaExibicaoQuestionario: encodeURI(params.forma_exibicao),
                titulo: encodeURI(params.titulo),
                exibeResumo: params.EXIBE_PAG_RESUMO,
                obrigatorio: params.OBRIGATORIA
            };

            if (objQuestionario) {
                $resource(Configs.getApiServer() + '/avaliacaoInstitucional/questionarios/iniciar-questionario', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                    
                  })
                  .query(objQuestionario, function(result) {
                      defer.resolve(result);
                  }, function(err) {
                  defer.reject(err);
              });
            } else {
              defer.reject({ _msgErro });
            }
            return defer.promise;      
        }

        this.obterQuestionarioASQ = function obterQuestionarioASQ(params){
            let defer = $q.defer();
            
            let objQuestionario = {
                codAvaliador: SessionData.aluno.list[_aluno.selecionado].codAluno,
                questionario: encodeURI(params.questionario),
                tipoQuestionario: encodeURI(params.tipoQuestionario),
                aplicacao: encodeURI(params.aplicacao),
                paginaAtual: params.paginaAtual
            };

            if (objQuestionario) {
                $resource(Configs.getApiServer() + '/avaliacaoInstitucional/questionarios/obter-avaliado-seguido-questoes', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                    
                  })
                  .query(objQuestionario, function(result) {
                      defer.resolve(result);
                  }, function(err) {
                  defer.reject(err);
              });
            } else {
              defer.reject({ _msgErro });
            }
            return defer.promise;
        };

        this.obterQuestionarioACG = function obterQuestionarioACG(params){
            let defer = $q.defer();
            
            let objQuestionario = {
                codAvaliador: SessionData.aluno.list[_aluno.selecionado].codAluno,
                questionario: encodeURI(params.questionario),
                tipoQuestionario: encodeURI(params.tipoQuestionario),
                aplicacao: encodeURI(params.aplicacao),
                paginaAtual: params.paginaAtual
            };

            if (objQuestionario) {
                $resource(Configs.getApiServer() + '/avaliacaoInstitucional/questionarios/obter-avaliacao-caixa-grupos', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: false
                    }                    
                  })
                  .query(objQuestionario, function(result) {
                      defer.resolve(result);
                  }, function(err) {
                  defer.reject(err);
              });
            } else {
              defer.reject({ _msgErro });
            }
            return defer.promise;
        };

        this.obterQuestionarioQSA = function obterQuestionarioQSA(params){
            let defer = $q.defer();
            
            let objQuestionario = {
                codAvaliador: SessionData.aluno.list[_aluno.selecionado].codAluno,
                questionario: encodeURI(params.questionario),
                tipoQuestionario: encodeURI(params.tipoQuestionario),
                aplicacao: encodeURI(params.aplicacao),
                paginaAtual: params.paginaAtual
            };

            if (objQuestionario) {
                $resource(Configs.getApiServer() + '/avaliacaoInstitucional/questionarios/obter-questoes-seguidas-avaliados', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: false
                    }                    
                  })
                  .query(objQuestionario, function(result) {
                      defer.resolve(result);
                  }, function(err) {
                  defer.reject(err);
              });
            } else {
              defer.reject({ _msgErro });
            }
            return defer.promise;
        };


        this.responderQuestoesPagina = function responderQuestoesPagina(params){
            let defer = $q.defer();
            
            $http({
                method: 'POST',
                url: Configs.getApiServer() + '/avaliacaoInstitucional/questionarios/responder-questoes-pagina',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                data: params
              })
              .success(function(retorno){
                defer.resolve(retorno);
              })
              .error(function(erro){
                defer.reject(erro);
              });
              return defer.promise;      
        }

        this.enviarRespostasQuestionario = function enviarRespostasQuestionario(params){
            let defer = $q.defer();
            
            $http({
                method: 'POST',
                url: Configs.getApiServer() + '/avaliacaoInstitucional/questionarios/enviar-respostas-questionario',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                data: params
              })
              .success(function(retorno){
                defer.resolve(retorno);
              })
              .error(function(erro){
                defer.reject(erro);
              });
              return defer.promise;      
        }

        this.obterIndiceRendimento = function obterIndiceRendimento() {
            let defer = $q.defer();
            
            var _objAluno = {
                codPessoa: SessionData.aluno.list[_aluno.selecionado].id,
                codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno
            };
            
            if (_objAluno) {
                $resource(Configs.getApiServer() + '/matricula/codPessoa/:codPessoa/codAluno/:codAluno/obter-indice-aluno', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                    
                  })
                  .query(_objAluno, function(result) {
                      defer.resolve(result);
                  }, function(err) {
                  defer.reject(err);
              });
            } else {
              defer.reject({ _msgErro });
          }
          return defer.promise;          
        }
        
    }
]);