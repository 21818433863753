(function() {
angular.module('card')
  .component('cardComponent', {
    templateUrl: 'js/custom-components/card/card.component.html',
    bindings: {
        titleCard: "=",
        columns: "=",
        itens: "=",
        enableCheckbox: "=",
        cardItemStyle: "=",
        filterSearch: "=",
        forceCard: "=",
        cardHint: "=",
        hideTitleBar: "=",
        onSelect: "<",
        messageNull: "=",
        insideFilter: "="
    },
    controller: 'CardComponentController',
    controllerAs: 'vm'
  });

})();
