angular.module('MyApp')
    .service('ProcessoSeletivoService', ["Configs", "$resource", "$q" , "$http", function (Configs, $resource, $q, $http) {

        let methodGetObject= { cache: false, query: { method: 'GET', isArray: false } }
        let methodGetArray = { cache: false, query: { method: 'GET', isArray: true } }
        
        this.obterDadosProcessoSeletivo = function(aluno = {}) {
           
            let deferr = $q.defer();

            let objQuery   = {
                codPessoa : aluno.id,
                codAluno: aluno.codAluno,
            };
           
            $resource(Configs.getApiServer() + '/pessoas/cod_pessoa/:codPessoa/cod_aluno/:codAluno/obter-dados-processo-seletivo', {}, methodGetObject)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterProvasProcessoSeletivo = function(dadosProcesso = {}) {
            let deferr = $q.defer();

            let objQuery   = {
                ano: dadosProcesso.ano,
                codAluno: dadosProcesso.codAluno,
                codPessoa : dadosProcesso.codPessoa,
                semestre: dadosProcesso.semestre,
            };

            if (dadosProcesso.codAluno && dadosProcesso.codPessoa) {
                $resource(Configs.getApiServer() + '/pessoas/obter-lista-provas-processo-seletivo', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

     
    }]);