(function () {
  angular.module('menu')
    .controller('MenuLeftController', [
      '$log',
      '$scope',
      '$http',
      '$rootScope',
      '$state',
      '$timeout',
      '$translate',
      'Notification',
      '$ionicHistory',
      '$ionicModal',
      'serviceAlunos',
      'MenuTree',
      'MenuGroup',
      'SessionData',
      'LogoutService',
      '$location',
      'LOCALES',
      '$ionicLoading',
      '$ionicSideMenuDelegate',
      '$window',
      'Storage',
      'RelatorioService',
      'NeoAssistService',
      MenuLeftController
    ]);

  function MenuLeftController($log, $scope, $http, $rootScope, $state, $timeout, $translate, Notification, $ionicHistory, $ionicModal, serviceAlunos, menuTree, MenuGroup, SessionData, LogoutService, $location, LOCALES, $ionicLoading, $ionicSideMenuDelegate, $window, Storage, RelatorioService, NeoAssistService) {
    var ctrl = this;

    ctrl.activeMenu = $state.current.pageId;
    ctrl.dropdownOpen = '';

    ctrl.rootScope = $rootScope;
    $scope.SessionData = SessionData;
    $log.debug('[MenuLeftController]\tctrl=', ctrl);
    $rootScope.menuLeftLoading = true;

    $scope.locales = {
      list: [],
      selectedLanguage: LOCALES.selectedLanguage || LOCALES.preferredLanguage,
      locales: LOCALES.locales
    };

    /*
     * Ao clicar no nome do Aluno localizado no canto superior esquerdo da tela
     * o mesmo será direcionado para a tela de avisos caso seja um aluno ou para
     * a tela responsavel caso seja o responsável.
     */
    ctrl.url = function () {
      if (SessionData.perfil === "responsavel") {
        return ".responsavel"
      } else {
        return ".avisos"
      }
    }

    if ($rootScope.isLeftSideMenuOpened === undefined || $rootScope.isLeftSideMenuOpened === '') {
      $rootScope.isLeftSideMenuOpened = true;
    }

    $scope.toggleButtonLeftSideMenu = function () {
      if ($rootScope.isLeftSideMenuOpened) {
        angular.element('#toggle-button-left-side-bar').css('left', '50px');
        angular.element('#ion-side-menu-content').css('transform', 'translate3d(50px, 0px, 0px)');
        angular.element('#ion-side-menu-content').css('width', $(window).width() - 50 + 'px');

        $rootScope.sideBarMenuItemClass = 'side-bar-menu-item-closed';
        $rootScope.sideBarMenuIconClass = 'side-bar-menu-icon-closed';
      } else {
        angular.element('#toggle-button-left-side-bar').css('left', '275px');
        angular.element('#ion-side-menu-content').css('transform', 'translate3d(275px, 0px, 0px)');
        angular.element('#ion-side-menu-content').css('width', $(window).width() - 275 + 'px');

        $rootScope.sideBarMenuItemClass = 'side-bar-menu-item-opened';
        $rootScope.sideBarMenuIconClass = 'side-bar-menu-icon-opened';
      }

      $rootScope.isLeftSideMenuOpened = !$rootScope.isLeftSideMenuOpened;

      ctrl.dropdownOpen = '';
      ctrl.grupoSelecionado = null;

    }

    $scope.showCart = function () {
      return $location.url() === '/home/servico-solicitacao';
    }

    $scope.getCarrinhoItemsQuantity = function () {
      if (typeof SessionData.carrinho.length !== "undefined") {
        return 0;
      }
      return SessionData.carrinho.length
    }

    $scope.alunosList = {
      show: false,
      items: SessionData.alunosResponsavel.list
    };

    $scope.getWindowWidth = function () {
      return $(window).width();
    }

    $scope.toggleList = function () {
      $scope.alunosList.show = !$scope.alunosList.show;
    };

    $scope.isListShown = function () {
      return $scope.alunosList.show;
    };

    $scope.isCurrentStudent = function (student) {
      SessionData.aluno.list[SessionData.aluno.selecionado]
    };

    $scope.getPicture = function (aluno) {
      if (aluno.foto) {
        return aluno.foto;
      } else {
        switch (aluno.sexo) {
          case 'F':
            return 'img/avatar_F.png';
          case 'M':
            return 'img/avatar_M.png';
        }
        return 'img/avatar_M.png';
      }
    }

    $scope.selecionarAluno = function (aluno) {
      serviceAlunos.selecionarAluno(aluno);
      $scope.alunosList.show = false;
      $state.go($state.$current.self.name, {}, { reload: true, inherity: true, notify: true });
    };

    var keys = Object.keys(LOCALES.locales);
    for (i in keys) {
      var k = keys[i];
      var v = LOCALES.locales[k];
      $log.debug('k=', k, 'v=', v);
      $scope.locales.list.push({ 'code': k, 'name': v });
    }

    $log.debug('[MenuLeftController]\t$scope=', $scope);

    $ionicModal.fromTemplateUrl('views/logged/_changepassword.view.html', {
      scope: $scope,
      animation: 'slide-in-up'
    }).then(function (modal) {
      ctrl.modal = modal;
    });

    // Modal alterar aluno
    $ionicModal.fromTemplateUrl('views/logged/modal.aluno.alterar.view.html', {
      scope: $scope,
      animation: 'slide-in-up'
    }).then(function (modal) {
      ctrl.modalAluno = modal;
    });

    ctrl.openChangeStudent = function () {
      ctrl.modalAluno.show();
    }

    ctrl.openChangePassword = function () {
      ctrl.modal.show();
    };

    ctrl.closeChangePassword = function () {
      ctrl.modal.hide();
    };

    ctrl.shouldShowDelete = false;
    ctrl.shouldShowReorder = false;
    ctrl.listCanSwipe = true;

    $ionicModal.fromTemplateUrl('views/logged/_selectlocale.view.html', {
      scope: $scope,
      animation: 'slide-in-up'
    }).then(function (modal) {
      ctrl.modal2 = modal;
    });

    ctrl.openSelectLocale = function () {
      ctrl.modal2.show();
    };

    $scope.closeSelectLocale = function () {
      ctrl.modal2.hide();
    };

    $scope.onSelectLanguage = function (selectedLanguage) {
      $log.debug('[onSelectLanguage]\tlocales=', $scope.locales);
      $scope.locales.selectedLanguage = selectedLanguage;
      $translate.use($scope.locales.selectedLanguage.code).then(() => $translate.refresh());

      ctrl.idioma.menu = selectedLanguage.code === 'pt_BR' ? 'pt-br' : 'en-us';

      ctrl.idioma.menu = selectedLanguage.code === 'pt_BR' ? 'pt-br' : 'en-us';

      // Atualiza o botão voltar da HeaderBar do Ionic
      $timeout(function () {
        var el = angular.element(".back-text > .default-title");
        if (el) {
          var btnBackCaption = $translate.instant('Ionic.HeaderBar.Button.Back');
          el.text(btnBackCaption);
        }
      }, 400);
    }

    ctrl.message = {};

    ctrl.selecionado = {
      valor: 1
    }

    ctrl.vibrate = function () {
      // cordova has been removed
    };


    // Menu
    var aluno = SessionData.aluno;
    var alunoSelecionado = aluno.list[aluno.selecionado];

    ctrl.groups = [];

    if (!SessionData.aluno.list[0].permittedPages) {
      SessionData.aluno.list[0].permittedPages = [];
    }

    //ctrl.groups = menuTree.get({codAluno : encodeURIComponent(alunoSelecionado.codAluno), codPessoa : alunoSelecionado.id}, handleSuccess, handleFailure);
    if (!Storage.get("menu")) {
      MenuGroup.get({ codAluno: encodeURIComponent(alunoSelecionado.codAluno), codPessoa: alunoSelecionado.id }, (result) => {
        verifySelectedGroup();
        ctrl.menuAgrupado = result;
        Storage.set('menu', result)
        // valida permissão de acesso à transações
        validaMenuAgrupado();
        if (Storage.get("questionariosObrigatorios")) {
          Notification.info('Você tem questionário(s) obrigatório(s) pendentes.')
          $timeout(function () {
            $state.go('home.avaliacaoinstitucional');
          }, 300);
        }

        if (!$rootScope.menuLeftLoading) {
          $ionicLoading.hide();
        }
        $rootScope.menuLeftLoading = false;
      }, (err) => {
        $ionicLoading.hide();
      })
    } else {
      ctrl.menuAgrupado = Storage.get("menu");
      // valida permissão de acesso à transações
      validaMenuAgrupado();
      $rootScope.menuLeftLoading = false;
      verifySelectedGroup();
    }

    ctrl.idioma = {};
    ctrl.idioma.menu = $translate.instant('Home.view.language');

    $log.debug(ctrl.groups);
    
    function validaMenuAgrupado() {
      // valida visualizacao de submenu e itens do submenu
      // só deve exibir o submenu se houve algum item autorizado dentro
      angular.forEach(ctrl.menuAgrupado, function (submenu) {
        let showItemMenu = false;
        angular.forEach(submenu.itens, function (item) {
          let aluno = SessionData.aluno;
          if (aluno) {
            ctrl.aluno = aluno;
            if (aluno.list) {
              let transacoes = aluno.list[0].transacoes ? aluno.list[0].transacoes : [];
              if (!item.pageId || transacoes.indexOf(item.pageId) >= 0) {
                if (item.emite_relatorio === 'S') {
                  item.url = '.' + $state.$current.self.pageId;
                }
                let strUrl = item.url;
                strUrl = (strUrl && strUrl !== undefined && strUrl.startsWith('.')) ? strUrl.replace('.', '') : strUrl;

                // Preenche a lista de páginas acessíveis pelo aluno
                if (SessionData.aluno.list[0].permittedPages && SessionData.aluno.list[0].permittedPages.indexOf(strUrl) === -1) {
                  SessionData.aluno.list[0].permittedPages.push(strUrl);

                  if (strUrl === 'servico-list') {
                    SessionData.aluno.list[0].permittedPages.push('servico-solicitacao');
                    SessionData.aluno.list[0].permittedPages.push('servico-detail');
                    SessionData.aluno.list[0].permittedPages.push('concluir-solicitacao-servico');
                  }
                }
                item.exibir = true;
                showItemMenu = true;
                return;
              }
            }
          }
          item.exibir = false;
        });
        submenu.exibir = showItemMenu;
      });
    }


    function verifySelectedGroup() {
      ctrl.grupoSelecionado = Storage.get('idGrupoMenu');
    }

    ctrl.acaoMenuDropdown = function acaoMenuDropdown(id) {
      if ($rootScope.isLeftSideMenuOpened == false) {
        $scope.toggleButtonLeftSideMenu();
      }
      if (id === ctrl.grupoSelecionado) {
        ctrl.grupoSelecionado = null;
        return
      }

      ctrl.grupoSelecionado = id;
    }

    ctrl.toggleGroup = function (group) {
      if (ctrl.isGroupShown(group)) {
        ctrl.shownGroup = null;
      }
      else {
        ctrl.shownGroup = group;
      }
    };

    ctrl.isGroupShown = function (group) {
      return ctrl.shownGroup === group;
    };

    $rootScope.logout = LogoutService.logout;

    ctrl.logout = function () {
      $rootScope.logout();

      // Remove Chat NeoAssist, caso ele esteja implementado.
      NeoAssistService.removerNeoAssist();
    }

    ctrl.changePassword = function () {
      $ionicLoading.show({
        content: 'Loading',
        animation: 'fade-in',
        showBackdrop: true,
        maxWidth: 200,
        showDelay: 0
      });

      var idPessoa = SessionData.user.id;

      var user = {
        oldPassword: ctrl.oldPassword,
        newPassword: ctrl.newPassword,
        newPasswordConfirmation: ctrl.newPasswordConfirmation,
        idPessoa: idPessoa,
      };

      $http({
        method: 'PUT',
        url: 'changePassword',
        data: $.param(user),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).success(changeSuccess).error(changeError);

      function changeSuccess(data, status, headers, config) {
        if (status == 200) {
          Notification.info($translate.instant('Home.view.passwordChanged'));
        } else {
          var error = status == 500 ? $translate.instant('Home.view.InvalidPassword') : data.message;
          Notification.error(error);
        }

        $ionicLoading.hide();
        cleanPasswordFields();
      }

      function changeError(data, status, headers, config) {
        var error = status == 401 ? $translate.instant('Home.view.InvalidPassword') : data;
        $ionicLoading.hide();
      }

      function cleanPasswordFields() {
        ctrl.oldPassword = "";
        ctrl.newPassword = "";
        ctrl.newPasswordConfirmation = "";
        ctrl.closeChangePassword();
      }
    }

    ctrl.abrirRecursoExterno = function (item) {
      ctrl.activeMenu = item.pageId;
      Storage.set('idGrupoMenu', item.id_grupo);
      ctrl.grupoSelecionado = item.id_grupo;

      if ($ionicSideMenuDelegate.isOpenLeft()) {
        $ionicSideMenuDelegate.toggleLeft();
      }
      // if item.grupo == S
      if (item.url == '.avisos' && $rootScope.isLeftSideMenuOpened == false)
        $scope.toggleButtonLeftSideMenu()

      if (ctrl.dropdownOpen.length > 0)
        ctrl.dropdownOpen = '';
      else
        ctrl.dropdownOpen = item.url;

      if (item.urlExterna === 'S') {
        //impede o comportamento padrão para transacao de nome canvas
        if (item.pageId.indexOf('canvas') > -1) {
          //captura a url, usuario e senha
          //espera uma url no estilo https://instituicao.instructure.com?user=aaaa@email.com.br&pass=senha
          const regex = /(^.*)[?]user=([^#]*)[&]pass=([^#]*)/g;
          results = regex.exec(item.url);

          var form = document.createElement("form");
          var element1 = document.createElement("input");
          var element2 = document.createElement("input");

          form.id = "login_form";
          form.method = "POST";
          form.target = "_blank";
          form.action = results[1] + "/login/canvas";

          element1.value = results[2];
          element1.name = "pseudonym_session[unique_id]";
          element1.id = "pseudonym_session_unique_id";
          form.appendChild(element1);

          element2.value = results[3];
          element2.name = "pseudonym_session[password]";
          element2.id = "pseudonym_session_password";
          form.appendChild(element2);

          document.body.appendChild(form);
          form.submit();

          var item = document.getElementById("login_form");
          item.parentNode.removeChild(item);
          return;
        }

        if (item.pageId.indexOf('moodlerooms') > -1) {
          //captura a url, usuario e senha
          //espera uma url no estilo https://instituicao.mrooms.net?user=aaaa@email.com.br&pass=senha
          const regex = /(^.*)[?]user=([^#]*)[&]pass=([^#]*)/g;
          results = regex.exec(item.url);

          var form = document.createElement("form");
          var element1 = document.createElement("input");
          var element2 = document.createElement("input");

          form.id = "login_form";
          form.method = "POST";
          form.target = "_blank";
          form.action = results[1] + "/login/index.php";

          element1.value = results[2];
          element1.name = "username";
          element1.id = "username";
          form.appendChild(element1);

          element2.value = results[3];
          element2.name = "password";
          element2.id = "password";
          form.appendChild(element2);

          document.body.appendChild(form);
          form.submit();

          var item = document.getElementById("login_form");
          item.parentNode.removeChild(item);
          return;
        }

        ctrl.insertParam('url_externa', item.url);

        if (!(/Version/.test(navigator.userAgent))) {
          $window.open(item.url, '_blank');
        }

        if (window.location.href.indexOf('url_externa') > -1) {
          var regex = new RegExp('[?&]url_externa(=([^#]*)|&|#|$)'),
            results = regex.exec(window.location.href);

          if ((/Apple/.test(navigator.vendor))) {
            window.location.assign(results[results.length - 1]);
          }
          else {
            window.location.href = window.location.href.replace(results[0], '');
          }
        }
      } else if (item.emite_relatorio === 'S') {
        RelatorioService.downloadReportService(item.pageId, SessionData.aluno.list[0].codAluno);
      }
    }

    ctrl.getLinkUrl = function (item) {
      return item ? $state.href('home' + item.url) : null;
    }

    ctrl.insertParam = function (key, value) {
      var url = window.location.href;

      if (url.indexOf('?') > -1) {
        url += '&' + encodeURI(key) + '=' + encodeURI(value);
      } else {
        url += '?' + encodeURI(key) + '=' + encodeURI(value);
      }

      window.location.href = url;
    }

    // registra aluno atual na sessão
    SessionData.alunoAntigo = SessionData.aluno;
    // registra evento de alteração do aluno selecionado
    $scope.$watch(function () { return SessionData.aluno; },
      function (value) {
        // caso o aluno selecionado tenha mudado, limpa lista de transações autorizadas para forçar recarregamento
        if (SessionData.alunoAntigo != value)
          Storage.set('menu', null);
      }
    );

  }
})();
