/**
 * Controller do componente Matricula Termo
 */
(function () {
    angular.module('matriculaTermoModule')
        .controller('MatriculaTermoComponentController', ['$scope', '$log', 'Notification', '$ionicModal', '$q', 'SessionData', '$ionicLoading', 'MatriculaService', 'Configs', '$state', '$ionicHistory', '$ionicPopup', '$translate', MatriculaTermoComponentController]);
    function MatriculaTermoComponentController($scope, $log, Notification, $ionicModal, $q, SessionData, $ionicLoading, MatriculaService, Configs, $state, $ionicHistory, $ionicPopup, $translate) {

        var ctrl = this;
        ctrl.getWindowWidth    = getWindowWidth;
        ctrl.closeRecusarTermo = closeRecusarTermo;
        ctrl.termoMatriculaTexto = '';

        let configMatricula = $scope.$parent.vm.matriculaController.configMatricula.config;
	    ctrl.instrucoes = configMatricula.instrucoes;

        matriculaController = ctrl.matriculaController;
        ctrl.termoMatriculaTexto = matriculaController.termoMatricula.mensagem;


        function getWindowWidth() {
            return window.innerWidth;
        }

        function openRecusarTermo(){
            $ionicPopup.show({
                template: $translate.instant('Aluno.Matricula.Termo.Texto.Recusa'),
                title: $translate.instant('Aluno.Matricula.Termo.Titulo.Recusa'),
                scope: $scope,

                buttons: [
                    {
                        text: $translate.instant('Aluno.Matricula.Termo.Opcao.Nao'),
                        type: 'button-blue'
                    },
                    {
                        text: $translate.instant('Aluno.Matricula.Termo.Opcao.Sim'),
                        type: 'button-red',
                        onTap: ctrl.closeRecusarTermo
                    }
                ]
             });
        }

		function closeRecusarTermo() {
            $ionicLoading.show({
                content: 'Loading',
                animation: 'fade-in',
                showBackdrop: true,
                maxWidth: 200,
                showDelay: 0
            });

            MatriculaService.aceitoTermo(false).then(function (response) {
                Notification.warning(response.mensagem);
                if(response.continuar === "S"){
                	var wizzardControler = $scope.$parent.$parent.$parent.$parent.$parent.$parent.vm;
                	wizzardControler.currentStep++;
	                $state.go(wizzardControler.steps[wizzardControler.currentStep].state);
	                adjustScroll();
                }
//                $ionicHistory.nextViewOptions({
//                    disableBack: true
//                });

                $ionicLoading.hide();
//                $state.go('home.avisos');
            })
            .catch(function () {
                $ionicHistory.nextViewOptions({
                    disableBack: true
                });

                $ionicLoading.hide();
//                $state.go('home.avisos');
            });
		}

        matriculaController.cancelButton = {
            caption: "Aluno.Matricula.Wizard.Recusar",
            style: "button-red",
            enabled: true,
            onClick: function() {
                let deferr = $q.defer();
                openRecusarTermo();
                deferr.reject();
                return deferr.promise;
            }
        }

        matriculaController.nextButton = {
            caption: "Aluno.Matricula.Wizard.Aceitar",
            style: "button-green",
            enabled: true,
            onClick: function() {
//            	return MatriculaService.aceitoTermo(true);
            	$ionicLoading.show({
                    content: 'Loading',
                    animation: 'fade-in',
                    showBackdrop: true,
                    maxWidth: 200,
                    showDelay: 0
                });

            	let deferr = $q.defer();

                MatriculaService.aceitoTermo(true)
                .then(function(response){
                	deferr.resolve(response);
                	$ionicLoading.hide();
                })
                .catch(function(error){
                	deferr.reject();
                	$ionicLoading.hide();
                });

                return deferr.promise;
            }
        }

    }
})();