(function() {
angular.module('servico')
  .component('servicoList', {
    templateUrl: 'js/custom-components/servico-list/servico-list.component.html',
    bindings: {
      servicos: "="
    },
    controller: 'ServicoListComponentController',
    controllerAs: 'ctrl'
  });

})();
