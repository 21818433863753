angular
    .module('MyApp')
    .service('HistoricoService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {

       var _msgErro = { msgErro: 'Error student undefined' };
       
      this.listarHistoricoSimplificado = function() {
    	  var _aluno = SessionData.aluno;
          var _objAluno = _aluno ? {
              codAluno: _aluno.list[_aluno.selecionado].codAluno
          } : {};

    	  let defer = $q.defer();

        if (_aluno) {
            $resource(Configs.getApiServer() + '/matricula/aluno/:codAluno/listarHistoricoSimplificado', {}, {
                cache: false,
                query: {
                    method: 'GET',
                    isArray: true,
                    transformResponse: function (result) {
                        let disciplinas = [];

                        JSON.parse(result).forEach(function(disciplina) {
                            disciplinas.push({
                                aluno: disciplina.aluno,
                                periodo: ((disciplina.semestre != undefined) && (disciplina.ano != undefined)) ? disciplina.semestre + '/' + disciplina.ano : '',
                                nomeDisciplina: disciplina.disciplina + ' - ' + disciplina.nomeDisciplina,
                                orderSerie: disciplina.orderSerie,
                                serie: disciplina.serie,
                                situacao: disciplina.situacao,
                                notaFinal: disciplina.notaFinal,
                                cargaHoraria: disciplina.cargaHoraria,
                                creditos: disciplina.creditos
                            });
                        });

                        return disciplinas;
                    }
                }
            })
            .query(_objAluno, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
        } else {
            defer.reject({ _msgErro });
        }

        return defer.promise;
      }

      this.listarCabecalhoHistorico = function() {
    	  var _aluno = SessionData.aluno;
          var _objAluno = _aluno ? {
              codAluno: _aluno.list[_aluno.selecionado].codAluno
          } : {};

        let defer = $q.defer();

        if (_aluno) {
            $resource(Configs.getApiServer() + '/matricula/aluno/:codAluno/listarCabecalhoHistorico', {}, {
                cache: false,
                query: {
                    method: 'GET'
                }
            })
            .query(_objAluno, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
        } else {
            defer.reject({ _msgErro });
        }

        return defer.promise;
      }
    }]);