angular.module('MyApp')
.factory('PagamentoCartaoFactory', function(EnvironmentEnum) {
    
    this.calculos = {};
    this.pedidoGerado = {};
    this.cobrancasSelecionadas = {};

    return {
        setCalculos: function(calculos) {
            this.calculos = calculos;
        },
        getCalculos: function() {
            return this.calculos;
        },
        setPedidoGerado: function(pedidoGerado) {
            this.pedidoGerado = pedidoGerado;
        },
        getPedidoGerado: function() {
            return this.pedidoGerado;
        },
        setCobrancasSelecionadas: function(cobrancasSelecionadas) {
            this.cobrancasSelecionadas = cobrancasSelecionadas;
        },
        getCobrancasSelecionadas: function() {
            return this.cobrancasSelecionadas;
        },
        clearCobrancasSelecionadas: function() {
            this.cobrancasSelecionadas = null;
        }
    }
});