angular.module('MyApp')
.factory('Configs', ['Storage', 'EnvironmentEnum', function(Storage, EnvironmentEnum) {
  return {
    getApiServer: getApiServer,
    getApiServerCustom: getApiServerCustom,
    getEnvironment: getEnvironment
  }

  /*
   *  Retorna tipo de ambiente: DEV or PROD
   */
  function getEnvironment() {
    return EnvironmentEnum.DEV;
  }

  /*
   *  Retorna caminho relativo filtrado pelo proxy da API
   */
  function getApiServer() {
    return 'apix';
  }

  function getApiServerCustom() {
    return 'https://apiapp.fia.com.br';
  }

}]);