(function () {
  /*
   * Modulo do componenet ocorrencias-list (ocorrenciasList)
   */
  angular
    .module('ocorrencias')
    .controller('OcorrenciasListItemController', ['$log', OcorrenciasListItemController]);

  function OcorrenciasListItemController($log) {
      var vm = this;

      vm.itemClick = function() {
        $log.debug('[OcorrenciasListItemController.itemClick]');
        vm.itemOpenOcorrenciaDetail();
      }

      vm.itemOpenOcorrenciaDetail = function() {
        $log.debug('[OcorrenciasListItemController.itemOpenOcorrenciaDetail]\t ocorrenciaItem=', vm.ocorrenciaItem);
        vm.onListOpenOcorrenciaDetail({ocorrencia: vm.ocorrenciaItem});
      }

      $log.debug('[OcorrenciasListItemController]\tvm=', vm);
   }
})();