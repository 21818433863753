app.factory('sharedLoginService', [
	'$log',
	'$http',
	'$location',
	'$rootScope',
	'$window',
	'$state',
	'$translate',
	'Notification',
	'$ionicLoading',
	'$timeout',
	'$stateParams',
	'AlunoFromUser',
	'$ionicModal',
	'UserService',
	'Storage',
	'SessionData',
	'Configs',
	'Aluno.AvaliacaoInstitucional',
	'appModalService', 
	'MatriculaService',
	'Storage',
	function($log, $http, $location, $rootScope, $window, $state, $translate,
			 Notification, $ionicLoading, $timeout, $stateParams, AlunoFromUser,
			 $ionicModal, UserService, Storage, SessionData, Configs,
       Aluno_AvaliacaoInstitucional, appModalService, MatriculaService, Storage) {

		var dataset = {};
		var methods = {};
		
	    methods.set = function (data) {
				dataset = data;
			}

	    methods.get = function get() {
				return dataset;
			}

	    methods.login = function() {
	    	
	    	$ionicLoading.show({
	    		content : 'Loading',
	    		animation : 'fade-in',
	    		showBackdrop : true,
	    		maxWidth : 200,
	    		showDelay : 0
	    	});

	    	dataset.message.error = undefined;

        if(window.hostApp) {
          $http({
            method : 'POST',
            url : window.hostApp + 'auth',
            data : methods.serializeData(dataset.user),
            headers : {
              'Content-Type' : 'application/x-www-form-urlencoded'
            }
          }).success(methods.handleSuccess).error(methods.handleError);
        }
        else {
          $ionicLoading.hide();
          Notification.error("HostApp is required!");
        }
      }

	    methods.handleSuccess = function (data, status, headers, config) {
	    	var self= this;

	    	Storage.set("_u", JSON.stringify(data));

    		AlunoFromUser(function() {
        	self.listaAlunos = angular.fromJson(Storage.get('_a'));

        	/*
        	 * Verifica quantidade de alunos e define o perfil do usuário: Responsável ou Aluno
        	 */
       		if(self.listaAlunos.list.length > 1){
       			self.alunosResponsavel = self.listaAlunos;
   				  Storage.set("perfil", "responsavel");
       		}
       		else {
       			self.aluno = self.listaAlunos;
       			Storage.set("perfil", "aluno");
			    }

       		SessionData.create();

       		/*
       		 * Tenta carregar página inicial definida para o aluno
       		 */
			if (Storage.get("autoLogin")){
				var landingState = Storage.get("autoLogin");
			} else {
				var defaultLandingState  = methods.get().url || 'home.avisos';
			}

       		let selAluno = self.listaAlunos.list[self.listaAlunos.selecionado];

       		UserService.getPaginaInicial(selAluno).then( function(result) {
    					if(result != undefined && result.paginaRedirecionamento !== null && result.paginaRedirecionamento !== '') {
    					  landingState = result.paginaRedirecionamento;

    					  if(landingState){

    						/*
    						 * Converte url relativa (e.g. '/home/avisos') para respectivo
    						 * state (e.g. 'home.avisos') quando necessário.
    						 */

    						landingState = landingState.replace(new RegExp('/', 'g'), '.');

    						if (landingState.indexOf('.') === 0) {
    						  landingState = landingState.substring(1);
    						}

    						$log.debug('Página inicial alterada:', landingState);
    					  }
    					}
  				})
  				.catch(function(err){
  					$log.error('Erro no serviço "Página Inicial":', err);
  				})
  				.then(function(){
            // Verify if Contract mus be accept
            MatriculaService.verificaExibeContratoLogin(selAluno.codAluno).then(function success(result) {
							Storage.set("exibirContrato", result.valor);
							
    					$state.go(landingState || defaultLandingState);
    					$ionicLoading.hide();

							if (!result.valor){
								methods.verificarAvaliacaoInstitucional(selAluno);
							}			

            }, function error(result) {
              let errorMsg;
              if (result && result.mensagemDetalhada) {
                errorMsg = result.mensagemDetalhada;
              } else {
                errorMsg = $translate.instant('Aluno.Aceite.Contrato.Login.Servidor.Indisponivel');
              }
              $ionicLoading.hide();
              Notification.error(errorMsg);
            });
    			})
    		});
	    }

	    methods.handleError = function handleError(data, status, headers, config) {
        var errorMsg = data;

        switch (status) {
          case 401:
              errorMsg = $translate.instant('Login.view.invalidPassword');
              break;
          case 403:
              errorMsg = $translate.instant('Login.view.accessDenied', { value: data});
              break;
          case 500:
              errorMsg = $translate.instant('Aluno.Aceite.Contrato.Login.Servidor.Indisponivel');
              break;
        }

	    	$ionicLoading.hide();
	    	Notification.error(errorMsg);
        return;
	    }

	    /**
	     * Transforma o objeto 'data' em uma encoded String com pares
	     * chave/valor prontos para uso em URIs.
	     */
	    methods.serializeData = function (data) {
			if(!angular.isObject(data)) {
			  return ((data === null) ? "" : data.toString());
			}

			var buffer = [];

			for( var name in data) {
				if(!data.hasOwnProperty(name)) {
					continue;
				}

				var value = data[name];
				buffer.push(encodeURIComponent(name) + "=" + encodeURIComponent((value === null) ? "" : value));
			}

			var source = buffer.join("&").replace(/%20/g, "+");

			return (source);
		}

	    methods.selectProfile = function (profile) {
	    	let classPositive    = 'button button-positive';
	        let classOutline     = 'button button-positive button-outline';
	        let loginAluno       = 'Login.view.AlunoUsername';
	        let loginResponsavel = 'Login.view.ResponsavelUsername';

	        dataset.loginAlunoButton       = 'Login.view.AlunoButtonLabel';
	        dataset.loginResponsavelButton = 'Login.view.ResponsavelButtonLabel';

	        if (profile == 'aluno') {
	        	dataset.profile = {};

	        	dataset.profile.name = 'aluno';
	        	dataset.buttonClassAluno = classPositive;
	        	dataset.buttonClassResponsavel = classOutline;
	        	dataset.loginUsername = loginAluno;
	        }

	        if (profile == 'responsavel') {
	        	dataset.profile = {};

	        	dataset.profile.name = 'responsavel';
	        	dataset.buttonClassAluno = classOutline;
	        	dataset.buttonClassResponsavel = classPositive;
	        	dataset.loginUsername = loginResponsavel;
	        }
	    }

    	/**
    	 * Verifica se existe questionários pendente para o aluno responder e o direciona para a avaliação institucional.
    	 */
	    methods.verificarAvaliacaoInstitucional = function verificarAvaliacaoInstitucional (aluno) {
				Aluno_AvaliacaoInstitucional.query({codAluno: aluno.codAluno}, handleSuccess, handleFailure);
								
				// Tratamento de sucesso da chamada ao serviço REST
		    function handleSuccess(result){
		    	if (result.length > 0 && SessionData.aluno.list[0].transacoes.indexOf('avaliacaoinstitucional') > -1) {							
							if (result.find(function(item){ return item.OBRIGATORIA === 'S'; })){
									Storage.set('questionariosObrigatorios', true);									
									return;
							}

							// Define as configurações do modal.
							$ionicModal.fromTemplateUrl('./views/logged/_avaliacao.institucional.view.html', {
								scope : $rootScope,
								animation : 'slide-in-up',
								hardwareBackButtonClose: false,
								backdropClickToClose: false
							}).then(function(modal) {
									$rootScope.modalAvaliacao = modal;
									$timeout(function(){
											modal.show();
									}, 500)
                	
							});						
														
		    	}
		    }

		    // Tratamento de erro da Chamada ao serviço REST
		    function handleFailure(reason){

		        var keys = Object.keys(reason);
		        var str = '';
		        for (var k in keys) {
		          str += reason[k] + '\n';
		        }

		        Notification.error('Problemas ao verificar a avaliação institucional. Erro: ' + str);
		    }
	    }

	    return methods;
	}
]);

(function() {
  angular.module('MyApp')
    .controller('LoginController', ['$rootScope', '$scope', 'sharedLoginService', '$timeout', '$ionicHistory',
      function($rootScope, $scope, sharedLoginService, $timeout, $ionicHistory) {

        var ensureClearState = function (event, data){
          $ionicHistory.clearCache();
          $ionicHistory.clearHistory();
          $scope.user = {};
          $scope.message = {};
          $scope.profile = {};
        };

        $scope.$on("$ionicView.beforeEnter", ensureClearState);

        $scope.login = sharedLoginService.login;
        $rootScope.infiniteReached = function() { }
        $scope.selectProfile = sharedLoginService.selectProfile;
        $scope.selectProfile('aluno');

        sharedLoginService.set($scope);
      }
    ]);
})(app);
