/**
 * Componente Contrato List (contrato-list)
 */
(function(){
	angular.module('contratoListModule').component('contratoList', {
		templateUrl: 'js/custom-components/contrato-list/contrato-list.component.html',
		controller: 'ContratoListComponentController',
		controllerAs: 'ctrl',
		bindings: {
			
		}
	});
})();