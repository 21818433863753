/**
 * Controller do component Boleto Deck (boletoDeck)
 */
(function() {
	angular.module('boletoDeckModule').controller('BoletoDeckComponentController', ['$rootScope', '$log', 'Financeiro.Boletos', 'SessionData', 'Notification', '$ionicLoading', '$timeout', BoletoDeckComponentController]);
	
	function BoletoDeckComponentController($rootScope, $log, Financeiro_Boletos, SessionData, Notification, $ionicLoading, $timeout){
		var self = this;
		
	    $log.debug('[BoletoDeckComponentController]:self=', self);
	    
	    var alunos = SessionData.aluno;
	    self.aluno = alunos.list[alunos.selecionado];
		
		// Lista de Boletos do Aluno
		self.boletos = [];
	    
		$ionicLoading.show({
    		content : 'Loading',
    		animation : 'fade-in',
    		showBackdrop : true,
    		maxWidth : 200,
    		showDelay : 1
		});
		
		$rootScope.$on('emitAtualizaBoletos', function() {
			$timeout(function(){
				Financeiro_Boletos.query({codAluno: encodeURIComponent(self.aluno.codAluno), codPessoa : self.aluno.usuarioLogado.id}, handleSuccess, handleFailure);
			}, 200);
		});
		
	    // Chama o serviço REST
	    Financeiro_Boletos.query({codAluno: encodeURIComponent(self.aluno.codAluno), codPessoa : self.aluno.usuarioLogado.id}, handleSuccess, handleFailure);
	    
	    // Tratamento de Sucesso da chamada ao serviço REST
	    function handleSuccess(result){
			$timeout(function(){
				self.boletos = result;
				$log.debug('BoletoDeckComponentController.Financeiro_Boletos.handleSuccess():\tboletos=', self.boletos);
				$ionicLoading.hide();
			}, 200);
	    }
	    
	    
	    // Tratamento de Erro da Chamada ao serviço REST
	    function handleFailure(reason){
	    	$ionicLoading.hide();
	    	$log.debug('[BoletoDeckComponentController.Financeiro_Boletos.handleFailure]\treason=', reason);
	    	
	        var keys = Object.keys(reason);
	        var str = '';
	        for (var k in keys) {
	          str += reason[k] + '\n';
	        }
	        
	        $log.debug('[[BoletoDeckComponentController.Financeiro_Boletos.handleFailure]\treason=' + str);
	        Notification.error('[BoletoDeckComponentController.Financeiro_Boletos.handleFailure]\treason=' + str);
	    }
	}
})();;