/**
 * Component Boleto Deck (boletoDeck)
 */
(function(){
	angular.module('boletoDeckModule').component('boletoDeck', {
		templateUrl: 'js/custom-components/boleto-deck/boleto-deck.component.html',
		controller: 'BoletoDeckComponentController',
		controllerAs: 'self',
		bindings: {
			aluno: "="
		}
	});
})();