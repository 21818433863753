angular.module('MyApp')
    .service('PosInformacoesService', ["Configs", "SessionData", "$resource", "$q" , "$http", function (Configs, SessionData, $resource, $q, $http) {

        let methodGetObject= { cache: false, query: { method: 'GET', isArray: false } }
        let methodGetArray = { cache: false, query: { method: 'GET', isArray: true } }

        var objRequest = {
            codAluno: encodeURI(SessionData.aluno.list[SessionData.aluno.selecionado].codAluno)
        }
        
        this.obterDadosPosGraduacao = function obterDadosPosGraduacao() {
            let deferr = $q.defer();

            let objQuery = objRequest;

            if (objRequest.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/obter-dados-pos-graduacao', {}, methodGetObject)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarOcorrencias = function listarOcorrencias(aluno = {}) {
            let deferr = $q.defer();

            let objQuery = objRequest;

            if (objQuery.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/listar-ocorrencias', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.obterDadosOrientadores = function obterDadosOrientadores(aluno = {}) {
            let deferr = $q.defer();

            let objQuery = objRequest;

            if (objQuery.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/listar-orientadores', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.obterDadosProficiencia = function obterDadosProficiencia(aluno = {}) {
            let deferr = $q.defer();

            let objQuery = objRequest;

            if (objQuery.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/listar-proficiencias', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.obterQualificacoes = function obterQualificacoes() {
            let deferr = $q.defer();

            let objQuery = objRequest;

            if (objQuery.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/obter-qualificacoes', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }
         
        this.obterBancaQualificacao = function obterBancaQualificacao(idQualificacao = {}) {
            let deferr = $q.defer();

            let objQuery = objRequest;
            objQuery.idQualificacao = idQualificacao;

            if (objQuery.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/idQualificacao/:idQualificacao/obter-banca-qualificacao', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.obterDefesas = function obterDefesas() {
            let deferr = $q.defer();

            let objQuery = objRequest;

            if (objQuery.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/listar-defesas', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.obterBancaDefesa = function obterBancaDefesa(codDefesa = {}) {
            let deferr = $q.defer();

            let objQuery = objRequest;
            objQuery.codDefesa = codDefesa;

            if (objQuery.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/codDefesa/:codDefesa/listar-banca-defesa', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }
        
        this.obterDadosAgencias = function obterDadosAgencias() {
            let deferr = $q.defer();

            let objQuery = objRequest;

            if (objQuery.codAluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/listar-financiadores', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

    }]);