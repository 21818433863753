/**
 * Controller do componente Cobrança List (cobranca-list)
 */
(function () {
	angular.module('contratoListModule').controller('ContratoListComponentController', ['$scope', '$log', 'Notification', '$ionicModal', '$http', 'SessionData', '$ionicLoading', 'ContratoService', 'Configs', 'DownloadArquivo', ContratoListComponentController]);

	function ContratoListComponentController($scope, $log, Notification, $ionicModal, $http, SessionData, $ionicLoading, Contrato_Service, Configs, DownloadArquivo) {
		var ctrl = this;

		$log.debug('[ContratoListComponentController]:ctrl=', ctrl);

		ctrl.sessionData = SessionData;
		ctrl.getWindowWidth = getWindowWidth;
		ctrl.openContratoDetail = openContratoDetail;
		ctrl.closeContratoDetail = closeContratoDetail;
		ctrl.downloadContrato = downloadContrato;
		ctrl.contratos = [];

		var alunos = ctrl.sessionData.aluno;
		ctrl.aluno = alunos.list[alunos.selecionado];

		$ionicLoading.show({
			content: 'Loading',
			animation: 'fade-in',
			showBackdrop: true,
			maxWidth: 200,
			showDelay: 1
		});

		// Chama o serviço REST
		Contrato_Service.consultaContrato().then(function handleSuccess(result) {
			// Tratamento de Sucesso da chamada ao serviço REST
			ctrl.contratos = result;
			$ionicLoading.hide();
		});

		// Tratamento de Erro da Chamada ao serviço REST
		function handleFailure(reason) {
			$ionicLoading.hide();
			var keys = Object.keys(reason);
			var str = '';
			for (var k in keys) {
				str += reason[k] + '\n';
			}
			Notification.error('[ContratoListComponentController.Aluno_Contrato.handleFailure]\treason=' + str);
		}

		function getWindowWidth() {
			return window.innerWidth;
		}

		function openContratoDetail(contratoSelecionado) {
			Contrato_Service.obterContrato(contratoSelecionado).then(function handleSuccess(result) {
				// Tratamento de Sucesso da chamada ao serviço REST
				ctrl.contratoAssinado = result.imagem;
				ctrl.contratoSelecionado = contratoSelecionado;
				ctrl.formatoContrato = result.extensaoContrato;
				$ionicLoading.hide();
				ctrl.modal.show();
			});
		}

		function downloadContrato(contratoSelecionado) {
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});
			
			if(contratoSelecionado){
				ctrl.contratoSelecionado = contratoSelecionado;
			}
			
			let params = {};
			params.url = Configs.getApiServer() + '/matricula/idContratoAluno/' + ctrl.contratoSelecionado + '/exibirArquivo/false/downloadContratoAluno';
			params.method = "GET";
			
			DownloadArquivo.downloadArquivo(params);
		}

		// Fecha os detalhes do boleto
		function closeContratoDetail() {
			ctrl.modal.hide();
		}

		$ionicModal.fromTemplateUrl('js/custom-components/contrato-detail/contrato-itens-modal.html', {
			scope: $scope,
			animation: 'slide-in-up',
		}).then(function (modal) {
			ctrl.modal = modal;
		});

		$scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
			if (ctrl.modal)
				ctrl.modal.hide();
		});

	}
})();
