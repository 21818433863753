(function() {
angular.module('agenda')
  .component('agendaList', {
    templateUrl: 'js/custom-components/agenda-list/agenda-list.component.html',
    bindings: {
      agenda: "="
    },
    controller: 'AgendaListComponentController',
    controllerAs: 'ctrl'
  });

})();
