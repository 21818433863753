(function() {
	/*
	 * Modulo do component tarefa-list (tarefasList)
	 */
	angular.module('tarefa').controller(
			'TarefaListComponentController',
			['$log', 'SessionData', 'Aluno.Tarefas', '$ionicLoading',
					TarefaListComponentController]);

	function TarefaListComponentController($log, SessionData, Aluno_Tarefas, $ionicLoading) {
		var ctrl = this;

		$log.debug('[TarefaListComponentController]\tctrl=', ctrl);
	
		ctrl.sessionData = SessionData;
		ctrl.aluno = ctrl.sessionData.aluno;
		ctrl.agenda = ctrl.sessionData.agenda;

		ctrl.alunoSelecionado = ctrl.aluno.list[ctrl.aluno.selecionado];
		
		$ionicLoading.show({
    		content : 'Loading',
    		animation : 'fade-in',
    		showBackdrop : true,
    		maxWidth : 200,
    		showDelay : 1
    	});
		
		Aluno_Tarefas.query({idAluno: encodeURIComponent(ctrl.alunoSelecionado.codAluno)},
          handleSuccess, handleFailure);
        
        function handleSuccess(result) {
          ctrl.tarefas = result;
          $log.debug('[AlunoTarefas_handleSuccess]\tresult=', result);
          
          $ionicLoading.hide();
        }
        
        function handleFailure(reason) {
          $log.debug('[AlunoTarefas_handleFailure]\treason=', reason);
          Notification.error('[AlunoTarefas_handleFailure]\treason=' + reason);
          
          $ionicLoading.show({
        	  template: str,
        	  duration: 2500
          });
        }

	    if (ctrl.tarefas && ctrl.tarefas.result && ctrl.tarefas.result.content) {
	      ctrl.tarefaList = ctrl.tarefas.result.content;
	    }
	}
    
})();