(function() {
	/*
	 * Modulo do component tarefa-list-item (tarefasList)
	 */
	angular.module('tarefa').controller('TarefaListItemComponentController',
	[ '$log', '$scope', 'Configs', 'DownloadArquivo', '$ionicLoading', TarefaListItemComponentController ]);

	function TarefaListItemComponentController($log, $scope, Configs, DownloadArquivo, $ionicLoading) {
		
		var ctrl = this;

		ctrl.downloadAnexoTarefa = function(idTarefa){
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});
			let params = {};
			params.url = Configs.getApiServer() + '/pessoas/tarefa/anexo/' + idTarefa + '/baixar_anexo_tarefa'; 
			params.method = "GET";

			DownloadArquivo.downloadArquivo(params);
		}

	}
})();