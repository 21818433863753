(function() {
    /*
     * Modulo do componenet agenda-list-item (avisosList)
     */
    angular.module('servico').controller('ServicoListItemComponentController', ['$rootScope', '$ionicLoading', '$ionicModal', '$ionicHistory', 'Notification','ServicoService', 'CartaoService', 'PagamentoCartaoFactory','$log', '$scope', '$state', 'Configs', 'DownloadArquivo', 'SessionData', ServicoListItemComponentController]);

    function ServicoListItemComponentController($rootScope, $ionicLoading, $ionicModal, $ionicHistory, Notification, ServicoService, CartaoService, PagamentoCartaoFactory, $log, $scope, $state, Configs, DownloadArquivo, SessionData) {
    	let aluno = SessionData.aluno.list[0];
        // Carregamento da tela -------------------------------------------------------------------------------------------
        var ctrl = this;
        $log.debug('[ServicoListItemComponentController]\t$scope=', $scope, 'ctrl=', ctrl, 'status=', ctrl.servico.status);
        
        /* OLD STYLE:
        if (ctrl.servico && ctrl.servico.status && ctrl.servico.status.toUpperCase().indexOf("CONCL") > -1) {
            ctrl.className = 'item-balanced';
            ctrl.statusIcon = 'fa fa-check';
            ctrl.dataIcon = 'fa fa-calendar-check-o';
            ctrl.dataIconEntrega = 'fa fa-calendar-check-o';
        } else if (ctrl.servico && ctrl.servico.status && ctrl.servico.status.toUpperCase().indexOf("EM ANDAMENTO") > -1) {
            ctrl.className = 'item-positive';
            ctrl.statusIcon = 'fa fa-spin fa-cog';
            ctrl.dataIcon = 'fa fa-calendar-o';
            ctrl.dataIconEntrega = 'fa fa-calendar-check-o';
        } else if (ctrl.servico && ctrl.servico.status && ctrl.servico.status.toUpperCase().indexOf("CANCELADO") > -1) {
            ctrl.className = 'item-assertive';
            ctrl.statusIcon = 'fa fa-times';
            ctrl.dataIcon = 'fa fa-calendar-times-o';
            ctrl.dataIconEntrega = 'fa fa-calendar-check-o';
        } else {
            ctrl.className = 'item-energized';
            ctrl.statusIcon = 'fa fa-exclamation-triangle';
            ctrl.dataIcon = 'fa fa-calendar-times-o';
            ctrl.dataIconEntrega = 'fa fa-calendar-check-o';
        }
		*/
        if (ctrl.servico && ctrl.servico.status && ctrl.servico.status.toUpperCase().indexOf("CONCL") > -1) {
            ctrl.className = 'fa fa-check-square';
        } else if (ctrl.servico && ctrl.servico.status && ctrl.servico.status.toUpperCase().indexOf("EM ANDAMENTO") > -1) {
            ctrl.className = 'fa fa-clock-o';
        } else if (ctrl.servico && ctrl.servico.status && ctrl.servico.status.toUpperCase().indexOf("CANCELADO") > -1) { 
            ctrl.className = 'fa fa-times';
        }else if (ctrl.servico && ctrl.servico.status && ctrl.servico.status.toUpperCase().indexOf("INDEFERIDO") > -1) { 
            ctrl.className = 'fa fa-times';
        } else {
            ctrl.className = 'fa fa-exclamation-triangle';
        }

        ctrl.servico.isCancel = SessionData.aluno.list[SessionData.aluno.selecionado].transacoes.indexOf('servicos-cancelamento') > -1 ? true : false;
        
        if (ctrl.servico.boleto !== null) {

            ServicoService
                .obterPermitePagarServicoCartao(ctrl.servico.boleto, ctrl.servico.codAluno)
                .then(function(result) {
                    ctrl.servico.permitePagarServicoCartao = result.valor;
                })
                .catch(function(err) {
                    Notification.error(err);
                    $ionicHistory.nextViewOptions({
                        disableBack: true
                    });
                });
        }

        ctrl.calcularPrazo = calcularPrazo;
        
        $ionicModal.fromTemplateUrl('./js/custom-components/servico-list-item/modal.detalhe.servico.solicitado.html', {
    	        scope: $scope,
    	        animation: 'slide-in-up'
    	    }).then(function(modal) {
    	        $scope.modal = modal;
    	    });

        // Métodos -------------------------------------------------------------------------------------------
         $scope.openDetailService = function(params) {
           
           var codSolicitacao = params.codSolicitacao;
           var codItemSolicitacao = params.itemSolicitacao;
           var codServico = params.servico;
           var codAluno = params.codAluno;
           loading(true);
           
           var andamentosSolicitacao = [];
           var anexosAndamentosSolicitacao = [];
           var codItem;
           $scope.solicitacaoDetalhe = {}
           $scope.solicitacaoDetalhe.existeDados = false;
           
           ServicoService
                        .obterAndamentoSolicitacaoServico(codSolicitacao, codItemSolicitacao)
                        .then(async function(result) {
                            
                            if (result.length > 0) {
                                $scope.solicitacaoDetalhe.andamentosSolicitacao = result;
                                $scope.solicitacaoDetalhe.existeDados = true;
                                for (i = 0; i < result.length; i++) {
                                    andamentosSolicitacao.push(result[i].andamento);
                                }
                            }

                            if (andamentosSolicitacao.length > 0) {
                                for (i = 0; i < andamentosSolicitacao.length; i++) {
                                    await obterAndamentoAnexo(andamentosSolicitacao[i]);
                                }

                                $scope.solicitacaoDetalhe.andamentosAnexos = anexosAndamentosSolicitacao;
                            }

                            loading(false);

                        })
                        .catch(function(err) {
                            Notification.error(err);
                            loading(false);
                            $ionicHistory.nextViewOptions({
                                disableBack: true
                            });
                        });

                    ServicoService
                        .obterParametroServicoSolicitado(codAluno, codSolicitacao, codItemSolicitacao, codServico)
                        .then(function(result) {
                            //loading(false);
                            if (result.length > 0) {
                                $scope.solicitacaoDetalhe.parametrosServicosSolicitacoes = result;
                                $scope.solicitacaoDetalhe.existeDados = true;
                            }
                        })
                        .catch(function(err) {
                            Notification.error(err);
                            //loading(false);
                            $ionicHistory.nextViewOptions({
                                disableBack: true
                            });
                        });

                async function obterAndamentoAnexo(codAndamentoSolicitacao) {
                    await ServicoService
                        .obterAndamentoAnexo(codAndamentoSolicitacao)
                        .then(function(result) {
                            //loading(false);
                            if (result.length > 0) {
                                for (i = 0; i < result.length; i++) {
                                    anexosAndamentosSolicitacao.push(result[i]);
                                }
                            }
                        })
                        .catch(function(err) {
                            Notification.error(err);
                            loading(false);
                            $ionicHistory.nextViewOptions({
                                disableBack: true
                            });
                        });
                }
                
            $scope.modal.show()
        }

        $scope.downloadAnexoAndamento = function(chave) {
            loading(true);
                                          
            let params = {};
            params.url = Configs.getApiServer() + '/pessoas/chave_andamento/'+ chave +'/download_anexo_andamento'; 
            params.method = "GET";
            
            DownloadArquivo.downloadArquivo(params);
        }
        
        ctrl.pagarCartao = function() {
            let cobrancas;
            let params = {
                "id_boleto": ctrl.servico.boleto,
                "cod_aluno": ctrl.servico.codAluno
            };     
            CartaoService.obterDadosPagarServicoCartao(params).then(function(retorno) {
            	cobrancas = {
            		valorDocumento: retorno.cartaoValorDocumento,//cartaoItensCobrancas[0].valorCobrancaAPagar,
    				origem: retorno.cartaoOrigem,//cartaoItensCobrancas[0].descricao,
    				dataCalculoValor: retorno.cartaoDataCalculoValor,
    				codResponsavelFinanceiro: retorno.cartaoCodRespFinan,
    				opcaoPgtoCartao: retorno.cartaoOpcaoPgto,
    				cobrancasConcatenadas: retorno.cartaoCobrancasSelecionadas,
                    cartaoItensCobrancas: retorno.cartaoItensCobrancas,
                    valorSelecionado: retorno.cartaoValorOriginalDocumento,
                    encargoCalculado: retorno.cartaoEncargoDocumento,
                    encargoBolsa: retorno.cartaoEncargoBolsaDocumento,
                    descontoAdiantamentoCalculado: retorno.cartaoDescontoDocumento
    			};

                PagamentoCartaoFactory.setCobrancasSelecionadas(cobrancas);
                $state.go('home.pgtocartao-pagamento');

            }).catch(function() {
                Notification.error($translate.instant('General.ErrorNotSpecified'));
            });
        }
        
        function loading(value) {
			ctrl.loading = value;
			if (value)
				$ionicLoading.show({
					content : 'Loading',
					animation : 'fade-in',
					showBackdrop : true,
					maxWidth : 200,
					showDelay : 1
				});
			else
				$ionicLoading.hide();
        }
        
        $scope.getWidth = function(){ 
            return $(window).width(); 
        };
        
        ctrl.downloadBoleto = function(servico) {
        	loading(true);
        	
        	let params = {};
			params.url = Configs.getApiServerCustom() + '/financeiro/imprime-boleto/' + codBoleto;
			params.type = 'application/pdf';
			params.nomeArquivo = 'Boleto_' + servico.boleto + '_' + new Date().getTime()+'.pdf';
			params.method = "GET";
			
			DownloadArquivo.downloadArquivoBoleto(params);
			
        }
        
        ctrl.downloadAnexo = function(servico) {
        	loading(true);
        	let params = {};
			params.url = Configs.getApiServer() + '/pessoas/codigo_anexo/' + servico.seqServicoAnexo + '/baixa_anexo_solicitacao_servico'; 
			params.method = "GET";
			
			DownloadArquivo.downloadArquivo(params);
        }  
        
		ctrl.downloadDocumento = function(servico) {
			loading(true);
			let params = {};
			params.url = Configs.getApiServer() + '/pessoas/codSolicitacao/' + servico.codSolicitacao + '/codItemSolicitacao/' + servico.itemSolicitacao + '/obtemDocumentoCertificacao';
			params.method = "GET";
			
			DownloadArquivo.downloadArquivo(params);
        }
        
        ctrl.cancelarServico = function cancelamento(solicitacao){
            loading(true);
            ServicoService.cancelarSolicitacaoServico(solicitacao.codSolicitacao, solicitacao.itemSolicitacao).then(function success(result){
                
                if (result.conteudo && result.conteudo.toLowerCase() === "cancelado"){
                    Notification.success("Serviço Cancelado com Sucesso.")
                    $scope.$emit('callListServicos');
                    return;
                }
                $scope.$emit('callListServicos');

            }, function failure(error){
                loading(false);
                $ionicHistory.nextViewOptions({
                    disableBack: true
                });
            });
        }

      ctrl.visualizarDocumento = function (solicitacao) {
        $ionicLoading.show({
          content : 'Loading',
          animation : 'fade-in',
          showBackdrop : true,
          maxWidth : 200,
          showDelay : 1
        });

        var params = {};
        params.url = Configs.getApiServer() + '/pessoas/solicitacao/' + solicitacao.codSolicitacao + '/itemSolicitacao/' + solicitacao.itemSolicitacao + '/exibirArquivo/false/visualizaDocumentoServico';
        params.method = "GET";

        DownloadArquivo.downloadArquivo(params);
      }

        // Calcula o prazo de entrega
        function calcularPrazo() {
            if (ctrl.servico && ctrl.servico.data !== undefined && ctrl.servico.data !== null) {
                var dtTimestamp = ctrl.servico.dataDate;
                var prazoEntrega = (ctrl.servico && ctrl.servico.prazoEntrega) ? ctrl.servico.prazoEntrega : 0;

                dtTimestamp = dtTimestamp + ((24 * 60 * 60 * 1000) * prazoEntrega);
                var dtPrazo = new Date(dtTimestamp);

                ctrl.servico.prazoEntregaDate = dtPrazo.toString();
                ctrl.servico.prazoEntregaTimestamp = dtPrazo.getTime();
            }
        }
        calcularPrazo();
    }
})();
