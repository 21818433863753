(function(){
    angular
        .module('historicoCabecalhoModule')
        .component('historicoCabecalho', {
            templateUrl: 'js/custom-components/historico-cabecalho/historico.cabecalho.html',
            bindings: {
                cabecalho: "="
            },
            controller: 'HistoricoCabecalhoController',
            controllerAs: 'historicoCtrl'
        });
})();