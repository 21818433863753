(function() {
angular.module('wizard')
  .component('wizardComponent', {
    templateUrl: 'js/custom-components/wizard/wizard.component.html',
    bindings: {
        steps: "=",
        scrolling: "=",
        fixButton: "=",
        cancelButton: "=",
        nextButton: "=",
        finalButton: "=",
        hasHeader: "=",
        additionalButton: "<",
        style: "<"
    },
    controller: 'WizardComponentController',
    controllerAs: 'vm'
  });

})();
