angular.module('MyApp')
    .service('PosTCCService', ["Configs", "$resource", "$q" , "$http", "DownloadArquivo", "Notification", function (Configs, $resource, $q, $http, DownloadArquivo, Notification) {

        let methodGetObject= { cache: false, query: { method: 'GET', isArray: false } }
        let methodGetArray = { cache: false, query: { method: 'GET', isArray: true } }
        
        this.obterTrabalhoDeConclusao = function(aluno = {}) {
           
            let deferr = $q.defer();

            let objQuery   = {
                codAluno: aluno.codAluno,
            };
           
            $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/obter-trabalho-conclusao', {}, methodGetObject)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterAreasPesquisas = function(aluno = {}) {
           
            let deferr = $q.defer();

            let objQuery   = {
                codAluno: aluno.codAluno,
            };
           
            $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/listar-areasPesquisa-trabalho-conclusao', {}, methodGetArray)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterPatrocinadores = function(aluno = {}) {
           
            let deferr = $q.defer();

            let objQuery   = {
                codAluno: aluno.codAluno,
            };
           
            $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/listar-patrocinadores', {}, methodGetArray)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.urlTCC = function(aluno = {}) {
           
            let deferr = $q.defer();

            let objQuery   = {
                codAluno: aluno.codAluno,
            };
           
            $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/urlTcc', {}, methodGetObject)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterArquivoTCC = function(aluno = {}) {
           
            let deferr = $q.defer();

            let objQuery   = {
                codAluno: aluno.codAluno,
            };
           
            $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/obter-arquivoTCC', {}, methodGetObject)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterVinculoEmpregaticio = function() {
           
            let deferr = $q.defer();

            $resource(Configs.getApiServer() + '/matricula/listar-vinculo-empregaticio', {}, methodGetArray)
            .query({}, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterTiposEmpresas = function() {
           
            let deferr = $q.defer();

            $resource(Configs.getApiServer() + '/matricula/listar-tipos-empresas', {}, methodGetArray)
            .query({}, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterExpectativaAtuacao = function() {
           
            let deferr = $q.defer();

            $resource(Configs.getApiServer() + '/matricula/listar-expectativa-atuacao', {}, methodGetArray)
            .query({}, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterGrandesAreas = function() {
           
            let deferr = $q.defer();

            $resource(Configs.getApiServer() + '/matricula/listar-grandes-areas', {}, methodGetArray)
            .query({}, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.inserirAlterarTCC = function (tcc){
            let defer = $q.defer();
            let params = { ...tcc }
            
            $http({
                method: 'POST',
                url: Configs.getApiServer() + '/matricula/inserir-alterarTCC',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                data: params
              })
              .success(function(retorno){
                defer.resolve(retorno);
              })
              .error(function(erro){
                defer.reject(erro);
              });
              return defer.promise;    
        }

        this.inserirPatrocinador = function( patrocinadores ) {

            let defer = $q.defer();
            let params = patrocinadores;
            
            $http({
                method: 'POST',
                url: Configs.getApiServer() + '/matricula/inserir-patrocinador',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                data: params
              })
              .success(function(retorno){
                defer.resolve(retorno);
              })
              .error(function(erro){
                defer.reject(erro);
              });
              return defer.promise;     
        }

        this.excluirPatrocinador = function(patrocinadores) {

            let defer = $q.defer();
            let params = patrocinadores;
            
            $http({
                method: 'POST',
                url: Configs.getApiServer() + '/matricula/excluir-patrocinador',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                data: params
              })
              .success(function(retorno){
                defer.resolve(retorno);
              })
              .error(function(erro){
                defer.reject(erro);
              });
              return defer.promise;     
        }



        this.inserirAlterarAreasTCC  = function(areasTcc) {

            let defer = $q.defer();
            let params = { ...areasTcc }
            
            $http({
                method: 'POST',
                url: Configs.getApiServer() + '/matricula/inserir-alterar-areasTCC',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                data: params
              })
              .success(function(retorno){
                defer.resolve(retorno);
              })
              .error(function(erro){
                defer.reject(erro);
              });
              return defer.promise;     
        }

        this.enviarArquivoTCC = function (aluno = {}, titulo ,file, base64Data){

            let deferr = $q.defer();

            let arquivo = {
                codAluno: aluno.codAluno,
                titulo: titulo,
                contentType: file.type,
                fileData:base64Data,
                fileName:file.name
            }

            
            var req = {
                method: 'POST',
                url: Configs.getApiServer() + '/matricula/enviar-arquivoTCC/',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: {
                    ...arquivo
                }
            }

            $http(req).then(function (resp) {
                deferr.resolve(resp);
                //$scope.anexoInvalido = false;
            }, function (resp) {
                deferr.reject();
                Notification.error('Erro ao carregar arquivo: ' + resp.status);
                //$scope.anexoInvalido = true;
            });

            return deferr.promise;
        }

        this.downloadPublicacao = function (idPublicacao, tipo) {
           
            let params = {};
            params.url = Configs.getApiServer() + '/matricula/idPublicacao/' + idPublicacao + '/tipo/' + tipo + '/download-publicacao'; 
            params.method = "GET";
            DownloadArquivo.downloadArquivo(params);
        }

    }]);