(function() {
	/*
	 * Modulo do componenet agenda-list (avisosList)
	 */
	angular.module('servico').controller(
			'ServicoListComponentController',
			['$log', '$state', '$scope','SessionData', '$ionicLoading', 'Aluno.Secretaria.Servicos',
					ServicoListComponentController]);

	function ServicoListComponentController($log, $state, $scope, SessionData, $ionicLoading, Servicos) {
		
		var ctrl = this;
		function loading(value) {
			ctrl.loading = value;
			if (value)
				$ionicLoading.show({
					content : 'Loading',
					animation : 'fade-in',
					showBackdrop : true,
					maxWidth : 200,
					showDelay : 1
				});
			else
				$ionicLoading.hide();
		}
		loading(true);

		$log.debug('[ServicoListComponentController]\tctrl=', ctrl);
		ctrl.sessionData = SessionData;
		ctrl.aluno = ctrl.sessionData.aluno;
		ctrl.agenda = ctrl.sessionData.agenda;

		ctrl.alunoSelecionado = ctrl.aluno.list[ctrl.aluno.selecionado];

    if (ctrl.servicos && ctrl.servicos.result && ctrl.servicos.result.content) {
      ctrl.servicoList = ctrl.servicos.result.content;
    }
    
		loading(false);
		$scope.novaSolicitacao = function(){
		  $state.go("home.servico-solicitacao");
		}
/*
		Servicos.query({
			alunoId : ctrl.alunoSelecionado.id
		}, Servicos_handleSuccess, Servicos_handleFailure);

		function Servicos_handleSuccess(result) {
			$log.debug('[Servicos_handleSuccess]\tresult=', result);
			ctrl.servicoList = result.content;
		}

		function Servicos_handleFailure(reason) {
			$log.debug('[Servicos_handleFailure]\treason=', reason);
			Notification.error('[Servicos_handleFailure]\treason=' + reason);
		}
*/
	}
})();
