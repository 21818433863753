(function () {
  /*
   * Modulo do componenet agenda-list (avisosList)
   */
  angular
    .module('agenda', [])
    .controller('AgendaListComponentController', [
      '$log',
      AgendaListComponentController]);

  function AgendaListComponentController($log) {
      var ctrl = this;
      
      $log.debug('[AgendaListComponentController]\tctrl=', ctrl);

      ctrl.events = [];
      
      var selDate = moment(ctrl.agenda.selectedDate);
      if (ctrl.agenda.events){
        for (i=0; i<ctrl.agenda.events.length; i++) {
          ev = ctrl.agenda.events[i];
          var start = moment(ev.start),
                end = moment(ev.end);
          if (!end.isValid()) end = start;
          
          $log.debug('ev=', ev, 'selDate=', selDate, 'start=', start, 'end=', end);
          $log.debug('selDate ISO=', selDate.valueOf());
          $log.debug('  start ISO=',   start.valueOf());
          $log.debug('    end ISO=',     end.valueOf());
          
          if ((selDate.isAfter(start, 'day') || selDate.isSame(start, 'day')) &&   
              (selDate.isBefore(end , 'day') || selDate.isSame(end, 'day')) ) {
            ctrl.events.push(ev);
          }
        }
      }    

      ctrl.noEvent = ctrl.events.length === 0;
   }
})();
