angular.module('MyApp')
    .service('FichaMedicaService', ["Configs", "$resource", "$q" , "$http", function (Configs, $resource, $q, $http) {

        this.obterFichaMedica = function(codAluno) {
            let deferr = $q.defer();

            if (codAluno) {
                $resource(Configs.getApiServer() + '/pessoas/cod_aluno/:codAluno/obter-ficha-medica', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                    
                  }).
                    query({
                        codAluno: codAluno,
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }


        this.salvarFichaMedica = function(oldFicha, newFicha) {

            let defer = $q.defer();

            let params = {oldFicha, newFicha}
            
            $http({
                method: 'POST',
                url: Configs.getApiServer() + '/pessoas/alterar-ficha-medica',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                data: params
              })
              .success(function(retorno){
                defer.resolve(retorno);
              })
              .error(function(erro){
                defer.reject(erro);
              });
              return defer.promise;     
        }

    }]);