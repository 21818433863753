(function () {
  /*
   * Modulo do componenet avisos-list (avisosList)
   */
  angular
    .module('avisos')
    .controller('AvisosListItemController', ['$log', AvisosListItemController]);

  function AvisosListItemController($log) {
      var vm = this;

      vm.itemClick = function() {
        $log.debug('[AvisosListItemController.itemClick]');
        vm.itemOpenAvisoDetail();
      }

      vm.itemOpenAvisoDetail = function() {
        $log.debug('[AvisosListItemController.itemOpenAvisoDetail]\t avisoItem=', vm.avisoItem);
        if((vm.avisoItem.aviso.content).search(/<a href=.*?>/gi) > -1){
        	vm.avisoItem.aviso.content = (vm.avisoItem.aviso.content).replace(/<a href=.*?>/gi,"<a class=\"linkAviso\">");
        	vm.avisoItem.aviso.atualizaLink = true;
        }else{
        	vm.avisoItem.aviso.atualizaLink = false;
        }
        vm.onListOpenAvisoDetail({aviso: vm.avisoItem});
      }
      $log.debug('[AvisosListItemController]\tvm=', vm);
   }
})();
