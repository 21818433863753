angular
    .module('MyApp')
    .service('AlunoExtratoService', ['$resource', '$http', '$q', '$log', 'SessionData', 'Configs', 'FileSaver', function($resource, $http, $q, $log, SessionData, Configs, FileSaver) {
        var _msgErro = {msgErro: 'Error student undefined'};
    	var aluno;
    	function dadosValidosAluno(){
    		if(SessionData.aluno){
    			aluno = {codPessoa: SessionData.aluno.list[SessionData.aluno.selecionado].id, codAluno: SessionData.aluno.list[SessionData.aluno.selecionado].codAluno};
    			return true;
    		}
    		return false;
    	}
    	
        this.obterAnoDividaAluno = function() {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/codAluno/:codAluno/listarAnosDividaAluno', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterPeriodoDividaAluno = function(ano) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
            	var params = aluno;
            	params.ano =  ano;
                $resource(Configs.getApiServer() + '/financeiro/codAluno/:codAluno/ano/:ano/listarPeriodosDividaAluno', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(params, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }
        
        this.obterExtratoFinanceiro = function(dataInicial, dataFinal, ano, periodo) {
            let deferr = $q.defer();
            
            dadosValidosAluno();
            var params = aluno;
            params.ano = "";
            params.periodo = "";
            params.dataVencimentoInicial = dataInicial;
            params.dataVencimentoFinal = dataFinal;
            
            $resource(Configs.getApiServerCustom() + '/financeiro/obterExtratoFinanceiro', {}, {
                    'query': {
                        method: 'POST',
                        isArray: false,
                        headers: { 'Authorization': 'Basic ' + btoa('apiuser:apiuser') }
                    }
                }).query(params,
                    function(response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                    },
                    function() {
                        deferr.reject();
                    });
 
            return deferr.promise;
        }
        
        this.obterExtratoFinanceiroPdf = function(dataInicial, dataFinal, ano, periodo) {
            
        	dadosValidosAluno();
        	var params = aluno;
            params.ano = ano;
            params.periodo = periodo;
            params.dataVencimentoInicial = dataInicial;
            params.dataVencimentoFinal= dataFinal;
            
            $http.post(Configs.getApiServer() + '/financeiro/obterExtratoFinanceiroPdf', params, { responseType: 'arraybuffer' }).then(
                    response => {
                        saveFileAs(response);
                    },
                    data => {
                    	$log.debug("Erro ao realizar o download do arquivo.");
                    }
                );
            function saveFileAs(response) {
                var contentDisposition = response.headers("content-disposition");
                var fileName = contentDisposition.substr(contentDisposition.indexOf("filename=") + 9);
                fileName = fileName.replace(/\"/g, "");
                var contentType = response.headers("content-type");
                var blob = new Blob([response.data], { type: contentType });
                FileSaver.saveAs(blob, fileName);
            } 
        }        
    }]);