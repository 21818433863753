angular.module('MyApp')
    .service('ContratoService', ["Configs", "SessionData", "$resource", "$q", function (Configs, SessionData, $resource, $q) {

        this.consultaContrato = function () {
            let deferr = $q.defer();
            var aluno = SessionData.aluno.list[SessionData.aluno.selecionado];
            if (aluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:alunoId/obterContratosAluno',
                    {}, { 'query': { method: 'GET', isArray: true}}).query({
                        alunoId: aluno.codAluno
                    }, function (response) {
                        if (response) {
                            deferr.resolve(response)
                        } else {
                            deferr.resolve(false)
                        }
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }     
        
        this.obterContrato = function (contratoSelecionado = 0) {
            let deferr = $q.defer();
            if (contratoSelecionado > 0) {
                $resource(Configs.getApiServer() + '/matricula/idContratoAluno/:idContratoAluno/getObterContratoAlunoImagem',
                    {}, { 'query': { method: 'GET'}}).query({
                        idContratoAluno: contratoSelecionado
                    }, function (response) {
                        if (response) {
                            deferr.resolve(response)
                        } else {
                            deferr.resolve(false)
                        }
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }
}]);