/**
 * Component Boleto Detail (boletoDetail)
 */
(function(){
	angular.module('boletoDeckModule').component('boletoDetail', {
		templateUrl: 'js/custom-components/boleto-detail/boleto-detail.component.html',
		controller: 'BoletoDetailComponentController',
		controllerAs: 'ctrl',
		bindings: {
			boleto: "="
		}
	});
})();