angular.module('MyApp').service('NeoAssistService', ["Configs", "SessionData", "$resource", "$q", NeoAssistService]);


function NeoAssistService(Configs, SessionData, $resource, $q){

    /*
     * Flag que indica se o Script NeoAssist foi carregado (true) ou não (false).
     * TODO: Implementar uso futuramente, caso necessário.
     */
    this._scriptCarregado = false;


    /**
     * Limpa os valores das variáveis globais do NeoAssist.
     */
    this.limparVariaveisNeoAssist = function(){
        neoIES="";
        neoNomeIES="";
        neoAluno="";
        neoRA="";
        neoMail="";
    };


    /**
     * Remove DIV elemento do NeoAssist.
     */
    this.removerNeoAssist = function(){
        this.limparVariaveisNeoAssist();

        let elNeoAssist = angular.element('#' + NEO_ASSIST_DIV);

        if(elNeoAssist){
            elNeoAssist.remove();
        }

        this._scriptCarregado = false;
    };

    
    /**
     * Obtém os dados do aluno para uso do chat NeoAssist.
     */
    this.obterDadosNeoAssist = function(codAluno){
        let defer = $q.defer();

        // Obtém os dados para preenchimento do NeoAssist, com base no código do aluno.
        if (codAluno) {
            $resource(Configs.getApiServer() + '/custom/cod_aluno/:cod_aluno/obtem_dados_neoassist',
                      {}, { 'query': {method: 'GET' }}).query({
                          cod_aluno: encodeURIComponent(codAluno)
                      }, function(response){
                            if(response.neoAluno){
                                let dadosNeoAssist = {
                                    'neoAluno': response.neoAluno,
                                    'neoIES': response.neoIES,
                                    'neoMail': response.neoMail,
                                    'neoNomeIES': response.neoNomeIES,
                                    'neoRA': response.neoRA,
                                    'nomeAbreviadoIES': response.nomeAbreviadoIES
                                }
                                
                                defer.resolve(dadosNeoAssist);                                
                            } else {
                                defer.resolve(false);
                            }
                      });
        } else {            
            defer.resolve(false);
        }

        return defer.promise;
    };


    /**
     * Atualiza os dados das variáveis globais do NeoAssist.
     * 
     * @param dadosNeoAssist Objeto json com os dados atualizados. Ex.:
     *      {
     *          'neoAluno': 'Aluno XXX',
     *           'neoIES': 'Código instituição',
     *           'neoMail': 'E-mail do aluno',
     *           'neoNomeIES': 'Nome da instituição',
     *           'neoRA': 'RA do aluno'
     *      }
     */
    this.atualizarDadosFormularioNeoAssist = function(dadosNeoAssist){
        // Preenche as variáveis globais do NeoAssist.
        neoIES = dadosNeoAssist.neoIES;
        neoNomeIES = dadosNeoAssist.neoNomeIES;
        neoAluno = dadosNeoAssist.neoAluno;
        neoRA = dadosNeoAssist.neoRA;
        neoMail = dadosNeoAssist.neoMail;

        // Verifica se a função global do NeoAssist existe e a executa 
        // para atualização dos dados no formulário do chat.
        if(typeof loadNeoForm === 'function'){
            console.log('Loading NeoAssist Form...');
            loadNeoForm(neoIES, neoAluno, neoRA, neoMail);
        }
    };


    /**
     * Carrega o Script customizado do cliente inserido no arquivo 'js/neoassist.js'.
     * @param instituicao nome abreviado da Instituição (nome_abrev).
     * @param dadosNeoAssist Objeto json com os dados atualizados.
     */
    this.loadNeoAssistScript = function(instituicao, dadosNeoAssist){
        if(this._scriptCarregado === false){
            this.removerNeoAssist();
            loadNeoAssist(instituicao, () => (this.atualizarDadosFormularioNeoAssist(dadosNeoAssist)) );
        }
    };

}