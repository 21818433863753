/*
 * Script para ativação do NeoAssist .
 *
 * Para utilização, insira o script do NeoAssist no corpo na function 'loadNeoAssist' abaixo, no local indicado.
 */

// #################################################################################

// * ID da DIV padrão, criada pelo NeoAsssit (Alterar somente se necessário).
const NEO_ASSIST_DIV = 'neoassist';

 /* Variáveis globais para preenchimento dos dados do aluno - NÃO ALTERAR  */
var neoIES="";
var neoNomeIES="";
var neoAluno="";
var neoRA="";
var neoMail="";

// #################################################################################

/**
 * loadNeoAssist
 * 
 * @param instituicao Nome abreviado da Instituição.
 * @param callback    Função de callback a ser executada após inserção do(s) script.
 */
var loadNeoAssist = function(instituicao, callback){
    if(instituicao !== undefined){
        // *************************************************************************
        // *************************************************************************
        // * Insira o script de carregamento do NeoAssit no local indicado abaixo. *
        // * Para inserir scripts diferentes, por instituição, utilize a estrutura *
        // * 'if/else' (ou 'switch/case') para verificar o valor da variável       *
        // * 'instituicao' e executar                                              *                                             
        // * o script desejado para a instituição em questão.                      *
        // * Exemplo:                                                              *
        //                                                                         *
        //         if(instituicao === 'PUC-MG'){                                   *
        //             >> insira o script referete à instituição PUC-MG aqui <<<   *
        //         } else if(instituicao = 'UNIFEOB') {                            *
        //             >> insira o script referete à instituição UNIFEOB aqui <<<  *
        //         } else {                                                        *
        //             >> insira o script padrão, a ser executado caso nenhum      *
        //                 dos requisitos anteriores sejam atendidos <<            *
        //         }                                                               *
        //                                                                         *
        // *************************************************************************
        // ****** INICIO CUSTOMIZAÇÃO - NÃO INSIRA NADA ANTES DESTA LINHA **********
        // ************************************************************************* 
        
        
        
        // <INSIRA O SCRIPT CUSTOMIZADO DO NEOASSIST AQUI>
        

        
        // *************************************************************************
        // ****** FIM CUSTOMIZAÇÃO - NÃO INSIRA NADA APÓS ESTA LINHA ********
        // *************************************************************************
        
        // Executa a função de callback após 5 segundos (5000ms), para dar tempo do script NeoAssist ser executado.
        if(typeof callback === 'function') setTimeout(callback, 5000); 
    }
};
