(function() {
angular.module('ocorrencias')
  .component('ocorrenciasList', {
    templateUrl: 'js/custom-components/ocorrencias-list/ocorrencias-list.component.html',
    bindings: {
      onOpenOcorrenciaDetail: "&"
    },
    controller: 'OcorrenciasListController',
    controllerAs: 'ctrlOcorrenciasList'
  });
})();