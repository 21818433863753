angular.module('MyApp')

/*
*   Environment enum to set if is prod or dev
*/
.constant('EnvironmentEnum', {
    DEV: 'DEV',
    PROD: 'PROD'
})

/*
*   Type of value in card
*/
.constant('TypeEnum', {
    DATE: 'DATE',
    STRING: 'STRING',
    CURRENCY: 'CURRENCY',
    CARD: 'CARD'
});