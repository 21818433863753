(function() {
    angular.module('wizard')
    .controller('WizardButtonComponentController', ['$log', '$scope','$rootScope', function($log, $scope, $rootScope) {
        let vm = this;

        vm.buttonsClass = function() {
            if(vm.getWindowWidth() >= 460) {
                if(vm.fixButton)
                    return 'wizard-buttons-all-fixed';
                return 'wizard-buttons-all';
            } else {
                if(vm.fixButton)
                    return 'wizard-buttons-phone-fixed';
                return 'wizard-buttons-phone';
            }
        }

        vm.cancelClass = function() {
            return 'button '+ vm.cancelButton.style +' button-break-line w-28';
        }
        vm.nextClass = function(firstStep) {
            if(vm.cancelButton && vm.cancelButton.enabled && !firstStep)
                return 'button '+ vm.nextButton.style +' button-break-line w-62 ';
            return 'button '+ vm.nextButton.style +' button-break-line w-90';
        }
        vm.finalClass = function() {
            if(vm.cancelButton && vm.cancelButton.enabled)
                return 'button '+ vm.finalButton.style +' button-break-line w-62';
            return 'button '+ vm.finalButton.style +' button-break-line w-90 ';
        }
        
        $scope.onPaymentClick = function(){
          $rootScope.$broadcast('onPaymentClickBroadcast');
        }
        
        vm.getWindowWidth = function() {
            return window.innerWidth;
        }
	}]);
})();
