angular
    .module('MyApp')
    .service('AvisoService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {
      var _aluno = SessionData.aluno;
      var _msgErro = { msgErro: 'Error student undefined' };
     

      this.listarAvisos = function() {
        let defer = $q.defer();
        var _objAluno = {
          codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
          codPessoa: SessionData.aluno.list[_aluno.selecionado].id
        };
        if (_objAluno) {
            $resource(Configs.getApiServer() + '/pessoas/:codPessoa/alunos/:codAluno/avisos', {}, {
                cache: false,
                query: {
                    method: 'GET',
                    isArray: true
                }
            })
            .query(_objAluno, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
        } else {
            defer.reject({ _msgErro });
        }

        return defer.promise;
      }
      
      this.obterDadosAnexo = function(codAviso) {
          let defer = $q.defer();
          
          if (codAviso) {
              $resource(Configs.getApiServer() + '/pessoas/cod_aviso/:codAviso/lista_anexo_aviso', {}, {
                cache: false,
                query: {
                    method: 'GET',
                    isArray: true
                }
            })
            .query({"codAviso": codAviso},
               function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;
      }
      
      this.listarUltAtividades = function() {
          let defer = $q.defer();
          var _objAluno = {
        		  codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
                  codPessoa: SessionData.aluno.list[_aluno.selecionado].id
             };
          if (_objAluno) {
              $resource(Configs.getApiServer() + '/pessoas/cod_pessoa/:codPessoa/cod_aluno/:codAluno/lista_ultimas_atividades', {}, {
                cache: false,
                query: {
                    method: 'GET',
                    isArray: true
                }
            })
            .query(_objAluno,
               function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;
      }
      
      this.baixarAnexo = function(dadosAnexo) {
          let defer = $q.defer();
          
          let params = {
                "codAviso": dadosAnexo.codAviso,
                "codAnexo": dadosAnexo.codAnexo
            };
          
          if (dadosAnexo) {
              $resource(Configs.getApiServer() + '/pessoas/cod_aviso/:codAviso/cod_anexo/:codAnexo/baixar_anexo_aviso', {}, {
                  cache: false,
                  query: {
                      method: 'GET'
                  } 
                })
                .get(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;
          
      }
      
      
    }]);