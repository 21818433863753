angular
    .module('MyApp')
    .service('RelatorioService', ["Configs", "SessionData", "DownloadArquivo", "$resource", "$q", "$http", "$ionicLoading", function (Configs, SessionData, DownloadArquivo, $resource, $q, $http, $ionicLoading) {
	    
    	this.downloadReportService = function(pagina, aluno){
			  let params = {};
        $ionicLoading.show({
          content : 'Loading',
          animation : 'fade-in',
          showBackdrop : true,
          maxWidth : 200,
          showDelay : 0
        });
			
        params.url = Configs.getApiServer() + '/relatorios/report-service/?pagina=' + pagina + "&codAluno=" + aluno;
        params.method = "GET";

        DownloadArquivo.downloadArquivo(params);
    	}
	   
    	this.downloadReportServiceEP = function(data){
    		let params = {};
    		$ionicLoading.show({
          content : 'Loading',
          animation : 'fade-in',
          showBackdrop : true,
          maxWidth : 200,
          showDelay : 0
        });
        
        params.url = Configs.getApiServer() + '/relatorios/report-service/';
        params.method = "POST";
        params.data = data;

        DownloadArquivo.downloadArquivo(params);
    	}
    	
    	this.verificaReportServiceEntry = function(pagina, aluno) {
        let defer  =  $q.defer();
    		let params = {
                	"pagina": pagina,
                	'codAluno': aluno
                 };
    		
    		$http({
    			  method: 'GET',
    			  url: Configs.getApiServer() + '/relatorios/report-service-entrypoint/',
    			  params: params
    		})
    	    .success(function(retorno) {
    	    	defer.resolve(retorno);
    	    })
    	    .error(function(erro) {
    	    	defer.reject(erro);
    	    });
    		return defer.promise;
     }
}]);
    
