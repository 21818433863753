angular.
module('MyApp')
    .service('AcordoService', ['$resource', '$q', 'SessionData', 'Storage', 'Configs', 'DownloadArquivo', 'FileSaver', function($resource, $q, SessionData, Storage, Configs, DownloadArquivo, FileSaver) {
        var _msgErro = {msgErro: 'Error student undefined'};
    	var aluno;
    	function dadosValidosAluno(){
    		if(SessionData.aluno){
    			aluno = {codPessoa: SessionData.user.id, codAluno: SessionData.aluno.list[SessionData.aluno.selecionado].codAluno};
    			return true;
    		}
    		return false;
    	}
    	
        base64ToArrayBuffer = function (data) {
        	var binaryString =  window.atob(data);
        	var binaryLen = binaryString.length;
        	var bytes = new Uint8Array(binaryLen);
                
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            
            return bytes;
        };          

        this.opcoesAcordoParaAluno = function() {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoa/codAluno/:codAluno/obterOpcoesAcordo', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterCobrancasAbertas = function() {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/codPessoa/:codPessoa/codAluno/:codAluno/obterCobrancasAbertas', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.verificarSePessoaPodeFazerAcordo = function() {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/codPessoa/:codPessoa/codAluno/:codAluno/verificarSePodeFazerAcordo', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterListaAcordos = function() {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoa/idAluno/:codAluno/obterListaAcordos', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterDetalhesAcordo = function(codAcordo) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
            	aluno.codAcordo = codAcordo;
                $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoa/codAluno/:codAluno/codAcordo/:codAcordo/obterDetalhesAcordo', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        delete aluno.codAcordo;
                        defer.resolve(result);
                    }, function(err) {
                        delete aluno.codAcordo;
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterDetalhesPreAcordo = function(codAcordo) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/codPreAcordo/:codAcordo/obterDadosPreAcordo', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query({
                        codAcordo: codAcordo
                    }, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterCobrancasDoAcordo = function(codAcordo) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
            	aluno.codAcordo = codAcordo;
                $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoa/codAcordo/:codAcordo/obterCobrancasDoAcordo', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        delete aluno.codAcordo;
                        defer.resolve(result);
                    }, function(err) {
                        delete aluno.codAcordo;
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterCobrancasDoPreAcordo = function(codAcordo) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/codPreAcordo/:codAcordo/listarCobrancasPreAcordadas', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query({
                        codAcordo: codAcordo
                    }, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterCobrancasGeradasPeloAcordo = function(codAcordo) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
            	aluno.codAcordo = codAcordo;
                $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoa/codAluno/:codAluno/codAcordo/:codAcordo/obterCobrancasGeradasPeloAcordo', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        delete aluno.codAcordo;
                        defer.resolve(result);
                    }, function(err) {
                        delete aluno.codAcordo;
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterCobrancasGeradasPeloPreAcordo = function(codAcordo) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/codPreAcordo/:codAcordo/listarCobrancasGeradasPreAcordo', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query({
                        codAcordo: codAcordo
                    }, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.dadoParaConfirmacao = function(dados) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/obterDetalhesAcordo', {}, {
                        cache: false,
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    })
                    .save(dados, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterDadosPagarComCartao = function(dados) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                aluno.cobranca = dados.codCobranca;
                $resource(Configs.getApiServer() + '/financeiro/cobranca/:cobranca/cod_aluno/:codAluno/obtem_dados_pagar_cobranca_com_cartao', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(aluno, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.salvarAceite = function(dados) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/aceito', {}, {
                        cache: false,
                        save: {
                            method: 'POST'
                        }
                    })
                    .save(dados, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.salvarAceitoSemContrato = function(dados) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/aceitoSemContrato', {}, {
                        cache: false,
                        save: {
                            method: 'POST'
                        }
                    })
                    .save(dados, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.listaPlanosDePagamentoAcordo = function(dados) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/listarPlanosDePagamentoAcordo', {}, {
                        cache: false,
                        save: {
                            method: 'POST',
                            isArray: true
                        }
                    })
                    .save(dados, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.getHtmlDoAcordo = function(dados) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/gerarHtmlDoAcordo', {}, {
                        cache: false,
                        save: {
                            method: 'POST',
                            isArray: false
                        }
                    })
                    .save(dados, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }
        
        // It is not returning an Array but an Object, created another service called getHtmlDoAcordo expecting an object as response
        this.gerarHtmlDoAcordo = function(dados) {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/financeiro/gerarHtmlDoAcordo', {}, {
                        cache: false,
                        save: {
                            method: 'POST',
                            isArray: false
                        }
                    })
                    .save(dados, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err.data);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.downloadBoleto = function(codBoleto = 0) {
        	if (dadosValidosAluno()) {
	        	let params = {};
				params.url = Configs.getApiServer() + '/financeiro/codPessoaLogada/' + aluno.codPessoa + '/codAluno/' + aluno.codAluno + '/codBoleto/' + codBoleto + '/exibirArquivo/false/obterBoleto'; 
				params.method = "GET";
				
				DownloadArquivo.downloadArquivo(params);
        	}
        }

        this.imprimirContrato = function(acordoOnLineDados) {
        	// Download contrato em PDF
            var fileName = "contrato.pdf";
            var arquivo = new Blob([base64ToArrayBuffer(Storage.get("contratoPDF"))], { type: 'application/pdf' });

            FileSaver.saveAs(arquivo, fileName);
            Storage.delete("contratoPDF");
        }

        this.imprimirContratoConsulta = function(codAcordo) {
        	let params = {};
			params.url = Configs.getApiServer() + '/financeiro/codAcordo/'+ codAcordo +'/obterImagemDocumentoAcordo'; 
			params.method = "GET";
			
			DownloadArquivo.downloadArquivo(params);            
        }

        this.gerarRelatorioAcordo = function(codAcordo) {
            let defer = $q.defer();
            dadosValidosAluno();
            let params = {
                    "codPessoaLogada": aluno.codPessoa,
                    "codAluno": aluno.codAluno,
                    "codAcordo": codAcordo,
                    "exibirArquivo": true
                };
            $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoaLogada/codAluno/:codAluno/codAcordo/:codAcordo/exibirArquivo/:exibirArquivo/geraRelatorioAcordo', {}, {
                    cache: false,
                        get: {
                            responseType: 'arraybuffer',
                            transformResponse: function(data) {
                                if (status == 500) {
                                    defer.reject();
                                    var decoder = new TextDecoder("utf-8");
                                    var domString = decoder.decode(data);
                                    var json = JSON.parse(domString);
                                    return json
                                }

                                let pdf;
                                if (data) {
                                    pdf = new Blob([data], {
                                        type: 'application/pdf'
                                    });
                                }
                                return {
                                    pdf: pdf
                                };
                            }
                        } 
                })
                .get(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err.data);
                });
            return defer.promise;
        }
    }]);