/**
 * Controller do componente Cobrança List (cobranca-list)
 */
(function(){
	angular.module('cobrancaListModule')
	  .controller('CobrancaListComponentController', ['$scope', '$log', 'Notification', '$ionicModal', '$http', 'SessionData', '$ionicLoading', 'Financeiro.Cobranca', 'CobrancaService', CobrancaListComponentController]);
	
	function CobrancaListComponentController($scope, $log, Notification, $ionicModal, $http, SessionData, $ionicLoading, Financeiro_Cobranca, Cobranca_Service){
		var ctrl = this;
		
		$log.debug('[CobrancaListComponentController]:ctrl=', ctrl);

		function showLoading(){
			$ionicLoading.show({
				content: 'Loading',
				animation: 'fade-in',
				showBackdrop: true,
				maxWidth: 200,
				showDelay: 0
			});
		} 
		showLoading();
		
		ctrl.sessionData = SessionData;
		
		var alunos = ctrl.sessionData.aluno;
	    ctrl.aluno = alunos.list[alunos.selecionado];
	    
	    ctrl.getWindowWidth = getWindowWidth;
        
		ctrl.cobrancas = [];
		ctrl.itensCobrancaDetalhe = [];
						
		// Chama o serviço REST
		Financeiro_Cobranca.query({codAluno: encodeURIComponent(ctrl.aluno.codAluno), codPessoa: ctrl.aluno.id}, handleSuccess, handleFailure);
		
	    // Tratamento de Sucesso da chamada ao serviço REST
	    function handleSuccess(result){
	    	ctrl.cobrancas = result.content;
	    	$log.debug('CobrancaListComponentController.Aluno_Cobranca.handleSuccess():\tcobrancas=', ctrl.cobrancas);
	    	$ionicLoading.hide();
	    }
	    
	    // Tratamento de Erro da Chamada ao serviço REST
	    function handleFailure(reason){
	    	$ionicLoading.hide();
	    	$log.debug('[CobrancaListComponentController.Aluno_Cobranca.handleFailure]\treason=', reason);
	    	
	        var keys = Object.keys(reason);
	        var str = '';
	        for (var k in keys) {
	          str += reason[k] + '\n';
	        }
	        
	        $log.debug('[CobrancaListComponentController.Aluno_Cobranca.handleFailure]\treason=' + str);
	        Notification.error('[CobrancaListComponentController.Aluno_Cobranca.handleFailure]\treason=' + str);
	    }
	    
	    $ionicModal.fromTemplateUrl('./js/custom-components/cobranca-list/modal-cobranca-detalhe.html', {
	        scope: $scope,
	        animation: 'slide-in-up'
	    }).then(function(modal) {
	        $scope.modal = modal;
	    });
	    
	    $scope.detalharCobranca = function(cobranca){
			showLoading();
			ctrl.cobranca = angular.copy(cobranca);
	        Cobranca_Service.listarItensCobranca(cobranca.codigoCobranca).then(function handleSuccess(result) {
				
				$ionicLoading.hide();
				ctrl.itensCobrancaDetalhe = result;
				$scope.lancamentos = ctrl.itensCobrancaDetalhe.itensLancamento
				$scope.pagamentos = ctrl.itensCobrancaDetalhe.itensPagamento
				$scope.modal.show()

	        }).catch(function(err) {
              $ionicLoading.hide();
          });
	    }
	    
	    function getWindowWidth(){
	    	return window.innerWidth;
	    }
	}
})();
