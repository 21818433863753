angular
    .module('historicoListModule')
    .controller('HistoricoListController', [
        '$scope',
        '$state',
        '$log',
        'TypeEnum',
        function($scope, $state, $log, TypeEnum) {
            var historicoCtrl = this;
            historicoCtrl.id = 'HistoricoListController';
            
            historicoCtrl.getWindowWidth = function() {
                return window.innerWidth;
            }
        }]);