/**
 * Componente Cobranca List (cobranca-list)
 */
(function(){
	angular.module('cobrancaListModule').component('cobrancaList', {
		templateUrl: 'js/custom-components/cobranca-list/cobranca-list.component.html',
		controller: 'CobrancaListComponentController',
		controllerAs: 'ctrl',
		bindings: {
			
		}
	});
})();