(function () {
  /*
   * Modulo do componenet avisos-list (avisosList)
   */
  angular
    .module('horarios', [])
    .controller('HorariosListController', [
      '$translate', '$log',
      HorariosListController]);

  function HorariosListController($translate, $log) {
      var vm = this;

      $log.debug('[HorariosListController]\tvm=', vm);

      vm.showList = function() {
        var ret = false;
        ret = vm.tableData.haveData();
        return ret;
      };
      
      //
      vm.listHorarioAula = function(diaSemana){
        $log.debug('Listando horários de aulas (diaSemana: " + diaSemana + ")...');
    	  var ret = false;
    	  ret = vm.tableData.listHorarios(diaSemana);
    	  return ret;
      };
      
      vm.getSemestreLabel = function(semestre) {
        var ord = '';
        switch (semestre) {
          case '1': ord = 'st';
            break;
          case '2': ord = 'nd';
            break;
          case '3': ord = 'rd';
            break;
          default: ord = 'th';
        }
        var str = $translate.instant('Aluno.HorarioAulas.Semestre', { 'semestre' : semestre, 'ord' : ord});
        return str;
      }
   }
})();
