(function() {
angular.module('tarefa')
  .component('tarefaListItem', {
    templateUrl: 'js/custom-components/tarefa-list-item/tarefa-list-item.component.html',
    bindings: {
      tarefa: "="
    },
    controller: 'TarefaListItemComponentController',
    controllerAs: 'ctrl'
  });

})();
