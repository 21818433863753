(function() {
angular.module('tarefa')
  .component('tarefaList', {
    templateUrl: 'js/custom-components/tarefa-list/tarefa-list.component.html',
    bindings: {
      tarefas: "="
    },
    controller: 'TarefaListComponentController',
    controllerAs: 'ctrl'
  });

})();
