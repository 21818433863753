(function() {
    angular.module('wizard')
    .controller('WizardComponentController', ['$log', '$scope', '$ionicScrollDelegate', '$state', '$timeout', function($log, $scope, $ionicScrollDelegate, $state, $timeout) {
        let vm = this;

        vm.currentStep = 0;
        vm.maxSteps = vm.steps.length-1;

        $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            var step = vm.steps.find(function(value) {
                return value.state == toState.name;
            })

            if(step) vm.currentStep = vm.steps.indexOf(step);
            else vm.currentStep = 0;
        });

        function adjustScroll() {
            let instances = $ionicScrollDelegate._instances;
            
            let scrollInstance = instances.filter(function(inst) {
                if(inst.$element[0].id == 'wizardScrollContent')
                    return inst;
            });
            
            if(scrollInstance.length > 0) {
                scrollInstance[0].resize();
                scrollInstance[0].scrollTop();
            }
        }

        vm.onButtonCancelClick = function(){
            if(vm.cancelButton.onClick) {
            	try{         	
	                vm.cancelButton.onClick().then(function(){
	                    vm.currentStep--;
	                    vm.additionalButton = {};
	                    $state.go(vm.steps[vm.currentStep].state);
	                    adjustScroll();
	                });
                }catch(e){
                	
                }
            } else {
                vm.currentStep--;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
                adjustScroll();
            }
        }
        vm.onButtonNextClick = function(){
            if(vm.nextButton.onClick){
            	vm.nextButton.onClick().then(function(){
                    vm.currentStep++;
                    vm.additionalButton = {};
                    $state.go(vm.steps[vm.currentStep].state);
                    adjustScroll();

                });
            } else {
                vm.currentStep++;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
                adjustScroll();
            }
        }
        vm.onButtonFinalClick = function(){
            if(vm.finalButton.onClick) {
                vm.finalButton.onClick().then(function(){
                    vm.currentStep = 0;
                    vm.additionalButton = {};
                    $state.go(vm.steps[vm.currentStep].state);
                });
            } else {
                vm.currentStep = 0;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
            }
        }
        
        vm.onButtonPaymentClick = function(){
            if(vm.paymentButton.onClick) {
                vm.paymentButton.onClick().then(function(){
                    vm.currentStep = 0;
                    vm.additionalButton = {};
                    $state.go(vm.steps[vm.currentStep].state);
                });
            } else {
                vm.currentStep = 0;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
            }
        }

        if(vm.steps[vm.currentStep])
            $state.go(vm.steps[vm.currentStep].state);

        vm.contentStyle = function() {
            let style = vm.style;

            if(vm.fixButton)
                style += ' margin-bottom: 60px; ';

            return style;
        }

        vm.parentScope = $scope.$parent;
        vm.parentScope.adjustWizardScroll = adjustScroll;
	}]);
})();
