angular
    .module('MyApp')
    .service('FinanceiroService', ['$resource', 'DownloadArquivo', '$ionicLoading', '$q', 'SessionData', 'Configs', 
    	function($resource, DownloadArquivo, $ionicLoading, $q, SessionData, Configs) {
      var _aluno = SessionData.aluno;
      var _msgErro = { msgErro: 'Error student undefined' };
           
      this.listarNotasFiscaisEletronicas = function listarNotasFiscaisEletronicas() {
          let defer = $q.defer();
          
          var _objAluno = {
              codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
              codPessoa: SessionData.aluno.list[_aluno.selecionado].id
          };
          
          if (_objAluno) {
              $resource(Configs.getApiServerCustom() + '/financeiro/codPessoa/:codPessoa/codAluno/:codAluno/listar-notas-fiscais-eletronicas', {}, {
                  cache: false,                  
                  query: {
                      method: 'GET',
                      isArray: true,
                      headers: { 'Authorization': 'Basic ' + btoa('apiuser:apiuser') }
                  }
                  
                })
                .query(_objAluno, function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;          
      }

}]);