angular
		.module('MyApp')
		.service(
				'UtilService',
				[
						'$resource',
						'$q',
						'SessionData',
						'Configs',
						function($resource, $q, SessionData, Configs) {

							this.obterEndereco = function(cep) {
								let
								defer = $q.defer();

								let
								cepConsulta = {"cep": cep};

								$resource(
										Configs.getApiServer()
												+ '/pessoas/cep/:cep/obterEndereco',
										{}, {
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(cepConsulta, function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}
							
							this.dateToString = function(data) {
								
								if (data !== null && data instanceof Date && data !== undefined && data !== '') {
									var dataFormatada = data.getDate() + '/' + (data.getMonth() + 1) + '/' + data.getFullYear();
								}

							  	return dataFormatada;
							}
							
							this.obterUF = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/listarUfs',
										{}, {
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}

							this.obterNecessidadeEspecial = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/listarNecessidadesEspeciais',
										{}, {
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}

							this.obterOrgaoEmissor = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/listarOrgaosEmissores',
										{}, {
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}

							this.obterEstadoCivil = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/obterEstadosCivis',
										{}, {
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}

							this.obterProfissao = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/listarProfissoes',
										{}, {
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}

							this.obterCredo = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/listarCredos', {},
										{
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}

							this.obterNacionalidade = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/listarNacionalidades',
										{}, {
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}

							this.obterPais = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/listarPaises', {},
										{
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}
							
							
							this.obterMunicipios = function(municipio) {
								let defer = $q.defer();
								
								$resource(
										Configs.getApiServer() + '/pessoas/lista_municipios',
										{},
										{
											cache: false,
											query : {
												method : 'POST',
												isArray : true
											}
										}
								).query(municipio,
										function(response) {
											defer.resolve(response);
										},
										function(err) {
											defer.reject(err);
										}
								);
								
								return defer.promise;
							}							
							
							
							this.obterEstadosEstrangeiros = function(codigoPais) {
								let defer = $q.defer();
								
								if(codigoPais){
									let codigo = {"cod_pais": codigoPais};
									
									$resource(
											Configs.getApiServer() + '/pessoas/cod_pais/:cod_pais/lista_estados_estrangeiros',
											{},
											{
												cache : false,
												query : {
													method : 'GET',
													isArray: true
												}
											}
									).query(codigo, 
											function(response){
												defer.resolve(response);
											},
											function(err) {
												defer.reject(err);
											}
									);
								} else {
									defer.resolve([]);
								}

								return defer.promise;
							}
							
							this.obterMunicipiosEstrangeiros = function(municipio) {
								let defer = $q.defer();
								
								$resource(
											Configs.getApiServer() + '/pessoas/lista_municipios_estrangeiros', 
											{},
											{
												cache : false,
												query : {
													method : 'POST',
													isArray: true
												}
											}
								).query(municipio,
										function(response) {
											defer.resolve(response);	
										}, 
										function(err) {
											defer.reject(err);
										}
								);
								
								return defer.promise;
							}
							
							
							this.obterBairros = function(codigoMunicipio) {
								let defer = $q.defer();
								
								let codigo = {"cod_municipio": codigoMunicipio};
								
								$resource(
										Configs.getApiServer() + '/pessoas/cod_municipio/:cod_municipio/lista_bairros',
										{},
										{
											cache : false,
											query : {
												method : 'GET',
												isArray: true
											}
										}
								).query(codigo, 
										function(response){
											defer.resolve(response);
										},
										function(err) {
											defer.reject(err);
										}
								);
								
								return defer.promise;
							}
							

							this.obterCores = function() {
								let
								defer = $q.defer();

								$resource(
										Configs.getApiServer()
												+ '/pessoas/listarCores', {}, {
											cache : false,
											query : {
												method : 'GET'
											}
										}).query(function(result) {
									defer.resolve(result);
								}, function(err) {
									defer.reject(err);
								});
								return defer.promise;
							}
						}]);