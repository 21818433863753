(function() {
angular.module('pgtocobrancas')
  .component('pagtocobrancasComponent', {
    templateUrl: 'js/custom-components/pgtocobrancas/pgtocobrancas.component.html',
    bindings: {
        titleCard: "=",
        columns: "=",
        itens: "=",
        enableCheckbox: "=",
        cardItemStyle: "=",
        filterSearch: "=",
        forceCard: "=",
        cardHint: "=",
        hideTitleBar: "=",
        onSelect: "<",
        messageNull: "=",
        insideFilter: "="
    },
    controller: 'PgtoCobrancasComponentController',
    controllerAs: 'vm'
  });

})();
