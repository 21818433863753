(function () {
  /*
   * Modulo do componenet avisos-list (avisosList)
   */
  angular
    .module('menu')
    .controller('MenuRightController', [
      '$log',
      '$ionicSideMenuDelegate',
      'uiCalendarConfig',
      MenuRightController]);

  function MenuRightController($log, $ionicSideMenuDelegate, uiCalendarConfig) {
      var ctrl = this;

      $log.debug('[MenuRightController]\tctrl=', ctrl);

      ctrl.closeMenuRight = function(isOpen) {
        $ionicSideMenuDelegate.toggleRight(isOpen);
      };
      
      xUiCalendarConfig = uiCalendarConfig;
      
      ctrl.dayClick = function( date, jsEvent, view ) {
        $log.debug('[dayClick]\tuiCalendarConfig=', uiCalendarConfig);
        $log.debug('[dayClick]\tdate=', date, 'jsEvent=', jsEvent, 'view=', view);
        let OPEN = false;
        ctrl.closeMenuRight(OPEN);
        ctrl.agenda.selectedDate = date;
        uiCalendarConfig.calendars['agendaCalendar'].fullCalendar('select', date);
        
        $log.debug('[dayClick]\tuiCalendarConfig.calendars[calendar]=', uiCalendarConfig.calendars['agendaCalendar']);
        $log.debug('[ dayClick]\tagenda.selectedDate=', ctrl.agenda.selectedDate.format());
        $(".fc-state-highlight").removeClass("fc-state-highlight");
        $(jsEvent.target).addClass("fc-state-highlight");
      };

      ctrl.alertEventOnClick = function() {
        $log.debug('[alertEventOnClick]');
        let OPEN = true;
        ctrl.closeMenuRight(OPEN);
      };
      ctrl.alertOnDrop = function() {};
      ctrl.alertOnResize = function() {};

      ctrl.uiConfig = {
        'calendar' : {
          editable: true,
          height: 'auto',
          header:{
            left: 'month,agendaWeek,agendaDay',
            center: 'title',
            right: 'prev,today,next'
          },
          theme: true,
          lang: 'pt-br',

          dayClick: ctrl.dayClick,
          eventClick: ctrl.alertEventOnClick,
          eventDrop: ctrl.alertOnDrop,
          eventResize: ctrl.alertOnResize
        }
      };

      var date = new Date();
      
      $log.debug('[showCalendarButton]\tctrl.agenda=', ctrl.agenda);
      
      var d = date.getDate();
      var m = date.getMonth();
      var y = date.getFullYear();

      ctrl.eventSources = [
        {
          "events" : [
            {title: 'All Day Event',start: new Date(y, m, 1)},
            {title: 'Long Event',start: new Date(y, m, d - 5),end: new Date(y, m, d - 2)},
            {id: 999,title: 'Repeating Event',start: new Date(y, m, d - 3, 16, 0),allDay: false},
            {id: 999,title: 'Repeating Event',start: new Date(y, m, d + 4, 16, 0),allDay: false},
            {title: 'Birthday Party',start: new Date(y, m, d + 1, 19, 0),end: new Date(y, m, d + 1, 22, 30),allDay: false},
            {title: 'Click for Google',start: new Date(y, m, 28),end: new Date(y, m, 29),url: 'https://google.com/'}
          ]
        }
      ];

   }
})();
