(function() {
  /*
   * Modulo do componente aluno-card (alunoCard)
   */
  angular.module('alunoCard', ['aol.service'])
    .controller('AlunoCardController',
     ['$log', 'SessionData', '$scope', 'serviceAlunos',
        AlunoCardController]);

  function AlunoCardController($log, SessionData, $scope, serviceAlunos) {
    var self = this;

    self.alunoSelecionado = SessionData.aluno !== null && SessionData.aluno.list !== undefined ? SessionData.aluno.list[0] : null;
    //self.turmaSelecionada = self.alunoSelecionado.alunoTurmas[self.alunoSelecionado.turmaSelecionada];
    
    self.sessionData = SessionData;
    
    $log.debug('[AlunoCardController]\tself=', self);
    
    self.loadFoto = loadFoto;
    
    
    // Function para carregar a foto do aluno
    function loadFoto(){
    	serviceAlunos.loadFotoAluno(self.alunoSelecionado);
    }
    
    
    // Chama function para Carregar a foto
    self.loadFoto();
  }
  
})();