/**
 * Controller do component Minha Turma (minhaTurma)
 */
(function() {
	angular.module('minhaTurmaListModule').controller('MinhaTurmaListController', ['$log', 'Aluno.Minha.Turma', 'SessionData', 'Notification', '$ionicLoading', MinhaTurmaListController]);
	
	function MinhaTurmaListController ($log, Aluno_Minha_Turma, SessionData, Notification, $ionicLoading) {
		var self = this;
		
	    $log.debug('[MinhaTurmaListController]:self=', self);
	    
	    var alunos = SessionData.aluno;
	    self.aluno = alunos.list[alunos.selecionado];
		
		// Lista dos Alunos que pertencem a mesma turma.
		self.alunos = [];
	    
		$ionicLoading.show({
    		content : 'Loading',
    		animation : 'fade-in',
    		showBackdrop : true,
    		maxWidth : 200,
    		showDelay : 1
    	});
		
	    // Chama o serviço REST
		Aluno_Minha_Turma.query({codAluno: encodeURIComponent(self.aluno.codAluno)}, handleSuccess, handleFailure);
	    
	    // Tratamento de Sucesso da chamada ao serviço REST
	    function handleSuccess(result){
	    	self.alunos = result.content;
	    	$log.debug('MinhaTurmaListController.handleSuccess():\tminhaTurma=', self.alunos);
	    	$ionicLoading.hide();
	    }
	    
	    // Tratamento de Erro da Chamada ao serviço REST
	    function handleFailure(reason){
	    	$log.debug('[MinhaTurmaListController.handleFailure]\treason=', reason);
	    	
	        var keys = Object.keys(reason);
	        var str = '';
	        for (var k in keys) {
	          str += reason[k] + '\n';
	        }
	        
	        $log.debug('[[MinhaTurmaListController.handleFailure]\treason=' + str);
	        Notification.error('[MinhaTurmaListController.handleFailure]\treason=' + str);
	        
	    	$ionicLoading.show({
	    		template: str,
	    		duration: 2500
	    	});
	    }
	}
})();