(function($app) {
angular.module('custom.services', ['ngStorage'])

/**
 * ------------------
 *  Storage / Session
 * ------------------
 *
 * Abstração para acesso à um serviço de 'storage'.
 * Deve ser utilizado para expandir o conceito, sem
 * no entanto, impactar o restante do código.
 */

.factory( 'Storage', [ '$rootScope', '$sessionStorage', '$localStorage',
  function($rootScope, $sessionStorage, $localStorage){

    let service = {
      storage: $sessionStorage // default value
    };

    /**
     * Remove item do storage
     *
     * @returns valor do item removido
     */
    service.delete = function (name) {
      let stored = this.get(name);
      delete this.storage[name];
      return stored;
    };

    /**
     * Limpa o storage
     */
    service.clear = function (name) {
      return this.storage.$reset();
    };

    /**
     * @returns item do storage
     */
    service.get = function (name) {
      return this.storage[name];
    };

    /**
     * Atribui 'value' a chave do storage 'name'
     * @returns valor atribuido
     */
    service.set = function (name, value) {
      this.storage[name] = value;
      return this.get(name);
    };

    /**
     * Altera o serviço de storage sendo utilizado
     */
    service.use = function (storage) {
      if (storage === 'sessionStorage') {
        this.storage = $sessionStorage;
      }
      else if (storage === 'localStorage') {
        this.storage = $localStorage;
      }
    };

    return service;
  }
])

/**
 * ----------------------------
 *  HttpPendingRequestService
 * ----------------------------
 *
 * Guarda array de referências à promise dos requests do $state
 * corrente e permite o cancelamento de requests pendentes, por
 * exemplo, quando um erro ocorre e não se deseja continuar com
 * as demais operações.
 */

.factory('HttpPendingRequestService', function ($q) {
  let service = {};
  var cancelPromises = [];

  service.createTimeout = function() {
    var cancelPromise = $q.defer();
    cancelPromises.push(cancelPromise);
    return cancelPromise.promise;
  }

  service.cancelAll = function() {
    angular.forEach(cancelPromises, function (cancelPromise) {
      cancelPromise.resolve();
    });
    cancelPromises.length = 0;
  }

  return service;
})

/**
 * ------------------
 *  LogoutService
 * ------------------
 *
 * Limpa informações de usuário e realiza redirecionamento adequado.
 */

.factory( 'LogoutService', [ '$log', '$state', '$location', '$http', 'SessionData', 'serviceAlunos', 'Storage',
  function($log, $state, $location, $http, SessionData, serviceAlunos, Storage){

    let service = {};

    service.logout = function() {
      $http({
        method : 'POST'   ,
        url    : 'logout' ,
      })
      .then(function(){

        if (!SessionData.isEmpty('aluno')) {
          let codPessoa = SessionData.aluno.list[0].user.id;
          let codAluno = SessionData.aluno.list[0].codAluno;

          serviceAlunos.redirectUrlRetorno(codPessoa, codAluno).then(
            function onSuccess(response){
              SessionData.clear();
              Storage.clear();
              if (response.conteudo !== null && response.conteudo !== undefined){
                $(location).attr('href', response.conteudo);
              } else {
                $state.go('login', {}, {reload: true});
              }

          }, function onFailure(response){
            SessionData.clear();
            Storage.clear();
            $state.go('login', {}, {reload: true});
          });
        }
        else { // Erro no login
          SessionData.clear();
          Storage.clear();
        }
      })
      .catch(function(err){
        $log.error('Logout error:', err);
      })
    }

    return service;
  }
])

}(app));
