angular.module('MyApp')
    .service('TrabalhosService', ["Configs", "$resource", "$q" , "$http", "DownloadArquivo", "Notification", function (Configs, $resource, $q, $http, DownloadArquivo, Notification) {

        let methodGetObject= { cache: false, query: { method: 'GET', isArray: false } }
        let methodGetArray = { cache: false, query: { method: 'GET', isArray: true } }
        
        this.obterTrabalhos = function(aluno = {}) {
           
            let deferr = $q.defer();

            let objQuery   = {
                codAluno: aluno.codAluno,
            };
           
            $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/obter-trabalhos', {}, methodGetArray)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.obterHistoricoTrabalhos = function(aluno = {}) {
           
            let deferr = $q.defer();

            let objQuery   = {
                codAluno: aluno.codAluno,
            };
           
            $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/obter-historico-trabalhos', {}, methodGetArray)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });
            

            return deferr.promise;
        }

        this.uploadArquivo = function (file, base64Data){

            let deferr = $q.defer();
            
            var req = {
                method: 'POST',
                url: Configs.getApiServer() + '/matricula/enviar-trabalho/',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: {
                    contentType: file.type,
                    fileData:base64Data,
                    fileName:file.name,
                    codArquivoAnterior: null
                }
            }

            $http(req).then(function (resp) {
                deferr.resolve(resp);
            }, function (resp) {
                deferr.reject();
                Notification.error('Erro ao carregar arquivo: ' + resp.status);
            });

            return deferr.promise;
        }
        

        this.incluirTrabalho = function (trabalho, codArquivo){

            let deferr = $q.defer();
            
            var req = {
                method: 'POST',
                url: Configs.getApiServer() + '/matricula/incluir-trabalho/',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: {
                    codAluno : trabalho.aluno,
                    codDisciplina: trabalho.disciplina,
                    codTurma: trabalho.turma,
                    ano: trabalho.ano,
                    periodo: trabalho.semestre,
                    codProva: trabalho.prova,
                    titulo: "",
                    descricao: "",
                    codArquivo: codArquivo
                }
            }

            $http(req).then(function (resp) {
                deferr.resolve(resp);
            }, function (resp) {
                deferr.reject();
            });

            return deferr.promise;
        }

        this.downloadTrabalhoSubmissao = function (trabalho) {
           
            let params = {};

            params.data = {
                codAluno : trabalho.aluno,
                codDisciplina: trabalho.disciplina,
                codTurma: trabalho.turma,
                ano: trabalho.ano,
                periodo: trabalho.semestre,
                codProva: trabalho.prova
            }

            params.url = Configs.getApiServer() + '/matricula/download-trabalho'; 
            params.method = "POST";
            DownloadArquivo.downloadArquivo(params);
        }

        this.downloadTrabalhoHistorico = function (trabalho, aluno) {
           
            let params = {};

            params.data = {
                codAluno : aluno.codAluno,
                codDisciplina: trabalho.disciplina,
                codTurma: trabalho.turma,
                ano: trabalho.ano,
                periodo: trabalho.semestre,
                codProva: trabalho.prova
            }

            params.url = Configs.getApiServer() + '/matricula/download-historico-trabalho'; 
            params.method = "POST";
            DownloadArquivo.downloadArquivo(params);
        }

    }]);