var external_chart;
(function() {
  /*
   * Modulo do componente boletim-detail (boletimDetail)
   */
  var module = angular
    .module('boletimDetail', ['chart.js'])
    .controller('BoletimDetailController', [
      '$log',
      BoletimDetailController
      ])
  ;

  function BoletimDetailController($log) {
    var vm = this;

    $log.debug('[BoletimDetailController]\tvm=', vm);

    vm.chart_height = window.innerHeight*0.7;
    
    vm.toggleGroup = function(group) {
      if(vm.isGroupShown(group)) {
        vm.shownGroup = null;
      }
      else {
        vm.shownGroup = group;
      }
      $log.debug('vm=', vm);
      $log.debug('vm.shownGroup=', vm.shownGroup);
    };

    vm.isGroupShown = function(group) {
      $log.debug('vm.shownGroup === group? ', (vm.shownGroup === group));
      return vm.shownGroup === group;
    };

    $log.debug('BoletimDetailController] vm', vm);
  }

})();
