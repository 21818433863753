(function() {
angular.module('wizard')
  .component('wizardButton', {
    templateUrl: 'js/custom-components/wizard/buttons/wizardButton.component.html',
    bindings: {
        step: "=",
        maxSteps: "=",
        cancelButton: "=",
        nextButton: "=",
        finalButton: "=",
        paymentButton: "=",
        additionalButton: "=",
        fixButton: "=",
        onCancelClick: "<",
        onNextClick: "<",
        onFinalClick: "<",
        onPaymentClick: "<"
    },
    controller: 'WizardButtonComponentController',
    controllerAs: 'vm'
  });

})();
