(function() {
angular.module('ocorrenciaDetail')
.component('ocorrenciaDetail', {
  templateUrl: 'js/custom-components/ocorrencia-detail/ocorrencia-detail.component.html',
  bindings: {
    ocorrenciaItem: "="
  },
  controller: 'OcorrenciaDetailController',
  controllerAs: 'ctrl'
});

})();
