/**
 * Controller do componente Frequencia List (frequencia-list)
 */
(function(){
	angular.module('frequenciaListModule').controller('FrequenciaListComponentController', ['$scope', '$log', 'Notification', '$ionicModal', '$http', 'SessionData', '$ionicLoading', 'Aluno.Frequencia', FrequenciaListComponentController]);
	
	function FrequenciaListComponentController($scope, $log, Notification, $ionicModal, $http, SessionData, $ionicLoading, Aluno_Frequencia){
		var ctrl = this;
		
		$log.debug('[FrequenciaListComponentController]:ctrl=', ctrl);
		
		ctrl.sessionData = SessionData;
		
		var alunos = ctrl.sessionData.aluno;
	    ctrl.aluno = alunos.list[alunos.selecionado];
	    
	    ctrl.getWindowWidth = getWindowWidth;
        
		ctrl.faltas = [];
		ctrl.faltaTotal = 0;
		ctrl.frequenciaTotal = 0;
		ctrl.aulasPrevistas = 0;
		
		$ionicLoading.show({
    		content : 'Loading',
    		animation : 'fade-in',
    		showBackdrop : true,
    		maxWidth : 200,
    		showDelay : 1
    	});
		
		// Chama o serviço REST
		Aluno_Frequencia.query({codAluno: encodeURIComponent(ctrl.aluno.codAluno), codPessoa: ctrl.aluno.id}, handleSuccess, handleFailure);
		
	    // Tratamento de Sucesso da chamada ao serviço REST
	    function handleSuccess(result){
	    	ctrl.faltas = result.content;
	    	
	    	for(var i=0; i<ctrl.faltas.length; i++) {
	    		if(ctrl.faltas[i].obrigatoria === 'S'){
	    			ctrl.faltaTotal += ctrl.faltas[i].numeroFaltas;
	    			ctrl.aulasPrevistas += ctrl.faltas[i].aulasPrevistas;
	    		}
	    	}
	    	
	    	ctrl.frequenciaTotal = (((ctrl.aulasPrevistas - ctrl.faltaTotal) / ctrl.aulasPrevistas)*100).toPrecision(4);
	    	$log.debug('FrequenciaListComponentController.Aluno_Frequencia.handleSuccess():\tfaltas=', ctrl.faltas);
	    	$ionicLoading.hide();
	    }
	    
	    
	    // Tratamento de Erro da Chamada ao serviço REST
	    function handleFailure(reason){
	    	$ionicLoading.hide();
	    	$log.debug('[FrequenciaListComponentController.Aluno_Frequencia.handleFailure]\treason=', reason);
	    	
	        var keys = Object.keys(reason);
	        var str = '';
	        for (var k in keys) {
	          str += reason[k] + '\n';
	        }
	        
	        $log.debug('[FrequenciaListComponentController.Aluno_Frequencia.handleFailure]\treason=' + str);
	        Notification.error('[FrequenciaListComponentController.Aluno_Frequencia.handleFailure]\treason=' + str);
	    }
	    
	    function getWindowWidth(){
	    	return window.innerWidth;
	    }
	}
})();
