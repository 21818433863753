angular.module('MyApp')
    .service('DownloadArquivo', ['$log', "$resource", "$q", "$http", "$ionicLoading", "FileSaver", "$translate",
    function ($log, $resource, $q, $http, $ionicLoading, FileSaver, $translate) {

        function insertParam(key, value) {
            var url = window.location.href;

            if (url.indexOf('?') > -1) {
                url += '&' + encodeURI(key) + '=' + encodeURI(value);
            } else {
                url += '?' + encodeURI(key) + '=' + encodeURI(value);
            }

            window.location.href = url;
        }

    	this.downloadArquivo = function(params) {

            insertParam('url_download', params.url);
            
            $http({
                url: params.url,
                method: params.method,
                data: params.data,
                responseType: "arraybuffer"
            }).then(success, error);
            
            function success(response) {
                $ionicLoading.hide();
                
            	if (response.status === 200) {
                    var fileName =  /.*filename=(.*)/g.exec(response.headers('content-disposition'))[1];
                    
                    var arquivo = new Blob([response.data], { type: response.headers('content-type') });
					var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && !(navigator.userAgent.indexOf('Safari') > -1);
					
					if (!iOS) {
                        FileSaver.saveAs(arquivo, fileName);
                    }
            	}
            	
                if (window.location.href.indexOf('url_download') > -1) {
                    var regex = new RegExp('[?&]url_download(=([^#]*)|&|#|$)'),
                    results = regex.exec(window.location.href);
    
                    window.location.href = window.location.href.replace(results[0], '');
                }
            }
            
            function error(response) {
                $ionicLoading.hide();  
                
                if (window.location.href.indexOf('url_download') > -1) {
                    var regex = new RegExp('[?&]url_download(=([^#]*)|&|#|$)'),
                    results = regex.exec(window.location.href);
    
                    window.location.href = window.location.href.replace(results[0], '');
                }
            }
        }

        this.downloadArquivoBoleto = function(params) {

            insertParam('url_download', params.url);
            
            $http({
                url: params.url,
                method: params.method,
                data: params.data,
                responseType: "arraybuffer",
                headers: { 'Authorization': 'Basic ' + btoa('apiuser:apiuser') }
            }).then(success, error);
            
            function success(response) {
                $ionicLoading.hide();
                
            	if (response.status === 200) {
                    var fileName =  'boleto.pdf';
                    
                    var arquivo = new Blob([response.data], { type: response.headers('content-type') });
					var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && !(navigator.userAgent.indexOf('Safari') > -1);
					
					if (!iOS) {
                        FileSaver.saveAs(arquivo, fileName);
                    }
            	}
            	
                if (window.location.href.indexOf('url_download') > -1) {
                    var regex = new RegExp('[?&]url_download(=([^#]*)|&|#|$)'),
                    results = regex.exec(window.location.href);
    
                    window.location.href = window.location.href.replace(results[0], '');
                }
            }
            
            function error(response) {
                $ionicLoading.hide();  
                
                if (window.location.href.indexOf('url_download') > -1) {
                    var regex = new RegExp('[?&]url_download(=([^#]*)|&|#|$)'),
                    results = regex.exec(window.location.href);
    
                    window.location.href = window.location.href.replace(results[0], '');
                }
            }
        }
    }
]);
