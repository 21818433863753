(function($app) {

    $app.filter('tel', function(){

		return function(input) {
		  	var str = input+ '';
		        str = str.replace(/\D/g,'');
		        if(str.length === 11 ){
		        str=str.replace(/^(\d{2})(\d{5})(\d{4})/,'($1) $2-$3');
		    	}else{
		    	str=str.replace(/^(\d{2})(\d{4})(\d{4})/,'($1) $2-$3');
		    	}
		    return str;
		  };

    });

    $app.filter('cep', function(){
    	return function(input) {
		  	var str = input+ '';
		        str = str.replace(/\D/g,'');
		        str=str.replace(/^(\d{2})(\d{3})(\d)/,"$1.$2-$3");
		    return str;
		  };
    });

    $app.filter('filtroHtml', function($sce){
        return function(html){
            return $sce.trustAsHtml(html)
        }
     })

    $app.filter('cnpj', function() {
	  return function(input) {
	  	var str = input+ '';
	        str=str.replace(/\D/g,'');
	    	str=str.replace(/^(\d{2})(\d)/,'$1.$2');
	    	str=str.replace(/^(\d{2})\.(\d{3})(\d)/,'$1.$2.$3');
	    	str=str.replace(/\.(\d{3})(\d)/,'.$1/$2');
	    	str=str.replace(/(\d{4})(\d)/,'$1-$2');
	    return str;
	  };
	});


    $app.filter('encodeURIComponent', function(){
	    	return function(input){
	    		return encodeURIComponent(input);
	    	};
    	}
	);

    // Converte a data recebida para timestamp (data formato 'yyyy-MM-dd 00:00:00.0')
    $app.filter('dateToTimestamp', function(){
    	return function(input){
    		if(input && input.trim() !== ''){
    			var dt = new Date(input);
    			return dt.getTime();
    		} else {
    			return input;
    		}
    	}
    });

    // Converte a data recebida para timestamp (data formato 'yyyy-MM-dd 00:00:00.0')
    $app.filter('milisecsToDate', function(){
    	return function(input){
    		if(input && input.trim() !== null){
    			var dt = new Date(input);
    			return dt;
    		} else {
    			return input;
    		}
    	}
    });

    // Remove o ano da data recebida (data formato 'dd/MM/yyyy')
    $app.filter('removeYearFromDate', function(){
    	return function(input){
    		if(input && input.trim() !== ''){
    			return input.match(/[0-9][0-9]\/[0-9][0-9]/g).toString(); // formato dia/mes
    		} else {
    			return input;
    		}
    	}
	});

	$app.filter('number', ['ConversaoService', function(ConversaoService) {
		return function(input = 0) {
			return ConversaoService.Moeda(input);
		}
  }]);

  $app.filter('startFrom', function() {
    return (input, start) => {
      start = +start; //parse to int
      return input ? input.slice(start) : [];
    }
  });

}(app));
