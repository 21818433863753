angular.module('MyApp')
  .service('UserService', ["Configs", "SessionData", "$resource", "$q", "$http", function (Configs, SessionData, $resource, $q, $http) {
    let vm = this;
    var url;
    
    function buscarTransacoesAutorizadas(aluno) {
      let deferr = $q.defer();

      if (aluno) {
        $resource(Configs.getApiServer() + '/pessoas/codPessoa/:pessoaId/codAluno/:alunoId/buscarTransacoesAutorizadas',
          {}, { 'query': { method: 'GET' } }).query({
            pessoaId: aluno.usuarioLogado.id,
            alunoId: encodeURIComponent(aluno.codAluno)
          }, function (response) {
            if (response.transoesAutorizadas)
              deferr.resolve(response.transoesAutorizadas)
            else
              deferr.resolve(false)
          });
      } else {
        deferr.resolve(false);
      }

      return deferr.promise;
    }

    function buscarTransacoesAutorizadasAlunosFunc(alunosOld, deferred, alunosNew) {
      if (!alunosNew) alunosNew = [];
      if (alunosOld.length > 0) {
        buscarTransacoesAutorizadas(alunosOld[0]).then(function (transacoes) {
          alunosOld[0].transacoes = transacoes;
          alunosNew.push(alunosOld[0]);
          alunosOld.shift();
          buscarTransacoesAutorizadasAlunosFunc(alunosOld, deferred, alunosNew);
        })
      } else {
        return deferred.resolve(alunosNew);
      }
    }

    this.buscarTransacoesAutorizadasAlunos = function (alunos) {
      let deferr = $q.defer();
      buscarTransacoesAutorizadasAlunosFunc(alunos, deferr);
      return deferr.promise;
    }
    this.alterarTemaAplicacao = function () {
      let  deferr  =  $q.defer();
      let  usuario  = SessionData.aluno.list[SessionData.aluno.selecionado] ? SessionData.aluno.list[SessionData.aluno.selecionado] : SessionData.user;      

      $resource(Configs.getApiServer() +'/pessoas/codPessoa/:pessoaId/alterarTemaAplicacao?codAluno=' + usuario.codAluno,
        {}, {  'query':  {  method:  'GET'  } }).query({
          pessoaId: usuario.id,
        }, function (result) {
          deferr.resolve(result);
        }, function (err) {
          deferr.reject(err);
        });
      return  deferr.promise;
    }
    
    this.gerarToken = function(login, path) {
    	let  defer  =  $q.defer();
    	var params = {login: login, path: path};

      $resource(Configs.getApiServer() + '/pessoas/reset_senha_aluno', {}, {
        query: {
          method: 'POST',
          isArray: false
        }
      })
      .query(params, function(result) {
        defer.resolve(result);
      }, function(err) {
        defer.reject(err);
      });

      return defer.promise;
    }

    this.resetarSenha = function(token, senha, senhaRepete) {
    	let  defer  =  $q.defer();
      var params = {
        "codigo": token,
        "senha": senha, 
        "repeticaoSenha": senhaRepete
      }

      $resource(Configs.getApiServer() + '/pessoas/cadastra_nova_senha_aluno', {}, {
        query: {
          method: 'POST',
          isArray: false
        }
      })
      .query(params, function(result) {
        defer.resolve(result);
      }, function(err) {
        defer.reject(err);
      });

      return defer.promise;
    }
    
    this.setTheme = function () {
      if (!SessionData.isEmpty('user')) {

        let deferr = $q.defer();

        this.alterarTemaAplicacao()
          .then(function (result) {
            if (result != null && result.tema != null){
              const folderRest = 'css/' + result.tema;
              const head = document.getElementsByTagName('head')[0];
              var link = document.createElement('link');
              link.rel = 'stylesheet';
              link.type = 'text/css';
              link.href = folderRest + '/custom.css';
              link.media = 'all';
              head.appendChild(link);

              deferr.resolve();
            }
          })
          .catch(function () {
            deferr.resolve();
          });
        return deferr.promise;
      }
    }

    // Retorna a página inicial definida para o aluno
    this.getPaginaInicial = function(aluno){
    	let deferr = $q.defer();

    	$resource(Configs.getApiServer() +'/pessoas/cod_pessoa/:cod_pessoa/cod_aluno/:cod_aluno/obter_pagina_inicial', 
    			{}, {  'query':  {  method:  'GET'  } }).query({
    				cod_pessoa: aluno.usuarioLogado.id,
    			  	cod_aluno:  encodeURIComponent(aluno.codAluno)
    			}, function (response) {
    	          deferr.resolve(response);
    	        }, function (err) {
    	          deferr.reject(err);
    	        });

    	return deferr.promise;
    }
    
  }]);
