angular
    .module('acordoCadastro')
    .controller('AcordoCadastroController', [
        '$scope',
        '$http',
        '$rootScope',
        '$state',
        '$timeout',
        '$translate',
        'Notification',
        '$ionicHistory',
        '$ionicModal',
        'SessionData',
        'AcordoService',
        '$log',
        '$ionicLoading',
        '$q',
        '$ionicScrollDelegate',
        'Configs',
        'AcordoFactory',
        'Storage',
        function ($scope, $http, $rootScope, $state, $timeout, $translate, Notification, $ionicHistory, $ionicModal, SessionData, AcordoService, $log, $ionicLoading, $q, $ionicScrollDelegate, Configs, AcordoFactory, Storage) {
            var acordoCadCtrl = this;
            acordoCadCtrl.id = 'AcordoCadastroController';
            $scope.id = acordoCadCtrl.id;
            acordoCadCtrl.exibirMsg = false;

            acordoCadCtrl.sessionData = SessionData;
            acordoCadCtrl.aluno = acordoCadCtrl.sessionData.aluno;
            acordoCadCtrl.user = acordoCadCtrl.sessionData.user;
            acordoCadCtrl.responsavel = SessionData.perfil === "responsavel" ? true : false;
            acordoCadCtrl.realizarAcordo = false;
            acordoCadCtrl.scrolling = false;
            acordoCadCtrl.cobrancasObrigatorias = [];
            acordoCadCtrl.cobrancasOpcionais = [];
            acordoCadCtrl.acordo = {
                codAluno: acordoCadCtrl.aluno.list[acordoCadCtrl.aluno.selecionado].codAluno,
                codPessoa: acordoCadCtrl.user.id,
                usuario: acordoCadCtrl.user.name,
                listaDeCobrancaEmAberto: []
            };
            acordoCadCtrl.getWindowWidth = getWindowWidth;
            acordoCadCtrl.totalCobrancas = 0;
            acordoCadCtrl.parcelasChecadas = parcelasChecadas;
            acordoCadCtrl.podeRealizarAcordo = false;
            acordoCadCtrl.scrolling = true;
            acordoCadCtrl.title = 'Home.view.Acordo';
            acordoCadCtrl.msgConfirmacao = 'Acordo.Mensagem.Confirmacao';

            $ionicLoading.show({
                content: 'Loading',
                animation: 'fade-in',
                showBackdrop: true,
                maxWidth: 200,
                showDelay: 1
            });

            acordoCadCtrl.steps = [
                {
                    id: 0,
                    view: "cobrancas",
                    state: 'home.acordo.cadastro.cobrancas'
                }, {
                    id: 1,
                    view: "parcelamentos",
                    state: 'home.acordo.cadastro.parcelamentos'
                }, {
                    id: 2,
                    view: "confirmacao",
                    state: 'home.acordo.cadastro.confirmacao'
                }
            ];

            $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
                if(toState.url == '/acordos')
                    $state.go(acordoCadCtrl.steps[0].state);
            });

            acordoCadCtrl.clear = function() {
                acordoCadCtrl.cancelButton = {};
                acordoCadCtrl.finalButton = {};
                acordoCadCtrl.nextButton = {};
                acordoCadCtrl.pagamentoButton = false;
            }

            AcordoService
                .verificarSePessoaPodeFazerAcordo()
                .then(function (result) {
                    acordoCadCtrl.podeRealizarAcordo = result.podeRealizarAcordo;
                    acordoCadCtrl.msgErro = result.msgErro;

                    if (result.podeRealizarAcordo) {
                        AcordoService
                            .opcoesAcordoParaAluno()
                            .then(function (result) {
                                acordoCadCtrl.acordo.opcoesDoAcordo = result;
                                acordoCadCtrl.text = acordoCadCtrl.acordo.opcoesDoAcordo.usaPreAcordo ? $translate.instant('Aluno.PreAcordo.SubTitle') : $translate.instant('Aluno.Acordo.SubTitle');
        
                                if (acordoCadCtrl.acordo.opcoesDoAcordo.usaPreAcordo) {
                                    acordoCadCtrl.title = 'Aluno.PreAcordo.Title';
                                    acordoCadCtrl.msgConfirmacao = 'Pre.Acordo.Mensagem.Confirmacao';
                                }
        
                                AcordoService
                                    .obterCobrancasAbertas()
                                    .then(function (result) {
                                        if (result) {
                                            if (result.cobrancasObrigatorias && result.cobrancasObrigatorias.length > 0) {
                                                acordoCadCtrl.cobrancasObrigatorias = result.cobrancasObrigatorias;
                                            }
        
                                            if (result.cobrancasOpcionais && result.cobrancasOpcionais.length > 0) {
                                                acordoCadCtrl.cobrancasOpcionais = result.cobrancasOpcionais;
                                            }
        
                                            if (acordoCadCtrl.cobrancasOpcionais.length == 0 && acordoCadCtrl.cobrancasObrigatorias.length == 0) {
                                                acordoCadCtrl.clear();
                                                acordoCadCtrl.exibirMsg = true;
                                                $ionicLoading.hide();
                                            } else {
                                                $ionicLoading.hide();
                                            }
                                        }
                                    });
                            });
                    } else {
                        acordoCadCtrl.clear();
                        $ionicLoading.hide();
                    }

                });

            var selecionarParcelas = {
                caption: "Aluno.Acordo.Wizard.Prosseguir",
                style: "button-green",
                enabled: true,
                onClick: function () {
                    $ionicLoading.show();
                    let defer = $q.defer();
                    acordoCadCtrl.totalCobrancas = 0;

                    acordoCadCtrl.acordo.listaDeCobrancaEmAberto = acordoCadCtrl.cobrancasObrigatorias;

                    if (acordoCadCtrl.cobrancasOpcionais.length > 0) {
                        acordoCadCtrl.cobrancasOpcionais
                            .forEach(function (cobrancaOpcional) {
                                if (cobrancaOpcional.check) {
                                    acordoCadCtrl.acordo.listaDeCobrancaEmAberto.push(cobrancaOpcional);
                                }
                            });
                    }

                    acordoCadCtrl.qtdCobranca = acordoCadCtrl.acordo.listaDeCobrancaEmAberto.length;

                    if (acordoCadCtrl.qtdCobranca > 0) {
                        acordoCadCtrl.acordo.listaDeCobrancaEmAberto.forEach(function (cobranca) {
                            acordoCadCtrl.totalCobrancas += cobranca.valorDiferenca;
                        });

                        AcordoService
                            .listaPlanosDePagamentoAcordo(acordoCadCtrl.acordo)
                            .then(function (parcelamentos) {
                                acordoCadCtrl.parcelamentos = parcelamentos;
                                acordoCadCtrl.nextButton = confirmarAcordo;
                                $ionicLoading.hide();
                                defer.resolve();
                                acordoCadCtrl.cancelButton = {
                                    caption: "Aluno.Acordo.Wizard.Cancelar",
                                    style: "button-gray",
                                    enabled: true,
                                    onClick: function () {
                                        let defer = $q.defer();
                                        acordoCadCtrl.openCancelar();
                                        defer.reject();
                                        return defer.promise;
                                    }
                                }
                            });

                    } else {
                        $ionicLoading.hide();
                        Notification.error($translate.instant('Acordo.Erro.Msg.Cobrancao.Selecionadas'));
                        defer.reject();
                    }

                    return defer.promise;
                }
            };

            var confirmarAcordo = {
                caption: "Aluno.Acordo.Wizard.Prosseguir",
                style: "button-green",
                enabled: true,
                onClick: function () {
                    $ionicLoading.show();
                    let defer = $q.defer();

                    if (acordoCadCtrl.acordo.planoDePagamentoSelecionado) {
                        AcordoService
                            .dadoParaConfirmacao(acordoCadCtrl.acordo)
                            .then(function(result) {
                                acordoCadCtrl.acordo = Object.assign({}, acordoCadCtrl.acordo, result);
                                gravarAcordo.caption = $translate.instant('Acordo.Confirmar.Btn') + acordoCadCtrl.text;
                                acordoCadCtrl.finalButton = acordoCadCtrl.acordo.opcoesDoAcordo.existeDocumentoAcordo ? verContrato : gravarAcordo;
                                acordoCadCtrl.gravarAcordo = gravarAcordo.onClick;
                                $ionicScrollDelegate.resize();
                                $ionicLoading.hide();
                                defer.resolve();
                            })
                            .catch(function(err) {
                                $ionicLoading.hide();
                                defer.resolve('');
                            });
                    } else {
                        $ionicLoading.hide();
                        Notification.error($translate.instant('Acordo.Erro.Msg.Parcelamento.Selecionado'));
                        defer.reject();
                    }

                    return defer.promise;
                }
            };

            var gravarAcordo = {
                style: "button-green",
                enabled: true,
                onClick: function () {
                    $ionicLoading.show();
                    let defer = $q.defer();

                    if (acordoCadCtrl.acordo.opcoesDoAcordo.existeDocumentoAcordo) {
                        acordoCadCtrl.closeConfirmacao();
                        AcordoService
                            .salvarAceite(acordoCadCtrl.acordo)
                            .then(function(result) {
                                acordoCadCtrl.hash = result.hash;
                                acordoCadCtrl.acordo.numAcordo = result.acordoGerado;
                                $ionicLoading.hide();
                            })
                            .catch(function(err) {
                                $log.error('err', err.data);
                                Notification.error(err.titulo);
                                $ionicLoading.hide();
                                defer.reject();
                            });
                    } else {
                        AcordoService
                            .salvarAceitoSemContrato(acordoCadCtrl.acordo)
                            .then(function(result) {
                                acordoCadCtrl.detalhes(result.acordoGerado);
                                $ionicLoading.hide();
                            })
                            .catch(function(err) {
                                $log.error('err', err.data);
                                Notification.error(err.titulo);
                                $ionicLoading.hide();
                                defer.reject();
                            });
                    }
                    acordoCadCtrl.pagamentoButton = true;
                    return defer.promise;
                }
            }

            acordoCadCtrl.detalhes = function(codigo) {
                acordoCadCtrl.closeContrato();
                $state.go('home.acordo.consulta', { codAcordo: codigo });
            }

            var verContrato = {
                caption: "Aluno.Acordo.Wizard.Prosseguir",
                style: "button-green",
                enabled: true,
                onClick: function () {
                    $ionicLoading.show();
                    let defer = $q.defer();

                    acordoCadCtrl.acordoOnLineDados = {
                        acordoOnLine: acordoCadCtrl.acordo,
                        opcoesAcordo: acordoCadCtrl.acordo.opcoesDoAcordo
                    };

//                    AcordoService
//                        .gerarHtmlDoAcordo(acordoCadCtrl.acordoOnLineDados)
                      AcordoService
                          .getHtmlDoAcordo(acordoCadCtrl.acordoOnLineDados)
                        .then(function(result) {
                        	Storage.set("contratoPDF", result.acordoPDF);
                        			
                            acordoCadCtrl.contratoAssinado = result.HTML;
                            acordoCadCtrl.openContrato();
                            $ionicLoading.hide();
                        })
                        .catch(function(err) {
                            Notification.error(err.titulo);
                            defer.reject();
                            $ionicLoading.hide();
                        });

                    return defer.promise;
                }
            }

            acordoCadCtrl.openCobrancas = function() {
                acordoCadCtrl.modalCobrancas.show();
            }

            acordoCadCtrl.closeCobrancas = function() {
                acordoCadCtrl.modalCobrancas.hide();
            }

            $ionicModal.fromTemplateUrl('js/custom-components/acordo-cadastro/detail/acordo.cadastro.modal.html', {
                scope: $scope,
                animation: 'slide-in-up',
            }).then(function (modal) {
                acordoCadCtrl.modalCobrancas = modal;
            });

            acordoCadCtrl.openContrato = function() {
                acordoCadCtrl.modalContrato.show();
                acordoCadCtrl.isContrato = true;
            }

            acordoCadCtrl.closeContrato = function() {
                acordoCadCtrl.modalContrato.hide();
            }

            acordoCadCtrl.naoAceitar = function() {
                acordoCadCtrl.closeContrato();
                acordoCadCtrl.openCancelar();
            }

            $ionicModal.fromTemplateUrl('js/custom-components/acordo-cadastro/detail/acordo.cadastro.contrato.modal.html', {
                scope: $scope,
                animation: 'slide-in-up',
                backdropClickToClose: false
            }).then(function (modal) {
                acordoCadCtrl.modalContrato = modal;
            });

            acordoCadCtrl.openCancelar = function() {
                acordoCadCtrl.modalCancelar.show();
            }

            acordoCadCtrl.closeCancelar = function() {
                acordoCadCtrl.modalCancelar.hide();
            }

            $ionicModal.fromTemplateUrl('js/custom-components/acordo-cadastro/detail/acordo.cadastro.cancelamento.modal.html', {
                scope: $scope,
                animation: 'slide-in-up',
                backdropClickToClose: false
            }).then(function (modal) {
                acordoCadCtrl.modalCancelar = modal;
            });

            acordoCadCtrl.continuar = function(isContrato) {
                acordoCadCtrl.closeCancelar();

                if (acordoCadCtrl.isContrato) {
                    acordoCadCtrl.isContrato = false;
                    acordoCadCtrl.openContrato();
                }
            }

            acordoCadCtrl.cancelarConfirmacao = function() {
                acordoCadCtrl.closeConfirmacao();
            }

            acordoCadCtrl.cancelar = function() {
                acordoCadCtrl.closeCancelar();
                acordoCadCtrl.closeContrato();
                acordoCadCtrl.closeConfirmacao();
                $state.go('home.acordo', { isContrato: acordoCadCtrl.isContrato });
            }

            acordoCadCtrl.openConfirmacao = function() {
                acordoCadCtrl.modalConfirmacao.show();
            }

            acordoCadCtrl.closeConfirmacao = function() {
                acordoCadCtrl.modalConfirmacao.hide();
            }

            $ionicModal.fromTemplateUrl('js/custom-components/acordo-cadastro/detail/acordo.cadastro.confirmacao.modal.html', {
                scope: $scope,
                animation: 'slide-in-up',
                backdropClickToClose: false
            }).then(function (modal) {
                acordoCadCtrl.modalConfirmacao = modal;
            });

            function parcelasChecadas(parcelamento) {
                acordoCadCtrl.acordo.planoDePagamentoSelecionado = {
                    codPlanoPagamento: parcelamento.codPlanoPagamento,
                    honorarioDeTerceiro: parcelamento.parcelasSimuladas[0].honorariosDeTerceiros,
                    valorParcelaBase: parcelamento.valorParcelaBase,
                    percentualEntrada: parcelamento.percentualEntrada,
                    valorDesconto: parcelamento.parcelasSimuladas[0].descontoCalculado,
                    aplicaEncargos: parcelamento.aplicaEncargos,
                    qtdParcelas: parcelamento.qtdParcelas,
                    valorEntrada: parcelamento.valorEntrada,
                    listaDeParcelas: parcelamento.parcelasSimuladas,
                    permiteCartao: parcelamento.permiteCartao,                    
                    permiteBoleto: parcelamento.permiteBoleto,
                    recorrencia: parcelamento.recorrencia,
                    descricao: parcelamento.descricao,
                    tipoPagamento: parcelamento.tipoPagamento

                };
                acordoCadCtrl.acordo.valoresDoAcordo = {
                    valorSelecionado: parcelamento.parcelasSimuladas[0].valorOriginal,
                    encargoCalculado: parcelamento.parcelasSimuladas[0].encargoCalculado,
                    encargoBolsaCalculado: parcelamento.parcelasSimuladas[0].encargoBolsa,
                    encargoProgressivoCalculado: parcelamento.parcelasSimuladas[0].totalEncargosProgressivosPlano,
                    totalEncargo: parcelamento.parcelasSimuladas[0].totalEncargo,
                    valorFinalAcordo: parcelamento.parcelasSimuladas[0].valorFinal
                };

                $ionicScrollDelegate.resize();
                $timeout(function () {
                    $ionicScrollDelegate.scrollBy(0, $('#listaParcelasGeradas')[0].offsetTop, true);
                }, 250);
            }

            function getWindowWidth() {
                return window.innerWidth;
            }

            acordoCadCtrl.nextButton = selecionarParcelas;
            acordoCadCtrl.finalButton = gravarAcordo;

            acordoCadCtrl.imprimirContrato = function() {
                AcordoService.imprimirContrato(acordoCadCtrl.acordoOnLineDados);
            }

            acordoCadCtrl.imprimirContratoAceito = function() {
                AcordoService.imprimirContratoConsulta(acordoCadCtrl.acordo.numAcordo);
            }

            $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
                if (acordoCadCtrl.modalConfirmacao)
                    acordoCadCtrl.modalConfirmacao.hide();
                if (acordoCadCtrl.modalCobrancas)
                    acordoCadCtrl.modalCobrancas.hide();
                if (acordoCadCtrl.modalContrato)
                    acordoCadCtrl.modalContrato.hide();
                if (acordoCadCtrl.modalCancelar)
                    acordoCadCtrl.modalCancelar.hide();
            });
        }]);