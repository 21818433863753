(function() {
    angular.module('info', [])
      .component('infoComponent', {
        templateUrl: 'js/custom-components/info/info.component.html',
        bindings: {
            message: "<"
        },
        controllerAs: 'vm'
      });
    
    })();