(function () {
  /*
   * Modulo do componenet avisos-list (avisosList)
   */
  angular
    .module('avisos')
    .controller('AvisosListController', [
      '$log',
      '$scope',
      'Aluno.Avisos',
      'SessionData',
      AvisosListController]);

  function AvisosListController($log, $scope, Aluno_Avisos, SessionData) {
      var vm = this;
      vm.id = 'AvisosListController';
      $scope.id = vm.id;

      $log.debug('[AvisosListController]\t$scope=', $scope);
      $log.debug('[AvisosListController]\tSessionData=', SessionData);

      var aluno = SessionData.aluno;
      var alunoSelecionado = aluno.list[aluno.selecionado];

      // Lista de avisos & ocorrencias
      vm.avisos = [];

      Aluno_Avisos.query({codPessoa : alunoSelecionado.id, codAluno: encodeURIComponent(alunoSelecionado.codAluno)}, Aluno_Aviso_handleSuccess, Aluno_Aviso_handleFailure);

      function Aluno_Aviso_handleFailure(reason) {
        $log.error('[Aluno_Aviso_handleFailure]\terror' + arguments);
        if(reason !== undefined) $log.error('[Aluno_Aviso_handleFailure]\treason:' + reason);
      }

      function Aluno_Aviso_handleSuccess(result) {
        $log.debug('[Aluno_Aviso_handleSuccess]\t result=' + result);
        vm.avisos = result.content;  
      }

      vm.listOpenAvisoDetail = function(item) {
        vm.avisoItem = item;
        $log.debug('[AvisosListController.listOpenAvisoDetail]\tvm=', vm);
        vm.onOpenAvisoDetail({aviso: item});
      }

      $log.debug('[AvisosListController]\tvm=', vm);
   }
})();
