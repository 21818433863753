(function() {
  /*
   * Modulo do componente boletim-deck (boletimDeck)
   */
	angular.module('boletim', ['aol.service', 'boletimDetail'])
    	.controller('BoletimDeckController', ['$log', 'Aluno.Disciplinas.Boletim', 'Aluno.Provas.Boletim','RelatorioService', '$attrs', 'Notification', '$ionicLoading', '$ionicModal', '$scope', '$http', 'Configs', 'DownloadArquivo', 'RelatorioService', BoletimDeckController]);

	function BoletimDeckController($log, Aluno_Disciplinas_Boletim, Aluno_Provas_Boletim, RelatorioService, $attrs, Notification, $ionicLoading, $ionicModal, $scope, $http, Configs, DownloadArquivo, RelatorioService) {
		var self = this;

		self.boletim;
		self.rows = [];
		self.listarSubPeriodos     = listarSubPeriodos;
		self.mediaSubperiodo       = mediaSubperiodo;
		self.faltasSubperiodo      = faltasSubperiodo;
		self.getWindowWidth        = getWindowWidth;
		self.filtrosSubPeriodo     = filtrosSubPeriodo;
		self.SubperiodoSelecionado = "";
		
		
		self.imprimirRelatorio = function(pagina, aluno) {
		   
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});
			
			RelatorioService.downloadReportService(pagina, aluno);
		}
		
		self.itemClick = function(cell) {
			$log.debug('[BoletimDeckController.itemClick] cell:', cell);
			self.itemOpenBoletimDetail(cell);
		}

		self.itemOpenBoletimDetail = function(cell) {
			$log.debug('[BoletimDeckController.itemOpenBoletimDetail]\t cell=', cell);
			self.onOpenBoletimDetail({cell: cell});
		}
		
		self.faltasDetail = function (cell) {
			self.onOpenFaltasDetail({cell: cell});
		}
		
		self.alunoSelecionado = self.aluno.list[self.aluno.selecionado];
		
		self.itemProvaDetail = function (cell) {
			
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});
			
			Aluno_Provas_Boletim.query({codAluno: encodeURIComponent(cell.aluno.codAluno),
				 						codDisciplina: cell.boletim.turmaDisciplina.disciplina.codDisciplina,
				 						codTurma: encodeURIComponent(cell.boletim.turmaDisciplina.turma.turma),
				 						ano: cell.boletim.turmaDisciplina.turma.periodo.ano,
				 						periodo: cell.boletim.turmaDisciplina.turma.periodo.semestre},
				Aluno_Provas_handleSuccess,
				Aluno_Provas_handleFailure);
			
			function Aluno_Provas_handleSuccess(result) {
				
				$ionicLoading.hide();
				
				self.onOpenProvasDetail({cell: cell, provas: result.content, subPeriodo: self.SubperiodoSelecionado});
			}

			function Aluno_Provas_handleFailure(reason) {

				var keys = Object.keys(reason);
				var str = '';
				
				for (var k in keys) {
					str += reason[k] + '\n';
				}
	        
				Notification.error('[Aluno_Boletins_handleFailure]\treason=' + str);
				
				$ionicLoading.show({
					template: str,
					duration: 2500
				});
			}
		}
		
		$ionicLoading.show({
			content : 'Loading',
			animation : 'fade-in',
			showBackdrop : true,
			maxWidth : 200,
			showDelay : 1
		});
		
		Aluno_Disciplinas_Boletim.query({alunoId: encodeURIComponent(self.alunoSelecionado.codAluno),
										 pessoaId: self.alunoSelecionado.id},
										Aluno_Boletins_handleSuccess,
										Aluno_Boletins_handleFailure);

		function filtrosSubPeriodo (subPeriodoSelecionado) {
			mediaSubperiodo(subPeriodoSelecionado);
			faltasSubperiodo(subPeriodoSelecionado);
		}
		
		function listarSubPeriodos(sPeriodo) {
			self.subPeriodos = [];
			
			for (i = 0; i < sPeriodo.length; i ++) {
				
				pos = self.subPeriodos.map((e) => e.descricao).indexOf(sPeriodo[i].descricao);
				if ( (pos === -1) && (sPeriodo[i].descricao !== null) ) {
					
					var subPeriodo = {};
					subPeriodo.codSubPeriodo = sPeriodo[i].codSubPeriodo;
					subPeriodo.descSubPeriodo = sPeriodo[i].descricao;
					
					self.subPeriodos.push(subPeriodo);
					self.filtroSubperiodo = true;
				}  
			}
		}
		
		function mediaSubperiodo (subPeriodo) {
			
			self.SubperiodoSelecionado = subPeriodo;
			
  	  		for (i = 0; i < self.boletinsAluno.length; i ++){
  	  			for (j = self.boletinsAluno[i].mediaSubPeriodos.length-1; j >= 0; j --) {
  	  				if ( (subPeriodo.codSubPeriodo !== null) &&
  	  					 (self.boletinsAluno[i].mediaSubPeriodos[j].periodo.subPeriodo.codSubPeriodo === subPeriodo.codSubPeriodo.toString()) ) {
	  	  				self.boletinsAluno[i].media = self.boletinsAluno[i].mediaSubPeriodos[j].nota;
	  	  				break;
	  	  			} else {
	  	  				self.boletinsAluno[i].media = "";
	  	  			}
	  	  		}	
  	  		}
        }
		
		function faltasSubperiodo (subPeriodo) {
			self.SubperiodoSelecionado = subPeriodo;
		

			RelatorioService.verificaReportServiceEntry('Boletim', self.alunoSelecionado.codAluno)
			.then(function(result) {
				self.exibeRelatorio = result;
			}).catch(function(err) {
				console.log(err);
			});
			
  	  		for (i = 0; i < self.boletinsAluno.length; i ++){
  	  			var totalFaltas = {};
  	  			var faltasAcumuladas = 0;
  	  			
  	  			for (j = 0; j < self.boletinsAluno[i].boletim.turmaDisciplina.disciplina.faltas.length; j ++) {
  	  				/* Faltas por Subperiodo */
  	  				/*
  	  				if ( (subPeriodo.codSubPeriodo !== null) && (self.boletinsAluno[i].boletim.turmaDisciplina.disciplina.faltas[j].periodo.subPeriodo) &&
  	  					 (self.boletinsAluno[i].boletim.turmaDisciplina.disciplina.faltas[j].periodo.subPeriodo.codSubPeriodo === subPeriodo.codSubPeriodo.toString()) ) {
  	  					faltasAcumuladas += self.boletinsAluno[i].boletim.turmaDisciplina.disciplina.faltas[j].faltasAcumuladas;
	  	  			}*/
  	  				faltasAcumuladas += self.boletinsAluno[i].boletim.turmaDisciplina.disciplina.faltas[j].faltasAcumuladas;
	  	  		}
  	  			totalFaltas.faltasAcumuladas =  faltasAcumuladas; 
  	  			self.boletinsAluno[i].faltasSubPeriodo = totalFaltas;
  	  		}
        }
		
		function Aluno_Boletins_handleSuccess(result) {
			
			self.boletinsAluno = result.content;

			for (d = 0; d < self.boletinsAluno.length; d++) {
				
				var cell = {};
				cell.boletimAluno = self.boletinsAluno[d];

				// Lista os subperiodos do boletim.
				listarSubPeriodos(cell.boletimAluno.boletim.turmaDisciplina.turma.periodo.listSubPeriodo);
				
				// Subperiodo corrente
				var subPeriodoCorrente = {};
				if (self.boletinsAluno[d].boletim.turmaDisciplina.turma.periodo.subPeriodoCorrente !== null) {
					subPeriodoCorrente.codSubPeriodo = self.boletinsAluno[d].boletim.turmaDisciplina.turma.periodo.subPeriodoCorrente.codSubPeriodo;
					subPeriodoCorrente.descSubPeriodo = self.boletinsAluno[d].boletim.turmaDisciplina.turma.periodo.subPeriodoCorrente.descricao;
				}
				if (subPeriodoCorrente.codSubPeriodo !== null) {
					$scope.subPeriodoSelecionado = subPeriodoCorrente;					
				} else {
					subPeriodoCorrente.codSubPeriodo = '0';
					subPeriodoCorrente.descSubPeriodo = '';
					
					$scope.subPeriodoSelecionado = subPeriodoCorrente;
				}
				
				if (d >= self.boletinsAluno.length) {
					break;
				}
			}
			
			filtrosSubPeriodo($scope.subPeriodoSelecionado)
			$ionicLoading.hide();
		}

		function Aluno_Boletins_handleFailure(reason) {
			$log.debug('[Aluno_Boletins_handleFailure]\treason=', reason);

			var keys = Object.keys(reason);
			var str = '';
			
			for (var k in keys) {
				str += reason[k] + '\n';
			}
        
			$log.debug('[Aluno_Boletins_handleFailure]\treason=' + str);
			
			Notification.error('[Aluno_Boletins_handleFailure]\treason=' + str);
			
			$ionicLoading.show({
				template: str,
				duration: 2500
			});
		}
		
	    function getWindowWidth(){
	    	return window.innerWidth;
	    }
	}
})();