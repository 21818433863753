(function() {
angular.module('minhaTurmaListModule')
  .component('minhaTurmaList', {
    templateUrl: 'js/custom-components/minha-turma-list/minha-turma-list.component.html',
    bindings: {
      turma: "="
    },
    controller: 'MinhaTurmaListController',
    controllerAs: 'self'
  });

})();
