(function() {
  /*
   * Modulo do componente ocorrencia-detail (ocorrenciaDetail)
   */
  angular
    .module('ocorrenciaDetail', [])
    .controller('OcorrenciaDetailController', ['$log', '$ionicLoading', 'Configs', 'DownloadArquivo', OcorrenciaDetailController])
  ;

  function OcorrenciaDetailController($log, $ionicLoading, Configs, DownloadArquivo) {
    var vm = this;

    vm.downloadAnexoOcorrencia = function(aluno, data, tipo, ordem) {
      $ionicLoading.show({
        content : 'Loading',
        animation : 'fade-in',
        showBackdrop : true,
        maxWidth : 200,
        showDelay : 1
      });    
      let params = {};
      var dataOco = (new Date(data + " GMT-03:00"));
      params.url = Configs.getApiServer() + '/pessoas/cod_aluno/' + aluno + '/data_ocorrencia/' + dataOco + '/tipo_ocorrencia/' + tipo + '/ordem_ocorrencia/' + ordem + '/baixar_anexo_ocorrencia'; 
      params.method = "GET";
      DownloadArquivo.downloadArquivo(params);
    }
    
    $log.debug('[OcorrenciaDetailController]\tvm=', vm);
  }
})();
