/**
 * Componente Matricula Termo
 */
(function(){
	angular.module('matriculaTermoModule').component('matriculaTermo', {
		templateUrl: 'js/custom-components/matricula-termo/matricula-termo.component.html',
		controller: 'MatriculaTermoComponentController',
		controllerAs: 'ctrl',
		bindings: {
			matriculaController: "<"
		}
	});
})();