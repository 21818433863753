/**
 * Component Minha Turma Detail (minhaTurmaDetail)
 */
(function() {
angular.module('minhaTurmaModule')
  .component('minhaTurmaDetail', {
    templateUrl: 'js/custom-components/minha-turma-detail/minha-turma-detail.component.html',
    bindings: {
      aluno: "="
    },
    controller: 'MinhaTurmaDetailController',
    controllerAs: 'ctrl'
  });

})();