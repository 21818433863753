(function() {
angular.module('servico')
  .component('servicoListItem', {
    templateUrl: 'js/custom-components/servico-list-item/servico-list-item.component.html',
    bindings: {
      servico: "="
    },
    controller: 'ServicoListItemComponentController',
    controllerAs: 'ctrl'
  });

})();
