angular
    .module('MyApp')
    .service('AlunoCadastroService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {
    	var _msgErro = {msgErro: 'Error student undefined'};
    	var _objAluno;
    	var codPessoa;
    	function dadosValidosAluno(){
    		if(SessionData.aluno){
    			_objAluno = {idPessoa: SessionData.aluno.list[SessionData.aluno.selecionado].id};
    			codPessoa = {codPessoa: SessionData.aluno.list[SessionData.aluno.selecionado].id};
    			return true;
    		}
    		return false;
    	}
        this.obterDadosPessoa = function() {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/pessoas/idPessoa/:idPessoa/obterPessoa', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(_objAluno, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterDadosRespFinan = function(respFinan) {
            let defer = $q.defer();
            let dados = {
                responsavel: encodeURI(respFinan)
            }
            if (respFinan) {
                $resource(Configs.getApiServer() + '/financeiro/responsavel/:responsavel/obter_dados_resp_finan', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(dados, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterAlunoSessao = function() {
            let defer = $q.defer();

            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/pessoas/codPessoa/:codPessoa/buscarAlunosSessao', {}, {
                        cache: false,
                        query: {
                            method: 'GET'
                        }
                    })
                    .query(codPessoa, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }

            return defer.promise;
        }

        this.obterDadosAcademicos = function(codAluno) {
            let defer = $q.defer();
						let codAlunoConsulta = {"codAluno": codAluno};            

            $resource(Configs.getApiServer() + '/pessoas/codAluno/:codAluno/obterDadosAcademicos', {}, {
                    cache: false,
                    query: {
                        method: 'GET'
                    }
                })
                .query(codAlunoConsulta, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });
            return defer.promise;
        }
        
        this.obterSituacaoDocumentos = function(codAluno) {
            let defer = $q.defer();
						let codAlunoConsulta = {"codAluno": codAluno};            

            $resource(Configs.getApiServer() + '/pessoas/codAluno/:codAluno/obterSituacaoDocumentos', {}, {
                    cache: false,
                    query: {
                        method: 'GET'
                    }
                })
                .query(codAlunoConsulta, function(result) {
                  //debugger;
                    if(result.status != 500){
                      defer.resolve(result);}
                    // else{
                    //   return;
                    //   defer.reject();
                    // }
                }, function(err) {
                  //debugger;
                    defer.reject();
                });
            return defer.promise;
        }      
        
        this.getListaDocumentosPendentesEEntregues = function(codAluno) {
            let defer = $q.defer();
						let codAlunoConsulta = {"codAluno": codAluno};            

            $resource(Configs.getApiServer() + '/pessoas/cod_aluno/:codAluno/lista_documentos_pendentes_e_entregues', {}, {
                    cache: false,
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                })
                .query(codAlunoConsulta, function(result) {
                  //debugger;
                    if(result.status != 500){
                       defer.resolve(result);}
                     else{
                    //   return;
                       defer.reject();
                    }
                }, function(err) {
                  //debugger;
                    defer.reject();
                });
            return defer.promise;
        }
        
        this.uploadDocumento = function(params){
            let defer = $q.defer();
            
            $resource(Configs.getApiServer() + "/pessoas/upload_documento_pessoa/", {}, {
                    query: {
                            method: 'POST',
                            cache: false
                        }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        } 
        
        this.incluirDocumento = function(params){
            let defer = $q.defer();
            
            $resource(Configs.getApiServer() + "/pessoas/inclui_documento/", {}, {
                    query: {
                            method: 'POST',
                            cache: false
                        }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }     
        
        this.baixarDocumento = function(){
            let defer = $q.defer();
            
            let params = {
                "codSolicitacao": codSolicitacao,
                "codItemSolicitacao": codItemSolicitacao
            };
            
            $resource(Configs.getApiServer() + '/pessoas/', {}, {
                    query: {
                            method: 'GET',
                            cache: false
                        }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }


        this.atualizarAluno = function(alunoCadastro) {
            let deferr = $q.defer();

            if (alunoCadastro) {
                $resource(Configs.getApiServer() + '/pessoas/alterarPessoa', {}, {
                    'query': {
                        method: 'POST',
                        isArray: false
                    }
                }).query(alunoCadastro,
                    function(response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                    },
                    function() {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

    }]);