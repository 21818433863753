/**
 * Controller do Component Minha Turma Detail (minhaTurmaDetail)
 */
(function(){
	angular.module('minhaTurmaModule').controller('MinhaTurmaDetailController', ['$scope', '$log', 'Notification', '$ionicModal', MinhaTurmaDetailController]);
	
	function MinhaTurmaDetailController($scope, $log, Notification, $ionicModal){
		var ctrl = this;
		
		$log.debug('[MinhaTurmaDetailController]:ctrl=', ctrl);
		
		//ctrl.getAlunoImageURL = getAlunoImageURL;
		ctrl.fotoAluno = getAlunoImageURL(ctrl.aluno);
		
		ctrl.obterPrimeiraLetra = function() {
			  var primeiraLetra = '';
        primeiraLetra = ctrl.aluno.nomeCompleto.substring(0, 1);			    
			  return primeiraLetra
		}
		
		var colors = ['#c4195c', '#5c6cc2', '#f4521e', '#ac47bb', '#512da9', '#68a039', '#906d65', '#02887b', '#475a63', '#5d4339', '#77909f', '#ed427b', '#ff8040', '#66a038', '#0388d2', '#ef6d00', '#7d57c3', '#80ff00', '#0098a7', '#3cc472', '#5d4138', '#ad48bc', '#679f38', '#8080c0', '#e4b31b', '#024d42'];
		
		ctrl.alunosColors = {};
		
		function getRandomColors(){			
			return colors[Math.floor(Math.random()*colors.length)];
		}
		
		angular.forEach($scope.$parent.self.alunos, function(value, key){
			ctrl.alunosColors[value.id] = getRandomColors();
		});
		
		// Gera o blob com a foto do aluno
		function getAlunoImageURL(aluno){
			
			if(aluno && aluno.fotoAluno){
				var imgURL = URL.createObjectURL(toPngBlob(aluno.fotoAluno));
				
				return 'background-image: url('+imgURL+')';
			} else {
				return null;
				//return "img/nophoto.png";
			}

			
			// Converte para Blob do tipo imagem PNG
			function toPngBlob(strHexadecimal){
				var hexString = atob(strHexadecimal);
			    var aBuffer = new ArrayBuffer(hexString.length/2);
			    var byteBuffer = new Uint8Array(aBuffer);
			    
			    for (let i=0; i<hexString.length; i+=2) {
			    	byteBuffer[i/2] = parseInt(hexString.slice(i,i+2),16);
			    }
			    
			    var blob = new Blob([byteBuffer], {type: "image/png"});
			    
			    
			    return blob;
			}
		}
	}
	
})();