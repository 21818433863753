(function() {
  /*
   * Modulo do componente aviso-detail (avisoDetail)
   */
  angular
    .module('avisoDetail', [])
    .controller('AvisoDetailController', ['$ionicPopup', '$log', AvisoDetailController])
  ;

  function AvisoDetailController($ionicPopup, $log) {
    var vm = this;

      // Lista de avisos & ocorrencias
  //    vm.avisos = [];
  //
  //    vm.avisos.push({title:"Próxima Aula", content:"10h00 - Matemática<br>Sala: 5B", icon:"icon ion-cube placeholder-icon", url: "#"});
  //    vm.avisos.push({title:"Aviso:", content:"Matrículas abertas para o próximo semestre.<br>Informações na secretaria.", icon:"icon ion-person-stalker placeholder-icon", url:"#"});
 
    vm.showAlert = function() {
    	//Ao clicar em um item que tenha um link abrirá um aviso
    	if(vm.avisoItem.aviso.atualizaLink){
	        var alertPopup = $ionicPopup.alert({
	           title: 'AOnline',
	           template: 'Link somente disponível no Website.'
	        });
	
	        alertPopup.then(function(res) {
	           // Custom functionality....
	        });
    	}
    };

    $log.debug('[AvisoDetailController]\tvm=', vm);
  }
})();
