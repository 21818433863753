(function(){
    angular
        .module('historicoListModule')
        .component('historicoList', {
            templateUrl: 'js/custom-components/historico-list/historico.list.html',
            bindings: {
                list: "=",
                columns: "="
            },
            controller: 'HistoricoListController',
            controllerAs: 'historicoCtrl'
        });
})();