angular
    .module('historicoCabecalhoModule')
    .controller('HistoricoCabecalhoController', [
        '$scope',
        '$state',
        '$log',
        'SessionData',
        'DownloadArquivo',
        'Configs',
        '$ionicLoading',
        function($scope, $state, $log, SessionData, DownloadArquivo, Configs, $ionicLoading) {
            var historicoCtrl = this;
            historicoCtrl.id = 'HistoricoCabecalhoController';

            historicoCtrl.getWindowWidth = function() {
                return window.innerWidth;
            }

            historicoCtrl.obterHistoricoPdf = function() {
              let _aluno = SessionData.aluno;
              let codAluno = _aluno.list[_aluno.selecionado].codAluno;
      	      let params = {};
      				params.url = Configs.getApiServer() + '/matricula/cod_aluno/' + codAluno + '/exibir_arquivo/false/obtem_historico_simplificado_pdf';
      				params.method = "GET";
      				$ionicLoading.show({
      		    		content : 'Loading',
      		    		animation : 'fade-in',
      		    		showBackdrop : true,
      		    		maxWidth : 200,
      		    		showDelay : 1
      		    	});
      				DownloadArquivo.downloadArquivo(params);
            }
        }]);
