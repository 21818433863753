angular.module('MyApp')
    .service('MatriculaService', ["Configs", "SessionData", "$resource", "$q", function (Configs, SessionData, $resource, $q) {

        // Service designed to verify if user has a contract to accept before accessing the system
        this.verificaExibeContratoLogin = function (codAluno) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + '/pessoas/cod_aluno/:cod_aluno/verifica_exibe_contrato_login',
            {}, {'query': {method: 'GET', isArray: false}}).query({
            cod_aluno: encodeURIComponent(codAluno)
          }, function (response) {
            if (!response.status)
              defer.resolve(response);
            else
              defer.reject();
          }, function () { 
            defer.reject();
          });
          return defer.promise;
        };

        // Service designed to generate / view the user contract
        this.gerarContratoAlunoLogin = function (codAluno) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + '/pessoas/cod_aluno/:cod_aluno/gera_contrato_aluno_login',
            {}, {'query': {method: 'GET', isArray: false}}).query({
            cod_aluno: encodeURIComponent(codAluno)
          }, function (response) {
            if (!response.status)
              defer.resolve(response);
            else
              defer.reject();
          }, function () {
            defer.reject();
          });
          return defer.promise;
        };

        // Service designed to accept the user contract
        this.aceitarContratoAlunoLogin = function (codAluno, htmlContrato) {
          let payload = {
            aluno: codAluno,
            htmlContrato: htmlContrato,
            contratoAceito: true
          };
          let defer = $q.defer();
          $resource(Configs.getApiServer() + '/pessoas/aceita_contrato_aluno_login',
            {}, {'query': {method: 'POST', isArray: false}}).query(payload, function (response) {
            if (!response.status)
              defer.resolve(response);
            else
              defer.reject();
          }, function () {
            defer.reject();
          });
          return defer.promise;
        };

        this.obterContratoImagemAluno = function (id_contrato) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + '/matricula/id_contrato/:id_contrato/busca_contrato_imagem_aluno_by_id_contrato',
            {}, {'query': {method: 'GET', isArray: false}}).query({id_contrato: id_contrato}, function (response) {
            if (!response.status)
              defer.resolve(response)
            else
              defer.reject()
          }, function () {
            defer.reject();
          });
          return defer.promise;
        };


        this.obterConfigMatricula = function (aluno) {
            let deferr = $q.defer();

            if (aluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/obterConfigMatricula',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        codAluno: encodeURIComponent(aluno.codAluno)
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response);
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.obterDadosQuadroHorario = function (aluno, config) {
            let deferr = $q.defer();

            if (aluno && config) {
                let codAluno = encodeURIComponent(aluno.codAluno);
                $resource(Configs.getApiServer() + '/matricula/codAluno/' + codAluno + '/obterDadosQuadroHorario',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(config,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response);
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.obterDisciplinasMatriculadas = function (aluno, config) {
            let deferr = $q.defer();

            if (aluno && config) {
                let codAluno = encodeURIComponent(aluno.codAluno);
                $resource(Configs.getApiServer() + '/matricula/codAluno/' + codAluno + '/obterDisciplinasMatriculadas',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(config,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response);
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.termoMatricula = function () {
            let deferr = $q.defer();
            var aluno = SessionData.aluno.list[SessionData.aluno.selecionado];
            if (aluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:alunoId/executaEPAlteraInicioMatricula',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        alunoId: aluno.codAluno
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject()
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }

        this.aceitoTermo = function (resposta) {
            let deferr = $q.defer();
            var aluno = SessionData.aluno.list[SessionData.aluno.selecionado];
            if (aluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:alunoId/respostaAluno/:respostaAluno/executaEPAcaoInicioMatricula',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        alunoId: aluno.codAluno,
                        respostaAluno: resposta ? 'S' : 'N'
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject()
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }

        this.existeContratoAceito = function (aluno, configMatricula) {
            let deferr = $q.defer();

            if (aluno && configMatricula) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/ano/:ano/periodo/:periodo/existeContratoAceito',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        codAluno: aluno.codAluno,
                        ano: configMatricula.ano,
                        periodo: configMatricula.periodo
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }

        this.gerarContratoAlunoRollback = function (params) {
            let deferr = $q.defer();

            if (params) {
                $resource(Configs.getApiServer() + '/matricula/postGerarContratoAlunoRollback',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(params,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                    }, function() {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.buscaContratoImagemAluno = function (aluno, configMatricula) {
            let deferr = $q.defer();

            if (aluno && configMatricula) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/ano/:ano/periodo/:periodo/buscaContratoImagemAluno',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        codAluno: aluno.codAluno,
                        ano: configMatricula.ano,
                        periodo: configMatricula.periodo
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject()
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }

        this.aceitarContratoAluno = function (aceito, configMatricula, contrato, aluno) {
            let deferr = $q.defer();

            if (configMatricula && contrato && aluno) {
                let params = {
                    codAluno: aluno.codAluno,
                    htmlContrato: contrato.htmlContrato,
                    contratoAceito: aceito,
                    presencial: false,
                    ehContratoEmAjuste: false,
                    configMatricula: configMatricula
                }

                $resource(Configs.getApiServer() + '/matricula/aceitarContratoAluno',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(params,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }
        
        this.obterRequerimentoAluno = function (aluno, config) {
            let deferr = $q.defer();

            if (aluno && config) {
                let params = {
                    codAluno: aluno.codAluno,
                    ano: config.ano,
                    periodo: config.periodo,
                    tipoMatricula: config.tipomatricula,
                    secretaria: false
                };

                $resource(Configs.getApiServer() + '/matricula/obterRequerimentoMatriculaAluno',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(params,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }

        this.gerarBoletoMatricula = function (aluno, configMatricula) {
            let deferr = $q.defer();

            if (aluno && configMatricula) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/' + aluno.codAluno + '/gerarBoletoMatricula').save(configMatricula,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }
        
        this.verificarImpressaoBoleto = function (aluno, configMatricula, boleto) {
            let deferr = $q.defer();

            if (aluno && configMatricula) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/' + aluno.codAluno + '/boleto/' + boleto + '/verificarSeImprimeBoletoWebMatricula',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(configMatricula,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }

        this.downloadBoleto = function (aluno, codBoleto = 0) {
            let defer = $q.defer();
            if (aluno) {
                let _objAluno = {
                    codPessoa : aluno.user.id,
                    codAluno  : aluno.codAluno,
                    codBoleto : codBoleto
                };
                $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoa/codAluno/:codAluno/codBoleto/:codBoleto/exibirArquivo/false/obterBoleto', {}, {
                    cache: false,
                    get: {
                        responseType: 'arraybuffer',
                    transformResponse: function (data, headersGetter, status) {
                            if(status == 500) {
                                defer.reject();
                                var decoder = new TextDecoder("utf-8");
                                var domString = decoder.decode(data);
                                var json = JSON.parse(domString);
                                return json
                            }

                            let pdf;
                            if (data) {
                                pdf = new Blob([data], {
                                    type: 'application/pdf'
                                });
                            }
                            return {
                                pdf: pdf
                            };
                        }
                    }
                }).get(_objAluno, function (result) {
                        delete _objAluno.codBoleto;
                        defer.resolve(result);
                    }, function (error) {
                        delete _objAluno.codBoleto;
                        defer.reject(error);
                    });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;
        }
        
        this.obterDadosPagarMatriculaCartao = function (aluno, codBoleto){
            let deferr = $q.defer();
            
            if (aluno && codBoleto){
                let _objAluno = {
                    codPessoa : aluno.user.id,
                    codAluno  : aluno.codAluno,
                    codBoleto : codBoleto
                };
                $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:codAluno/cod_pessoa/:codPessoa/id_boleto/:codBoleto/obter_dados_pagar_matricula_com_cartao',
                    {}, { 'query': { method: 'GET', isArray: false } }).query(_objAluno,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }

        this.validarPremissaoCartaoMatricula = function(cod_aluno, id_boleto) {
          let defer = $q.defer();
          params = {
              cod_aluno: cod_aluno,
              id_boleto: id_boleto
            };
          $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:cod_aluno/id_boleto/:id_boleto/permite_pagar_matricula_com_cartao', {}, {
              cache: false,
              query: {
                  method: 'GET'
              }
          })
          .query(params, function(result) {
              defer.resolve(result);
          }, function(err) {
              defer.reject(err);
          });

          return defer.promise;
        }
    }]);
