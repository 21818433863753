(function() {
angular.module('ocorrencias')
  .component('ocorrenciasListItem', {
    templateUrl: 'js/custom-components/ocorrencias-list/ocorrencias-list-item.component.html',
    bindings: {
      ocorrenciaItem: "=",
      onListOpenOcorrenciaDetail: "&"
    },
    controller: 'OcorrenciasListItemController',
    controllerAs: 'ctrl'
  });

})();