var config = {};

config.boletim = {};
config.boletim.cell = {};
config.boletim.cell.css = {};

config.boletim.cell.css.default = {
		icon:                  'fa fa-tasks',
		background:            'item-positive',
		titleBackground:       'bar-positive',
		notaBadge:             'badge-media',
		notaIcon:              'fa fa-check-square-o',
		mediaTurmaIcon:        'fa fa-thumbs-up',
		faltasBadge:           'badge-media',
		faltasIcon:            'fa fa-calendar',
		aulasPrevistasBadge:   'badge-media',
		aulasPrevistasIcon:    'fa fa-calendar',
		aulasMinistradasBadge: 'badge-media',
		aulasMinistradasIcon:  'fa fa-calendar' 
};

config.boletim.cell.css.reprovado = {
		icon:                  'fa fa-thumbs-o-down',
		background:            'item-assertive',
		titleBackground:       'bar-assertive',
		notaBadge:             'badge-faltas',
		notaIcon:              'fa fa-times',
		mediaTurmaIcon:        'fa fa-thumbs-up fa-flip-vertical',
		faltasBadge:           'badge-faltas',
		faltasIcon:            'fa fa-calendar-times-o',
		aulasPrevistasBadge:   'badge-faltas',
		aulasPrevistasIcon:    'fa fa-calendar-times-o',
		aulasMinistradasBadge: 'badge-faltas',
		aulasMinistradasIcon:  'fa fa-calendar-times-o',
		title:				   'Reprovado'
}; 

config.boletim.cell.css.aprovado = {
		icon:                  'fa fa-thumbs-o-up',
		background:            'item-balanced',
		titleBackground:       'bar-balanced',
		notaBadge:             'badge-aprovado',
		notaIcon:              'fa fa-check-square-o',
		mediaTurmaIcon:        'fa fa-thumbs-up',
		faltasBadge:           'badge-media',
		faltasIcon:            'fa fa-calendar',
		aulasPrevistasBadge:   'badge-aprovado',
		aulasPrevistasIcon:    'fa fa-calendar',
		aulasMinistradasBadge: 'badge-aprovado',
		aulasMinistradasIcon:  'fa fa-calendar',
		title:				   'Aprovado'
};