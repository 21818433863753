angular
	.module('MyApp')
	.service('CobrancaService', ['$resource','$q','SessionData','Configs', 'DownloadArquivo',
		function($resource, $q, SessionData, Configs, DownloadArquivo) {
			var _msgErro = {
			msgErro : 'Error cobranca não encontrada'
			};

			function dadosValidosAluno(){
				if(SessionData.aluno){
					dadosAluno = {
						aluno: SessionData.aluno.list[SessionData.aluno.selecionado].codAluno,
						pessoa: SessionData.aluno.list[SessionData.aluno.selecionado].id
					};
					return true;
				}
				return false;
			}

			this.listarItensCobranca = function(cobranca) {
				let defer = $q.defer();
				var codigoCobranca = {
					'cod_cobranca' : cobranca
					}

				$resource(
					Configs.getApiServer() + '/financeiro/cod_cobranca/:cod_cobranca/obter_lista_itens_cobranca', {}, {
						cache : false,
						query : {
							method : 'GET'
						}
				}).query(codigoCobranca, function(result) {
					defer.resolve(result);
				}, function(err) {
					defer.reject(err);
				});

				return defer.promise;
			}
	
	this.getDadosImpressaoBoleto = function(codBoleto) {
		let defer = $q.defer();
		var codigoCobranca = {
			'cod_boleto' : codBoleto
			}

		$resource(
			Configs.getApiServer() + '/financeiro/cod_boleto/:cod_boleto/obtem_dados_impressao_boleto', {}, {
				cache : false,
				query : {
					method : 'GET'
				}
		}).query(codigoCobranca, function(result) {
			defer.resolve(result);
		}, function(err) {
			defer.reject(err);
		});

		return defer.promise;
	}
	
	this.getExibeProrrogaBoleto = function(codBoleto) {
		let defer = $q.defer();
		if (dadosValidosAluno()){
			dadosAluno.boleto = codBoleto;
			$resource(
				Configs.getApiServer() + '/financeiro/aluno/:aluno/pessoa/:pessoa/boleto/:boleto/exibe_prorroga_boleto', {}, {
					cache : false,
					query : {
						method : 'GET',
						isArray: false
					}
			}).query(dadosAluno, function(result) {
				defer.resolve(result);
			}, function(err) {
				defer.reject(err);
			});			
		} else {
			defer.reject({
				_msgErro
			});
		}	

		return defer.promise;
	}

	this.prorrogarVencimentoBoleto = function(codBoleto) {
		let defer = $q.defer();
		if (dadosValidosAluno()){			
			let params = {
				cod_boleto_antigo: codBoleto,
				cod_aluno: dadosAluno.aluno
			}
			$resource(
				Configs.getApiServer() + '/financeiro/cod_boleto_antigo/:cod_boleto_antigo/cod_aluno/:cod_aluno/prorroga_vencimento_boleto', {}, {
					cache : false,
					query : {
						method : 'GET',
						isArray: false
					}
			}).query(params, function(result) {
				defer.resolve(result);
			}, function(err) {
				defer.reject(err);
			});			
		} else {
			defer.reject({
				_msgErro
			});
		}

		return defer.promise;
	}

	this.downloadBoletoCobranca = function(codBoleto) {
		let params = {};
		params.url = Configs.getApiServerCustom() + '/financeiro/imprime-boleto/' + codBoleto;
		params.type = 'application/pdf';
		params.nomeArquivo = 'Boleto_' + codBoleto + '_' + new Date().getTime();
		params.method = "GET";
			
		DownloadArquivo.downloadArquivoBoleto(params);
	}
	
	this.listarItensDetalheBoleto = function(codBoleto) {
		let defer = $q.defer();
		var codigoCobranca = {
			'cod_boleto' : codBoleto
			}

		$resource(
			Configs.getApiServer() + '/financeiro/cod_boleto/:cod_boleto/lista_itens_detalhe_boleto', {}, {
				cache : false,
				query : {
					method : 'GET',
	                isArray: true
				}
		}).query(codigoCobranca, function(result) {
			defer.resolve(result);
		}, function(err) {
			defer.reject(err);
		});

		return defer.promise;
	}
}]);