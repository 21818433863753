angular.
module('MyApp').factory('AonlineUtils', [
        '$ionicLoading',
    function($ionicLoading){
			return {
				showLoading : showLoading,
            };
            
            function showLoading(value) {
                if (value)
                    $ionicLoading.show({
                        content: 'Loading',
                        animation: 'fade-in',
                        showBackdrop: true,
                        maxWidth: 200,
                        showDelay: 1
                    });
                else $ionicLoading.hide();
            }
}]);